import { ReactComponent as FacebookIcon } from '../../../design-system/Icons/Facebook.svg';
import { ReactComponent as InstagramIcon } from '../../../design-system/Icons/Instagram.svg';
import { ReactComponent as GlobalIcon } from '../../../design-system/Icons/Global.svg';
import { ReactComponent as LinkedinIcon } from '../../../design-system/Icons/Linkedin.svg';
import React from 'react';

export type SocialNetwork = {
  name: string;
  icon: React.ElementType;
};

export const SOCIAL_NETWORKS: SocialNetwork[] = [
  { name: 'Instagram', icon: InstagramIcon },
  { name: 'LinkedIn', icon: LinkedinIcon },
  { name: 'Facebook', icon: FacebookIcon },
  { name: 'Website', icon: GlobalIcon },
];

import useKlydoQuery from './useKlydoQuery';
import studioApiClient from '../api/studio';

export type Locale = {
  range: [number, number];
  country: string;
  region: string;
  eu: string;
  timezone: string;
  city: string;
  ll: [number, number];
  metro: number;
  area: number;
};

const useLocale = () => {
  return useKlydoQuery<Locale>({
    queryKey: ['Locale'],
    queryFn: () => studioApiClient.getLocale(),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: false,
    keepPreviousData: true,
  });
};

export default useLocale;

import DSBox from '../../../../design-system/DSBox';
import KlydoGrid from './KlydoGrid';
import useKlydosView from '../hooks/useKlydosList';
import DSPrimaryButton from '../../../../design-system/DSPrimaryButtom';
import DSTabGradientViewPaper from '../../../../design-system/Paper/DSTabGradientViewPaper';
import DSTabHeaderTypography from '../../../../design-system/Typography/DSSTabHeaderTypography';
import { useTheme } from '@mui/material';
import useDeviceType from '../../../../hooks/useDeviceType';
import useScrollPagination from '../../../hooks/useScrollPagination';

const Klydos = () => {
  const {
    data,
    isLoading,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    handleCreateClick,
  } = useKlydosView();

  const { spacing } = useTheme();
  const { isMobile } = useDeviceType();

  const { scrollRef } = useScrollPagination({
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  });

  return (
    <DSTabGradientViewPaper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: { xxs: spacing(0), xl: spacing(3) },
        maxHeight: '87vh',
        minHeight: '87vh',
      }}
    >
      <DSBox
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: { xxs: spacing(2), lg: spacing(5) },
          paddingBottom: { xxs: spacing(2), lg: spacing(7) },
        }}
      >
        <DSTabHeaderTypography>Klydos</DSTabHeaderTypography>

        {!isMobile && (
          <DSPrimaryButton
            onClick={() => handleCreateClick()}
            sx={{ padding: '14px var(--5, 40px)' }}
          >
            Create
          </DSPrimaryButton>
        )}
      </DSBox>
      <DSBox
        ref={scrollRef}
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          overflowY: 'scroll',
          overflowX: 'hidden',
          maxHeight: '80vh',
          paddingRight: { sm: spacing(2) },
        }}
      >
        <KlydoGrid isLoading={isLoading} klydos={data} />
      </DSBox>
    </DSTabGradientViewPaper>
  );
};
export default Klydos;

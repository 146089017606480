import klydoApiClient, { KlydoUnion } from '../../../api/klydo';
import useKlydoQuery from '../../../hooks/useKlydoQuery';

const useGetKlydoQuery = (klydoId: string) => {
  return useKlydoQuery<KlydoUnion>({
    queryKey: ['klydo', klydoId],
    queryFn: () => klydoApiClient.getKlydo(klydoId),
    enabled: !!klydoId,
  });
};

export default useGetKlydoQuery;

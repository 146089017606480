import React from 'react';
import DSTextField from '../../../design-system/DSTextField';
import DSCircularProgress from '../../../design-system/DSCircularProgress';
import DSAutocomplete from '../../../design-system/DSAutocomplete';
import DSListItem from '../../../design-system/DSListItem';
import useCountryDropdown, {
  CountryDropdownProps,
} from '../hooks/useCountryDropdown';

const CountryDropdown: React.FC<CountryDropdownProps> = ({
  initialCountryCode,
  onSelect,
}) => {
  const {
    options,
    handleInputChange,
    isLoading,
    spacing,
    selectedCountry,
    handleOptionSelect,
  } = useCountryDropdown({ initialCountryCode, onSelect });

  return (
    <DSAutocomplete
      options={options}
      value={selectedCountry}
      onChange={handleOptionSelect}
      onInputChange={handleInputChange}
      getOptionLabel={(option) => option.name}
      renderOption={(props, option) => (
        <DSListItem {...props} key={option.code}>
          {option.name}
        </DSListItem>
      )}
      renderInput={(params) => (
        <DSTextField
          {...params}
          placeholder="Select a country..."
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <DSCircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          sx={{
            '.MuiInputBase-root': {
              padding: 0,
            },
          }}
        />
      )}
      sx={{
        minWidth: spacing(18),
        marginLeft: spacing(3),
      }}
    />
  );
};

export default CountryDropdown;

import KlydoPreview from '../../../components/klydoPreview/KlydoPreview';
import useCreateKlydo from '../hooks/useCreateKlydo';
import DSBox from '../../../design-system/DSBox';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { DraftKlydo } from '../../../api/klydo';
import DSTabHeaderTypography from '../../../design-system/Typography/DSSTabHeaderTypography';
import DSGradientViewPaper from '../../../design-system/Paper/DSGradientViewPaper';
import TimePicker from '../../../components/TimePicker/components/TimePicker';
import KlydoEditableView from './KlydoEditableView';
import KlydoReadOnlyView from './KlydoReadOnlyView';
import DSCircularProgress from '../../../design-system/DSCircularProgress';
import DSTabGradientViewPaper from '../../../design-system/Paper/DSTabGradientViewPaper';

const CreateKlydo = () => {
  const {
    klydoSecureUrl,
    minutesValue,
    hoursValue,
    isDialsHidden,
    spacing,
    menuButtonItemsList,
    isTimpePickerOpen,
    handleClosedTimePicker,
    klydo,
    isEditableView,
    klydoUrl,
    handleKlydoThemeChange,
    setSuggestedColors,
    selectedTheme,
    suggestedColors,
    handleLocalUrlChange,
    handleSecuredUrlChange,
    klydoId,
    handleBackToEditClick,
    goBack,
    isLoading,
    handleSubmitKlydo,
  } = useCreateKlydo();

  if (isLoading) {
    return (
      <DSTabGradientViewPaper
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          margin: spacing(3),
          paddingY: spacing(4),
          height: '75vh',
          gap: spacing(3),
        }}
      >
        <DSCircularProgress />
      </DSTabGradientViewPaper>
    );
  }

  return (
    <DSBox
      sx={{
        flexDirection: { xxs: 'column', sm: 'row' },
        justifyContent: 'space-between',
        display: 'flex',
        marginX: '3%',
        gap: spacing(3),
      }}
    >
      <DSGradientViewPaper
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
          flexGrow: 1,
          padding: '3%',
          paddingBottom: { xxs: 0, md: spacing(2) },
          maxWidth: { sm: '50%', md: '60%', xl: '70%' },
          minHeight: '75vh',
        }}
      >
        <DSBox sx={{ display: 'flex', gap: spacing(1) }}>
          <ArrowBack
            onClick={() => goBack()}
            sx={{
              fontSize: spacing(6),
              cursor: 'pointer',
            }}
          />
          {isEditableView && (
            <DSTabHeaderTypography>Edit Klydo</DSTabHeaderTypography>
          )}
        </DSBox>
        {isEditableView ? (
          <KlydoEditableView
            klydo={klydo as DraftKlydo}
            setSuggestedColors={setSuggestedColors}
            onKlydoThemeChange={handleKlydoThemeChange}
            suggestedColors={suggestedColors}
            onLocalUrlChange={handleLocalUrlChange}
            onSecuredUrlChange={handleSecuredUrlChange}
            klydoSecureUrl={klydoSecureUrl || ''}
            selectedTheme={selectedTheme}
            klydoId={klydoId}
            onSubmitKlydo={handleSubmitKlydo}
          />
        ) : (
          klydo && (
            <KlydoReadOnlyView
              klydo={klydo}
              onClickEdit={handleBackToEditClick}
            />
          )
        )}
      </DSGradientViewPaper>

      <KlydoPreview
        klydo={{
          loopUrl: klydoUrl || klydoSecureUrl,
          theme: selectedTheme,
        }}
        minutesValue={minutesValue}
        hoursValue={hoursValue}
        menuButtonItemsList={menuButtonItemsList}
        dialsHidden={isDialsHidden}
        scale={0.7}
      />
      <TimePicker isOpen={isTimpePickerOpen} onClose={handleClosedTimePicker} />
    </DSBox>
  );
};

export default CreateKlydo;

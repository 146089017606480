import { Link } from '@mui/material';
import DSValueTypography from '../design-system/Typography/DSValueTypography';

const Contract = () => {
  return (
    <DSValueTypography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
      These artist collaboration terms and conditions (the{' '}
      <strong>“General Terms”</strong>), are a binding contract between yourself
      (herein referred to as the <strong>“Artist”</strong>) and KLYDOCLOCK LTD.,
      an Israeli limited liability company (the <strong>“Company”</strong>).
      {'\n\n'}
      These General Terms govern your access and use of the online platform
      generally referred to as {''}
      <Link
        href="https://studio.klydoclock.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Klydo Studio
      </Link>{' '}
      , allowing the Artist to submit Art (as defined below) to be purchased or
      subscribed to by KLYDO Customers (as such term is defined below) in order
      to be presented using KLYDO (as such term is defined below) units sold,
      directly or indirectly, by the Company (the “${<strong>Platform</strong>}
      ”).
      {'\n\n'}
      1. General {'\n\n'}
      THESE GENERAL TERMS TAKE EFFECT WHEN YOU CLICK THE "I ACCEPT" BUTTON BELOW
      OR BY ACCESSING OR USING THE PLATFORM (the "
      <strong>Effective Date</strong>"). BY CLICKING ON THE "I ACCEPT" BUTTON
      BELOW OR BY ACCESSING OR USING THE PLATFORM YOU (A) ACKNOWLEDGE THAT YOU
      HAVE READ AND UNDERSTAND THESE GENERAL TERMS; (B) REPRESENT AND WARRANT
      THAT YOU HAVE THE RIGHT, POWER, AND AUTHORITY TO ENTER INTO THESE GENERAL
      TERMS AND, IF ENTERING INTO THESE GENERAL TERMS FOR AN ORGANIZATION, THAT
      YOU HAVE THE LEGAL AUTHORITY TO BIND THAT ORGANIZATION; AND (C) ACCEPT
      THESE GENERAL TERMS AND AGREE THAT YOU ARE LEGALLY BOUND BY ITS TERMS.{' '}
      {'\n\n'}
      IF YOU DO NOT ACCEPT THESE TERMS, YOU MAY NOT ACCESS OR USE THE PLATFORM.
      {'\n\n'}
      Collection and use of information is made pursuant to our Privacy Policy,
      a copy of which is available{' '}
      <Link
        href="https://www.klydoclock.com/pages/copyright-policy"
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </Link>{' '}
      incorporated herein by reference. If you do not want to agree to the
      Privacy Policy, you must not access or use the Platform.{'\n\n'}
      This Platform is offered and available to users who are 18 years of age or
      older. By using the Platform, you represent and warrant that you are of
      legal age to form a binding contract with the Company and meet all of the
      foregoing eligibility requirements. If you do not meet all of these
      requirements, you must not access or use the Platform.{'\n\n'}
      The platform may not be used for unlawful, fraudulent, offensive, or
      obscene activity, as further described and set forth in Company's{'\n\n'}
      The Company’s name, the terms “KlydoClock”, the Company’s logo, and all
      related names, logos, product and service names, designs, and slogans are
      trademarks of the Company or its affiliates or licensors. Artist must not
      use such marks without the prior written permission of the Company.
      Company may update the content on, and functionality of, the Platform from
      time to time, in its discretion.{'\n\n'}
      2. Access and use of the Platform{'\n\n'}
      (a) Under these General Terms, Artist hereby agrees to grant the Company
      the necessary rights and licenses, as required in order to integrate
      certain creations of Artist (the “<strong>Art</strong>”), into products of
      the Company and/or its licensors (collectively, the “
      <strong>KLYDO</strong>”), and to promote, sell, distribute and/or
      otherwise use and/or exploit the Art as part of and/or in connection with
      the KLYDO to third party customers (the “<strong>KLYDO Customers</strong>
      ”), through{' '}
      <Link
        href="https://www.klydoclock.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        https://www.klydoclock.com/
      </Link>
      (the “<strong>Website</strong>”), project crowdfunding platforms and/or
      through any other medium or channel the Company shall deem appropriate,
      including, but not limited to, KLYDO application (each such platform,
      medium and/or channel, a “$<strong>KLYDO Channel</strong>”), as further
      described below. The Company may use and exploit an unlimited number of
      copies of the Art. {'\n\n'}
      (b) Following acceptance of these General Terms, the Artist may open an
      account using the Website, through which Artist may upload Art, monitor
      its rights to receive royalties and communicate with the Company in
      connection therewith (the “ $<strong>Artist Homepage</strong>”). For the
      purpose of these General Terms, the Artist Homepage is included within the
      Platform, and any reference to the Platform, includes reference to the
      Artist Homepage.{'\n\n'}
      (c) Any Art submitted through the Platform is subject to the approval of
      the Company, and to ongoing review. The Company is not obligated to accept
      any Art to be presented on, or to be purchased or subscribed using, the
      Platform, and may at any time for reasonable causes refuse the addition of
      any Art, or remove any Art from the Platform. Without limiting its general
      discretion as to the removal or maintenance of any Art, the Company shall
      remove Art from the Platform in cases where an Art is reported (pursuant
      to the Company’s DMCA or other policy) or found by the Company to be
      offensive, to infringe third party rights, to be in breach of these
      General Terms (to include the Content Standards (as such term is defined
      below)), the AUP (as such term is defined below) and/or any other policy
      of the Company or agreement between the Company and the Artist. However,
      Company cannot and does not undertake to review all Art before it is
      posted on the Platform, and cannot ensure prompt removal of objectionable
      material after it has been posted. Accordingly, Company assumes no
      liability for any action or inaction regarding transmissions,
      communications, or content provided by any user or third party. Company
      has no liability or responsibility to anyone for performance or
      nonperformance of the activities described in this section.{'\n\n'}
      (d) Subject to Artist’s acceptance of these General Terms and compliance
      with all other terms and conditions of these General Terms, Company hereby
      grants the Artist a revocable, non-exclusive, non-transferable,
      non-sublicensable, limited right to access and use the Platform in
      accordance with the terms and conditions herein. Company shall provide
      Artist (or allow the Artist with an option to generate) the necessary
      passwords and access credentials to allow Artist to access the Platform.
      Artist is responsible for keeping its passwords and access credentials
      associated with the Platform confidential. Artist undertakes not to sell,
      transfer, assign, pledge or grant any third party any right in or to the
      access credentials. Artist shall promptly notify the Company in writing
      about any unauthorized access to Artist’s passwords or access credentials.
      {'\n\n'}
      (e) Artist shall not, and shall not permit any person to, use the
      Platform, any software component of the Platform, or any documentation
      provided by Company for any purposes beyond the scope of the access
      granted in these General Terms. Artist shall not at any time, directly or
      indirectly, and shall not permit any person to: (i) copy, modify, or
      create derivative works of the Platform, any software component of the
      Platform, or documentation provided by the Company, in whole or in part;
      (ii) rent, lease, lend, sell, license, sublicense, assign, distribute,
      publish, transfer, or otherwise make available the Platform or such
      documentation except as expressly permitted under these General Terms;
      (iii) reverse engineer, disassemble, decompile, decode, adapt, or
      otherwise attempt to derive or gain access to any software component of
      the Platform, in whole or in part; (iv) remove any proprietary notices
      from the Platform or such documentation; or (v) use the Platform or such
      documentation in any manner or for any purpose that infringes,
      misappropriates, or otherwise violates any intellectual property right or
      other right of any person, or that violates any applicable law,
      regulation, or rule. {'\n\n'}
      (f) Company may monitor Artist's use of the Platform and collect and
      compile data and information related to Artist's use of the Platform to be
      used by Company in an aggregated and anonymized manner, including to
      compile statistical and performance information related to the provision
      and operation of the Platform ("<strong>Aggregated Statistics</strong>").
      As between Company and Artist, all right, title, and interest in
      Aggregated Statistics, and all intellectual property rights therein,
      belong to and are retained solely by Company. Artist acknowledges that
      Company may compile Aggregated Statistics based on Artist’s data input
      into the Platform. Artist agrees that Company may (i) make Aggregated
      Statistics publicly available in compliance with applicable law, and (ii)
      use Aggregated Statistics to the extent and in the manner permitted under
      applicable law.{'\n\n'}
      (g) Company reserves all rights not expressly granted to Artist in these
      General Terms. Except for the limited rights and licenses expressly
      granted under these General Terms, nothing in these General Terms grants,
      by implication, waiver, estoppel, or otherwise, to Artist or any third
      party, any intellectual property rights or other right, title, or interest
      in or to the Company’s intellectual property, of any kind (“
      <strong>Company IP</strong>").{'\n\n'}
      (h) Notwithstanding anything to the contrary in these General Terms,
      Company may temporarily suspend Artist's access to any portion or all of
      the Platform if: (i) Company reasonably determines that (A) there is a
      threat or attack on any of the Company IP; (B) Artist's or any other
      Authorized User's use of the Company IP disrupts or poses a security risk
      to the Company IP or to any other Artist or vendor of Company; (C) Artist
      is using the Company IP for fraudulent or illegal activities; (D) subject
      to applicable law, Artist has ceased to continue its business in the
      ordinary course, made an assignment for the benefit of creditors or
      similar disposition of its assets, or become the subject of any
      bankruptcy, reorganization, liquidation, dissolution, or similar
      proceeding; (E) Company's provision of the Platform to Artist is
      prohibited by applicable law; (ii) any vendor (including any third-party
      product provider) of Company has suspended or terminated Company's access
      to or use of any third-party services or products required to enable
      Artist to access the Platform (any such suspension described in subclause
      (i) or (ii), a "Service Suspension"), (F) Company has determined or
      believe that the Artist has breached these General Terms or any agreement
      or policy which Artist is subject to. Company shall use commercially
      reasonable efforts to provide written notice of any Service Suspension to
      Artist and to provide updates regarding resumption of access to the
      Platform following any Service Suspension. Company shall use commercially
      reasonable efforts to resume providing access to the Platform as soon as
      reasonably possible after the event giving rise to the Platform Suspension
      is cured. Company will have no liability for any damage, liabilities,
      losses (including any loss of or profits), or any other consequences that
      Artist or any other Authorized User may incur as a result of a Service
      Suspension.{'\n\n'}
      (i) The Platform may use or permit access to Third-Party Products (as
      defined below). For purposes of these General Terms, such Third-Party
      Products are subject to their own terms and conditions presented to Artist
      for acceptance within the Platform by website link or otherwise. If
      Artists does not agree to abide by the applicable terms for any such
      Third-Party Products, then Artist should not install, access, or use such
      Third-Party Products. Artist understands and acknowledges that the Company
      uses Third-Party Products, which may include infrastructure, such as
      servers, security protocols, website management platforms, databases,
      money clearance services, shipping and other third-party service
      providers, to make the Platform available and to provide the services
      rendered through the Platform. Artist acknowledges and agrees, without
      limiting Company’s disclaimers herein, that the availability,
      functionality and Artist’s access to the Platform is subject, inter alia,
      to such Third-Party Products’ availability and performance.{'\n\n'}
      <strong>"Third-Party Products"</strong> means any products, content,
      services, information, websites, or other materials that are owned by
      third parties and are incorporated into or accessible through the
      Platform.{'\n\n'}
      3. Representations, Warranties and Undertakings. The Artist represents,
      warrants, and undertakes towards the Company, its shareholders, directors,
      managers, employees, advisors, service providers and their respective
      successors, as follows:{'\n\n'}
      (a) The platform may not be used for unlawful, fraudulent, offensive, or
      obscene activity, as further described and set forth in Company's
      acceptable use policy ("<strong>AUP</strong>") located{' '}
      <Link
        href="https://www.klydoclock.com/pages/acceptable-use-policy"
        target="_blank"
        rel="noopener noreferrer"
      >
        here
      </Link>
      , as may be amended from time to time, which is incorporated herein by
      reference. Artists will comply with all terms and conditions of these
      General Terms, all applicable laws, rules, and regulations, and all
      guidelines, standards, and requirements that may be posted on the AUP.
      {'\n\n'}
      (b) Artist is responsible and liable for all uses of the Platform, whether
      such access or use is permitted by or in violation of these General terms.
      {'\n\n'}
      (c) There are no un-settled, pending or, to Artist’s knowledge, threatened
      litigation, claim or proceeding against the Artist which may prevent the
      Artist from entering into, or performing all of the obligations of the
      Artist under these General Terms.{'\n\n'}
      (d) These General Terms will constitute a legal, valid and binding
      obligation of the Artist, enforceable against the Artist in accordance
      with their terms.{'\n\n'}
      (e) Artist shall perform all duties set forth under these General Terms in
      full compliance with all applicable laws, regulations, orders, relevant
      licenses and other legal requirements to which Artist is subject. {'\n\n'}
      (f) Artist is not bound by any agreement, either oral or written, which
      conflicts with these General Terms or which would in any way limit the
      Artist’s ability to fully perform the Artist’s obligations hereunder.
      Artist has the entire worldwide right and power to enter into these
      General Terms, to perform the obligations of Artist herein and to grant
      the Company all of the rights and licenses granted hereby and thereby.
      {'\n\n'}
      (g) The Art (that was or will be created) is owned exclusively by the
      Artist. The Art is made and is in compliance with the Content Standards.
      The Art and any materials provided by the Artist in connection with the
      Art and/or the parties engagement (collectively, the “Art Materials”), are
      the Artist’s original work of authorship, and no third party has any
      rights (including no intellectual property rights) in, to, or in
      connection with the Art Materials, and each portion of the Art Materials
      is free and clear from any such third-party rights. Artist has the entire
      worldwide ownership rights in and to the Art Materials, including all
      intellectual property rights thereof.{'\n\n'}
      (h) Artist hereby grants the Company a worldwide, non-exclusive,
      assignable, sublicensable, fully paid-up and royalty free, license and
      right, to (a) present the Art on the Platform, to integrate the Art with
      the KLYDO, and to use and exploit the Art and/or modify the Art as the
      Company deems required (in its exclusive direction) in order to facilitate
      such integration, (b) use, exploit and/or modify the Art Materials, in
      order to promote the KLYDO, the Art and/or portions thereof, (c) to
      prepare and present copies of the Art, to publish such copies, with or
      without the Artist’s name, on any KLYDO Channel and on any other medium
      the Company shall desire (including on Instagram, Facebook and other
      accounts and pages), and to repost over the KLYDO Channels any portion of
      the Art Materials associated media, from Artist’s social pages or other
      Artist’s mediums, and (d) to sell, resell, grant (including, and without
      limitation, gratis) use or presentation rights or other licenses (with or
      without consideration) in or to the Art as part of or separately from the
      KLYDO. The grant license and right above is perpetual, however, Artist can
      always opt in to remove the Art from any KLYDO Channel, but such removal
      will not terminate the license but simply make the Art unavailable to
      KLYDO users.{'\n\n'}
      The license and rights granted above for the use and presentation of the
      Art within the KLYDO shall be assigned to purchasers and/or recipients of
      the KLYDO, and the Artist agrees that in any event, the sub-license
      granted by the Company to purchasers and/or recipients of the KLYDO is
      perpetual and irrevocable, notwithstanding such expiration date.{'\n\n'}
      (i) Although the license granted herein is non-exclusive, the Artist
      undertakes not to grant any third party person any license or other right
      in or to the Art to the extent the licensee or grantee shall use the Art
      to compete with the Company.{'\n\n'}
      (j) The Company may (but does not have to) disclose the fact that Artist
      was engaged with the Company, present the Artist’s name, picture,
      biography and other works on any KLYDO Channel, and the Company is hereby
      granted with a license to all of the foregoing.{'\n\n'}
      (k) The Artist is not authorized to assume or create any obligation or
      responsibility, express or implied, on behalf of, or in the name of the
      Company, or to bind the Company in any manner.{'\n\n'}
      (l) The Artist understands that the Company and its licensors are the
      owners of, and hold all of the rights in and to the KLYDO, the Platform,
      the Artist’s Homepage, and other Company products, with the exception of
      the rights granted to the Company by Artist hereunder, which are subject
      by the limited terms of the licenses granted hereby. In no event shall
      these General Terms grant the Artist any right in or to the KLYDO, the
      Platform, the Artist’s Homepage or the other Company products, as a whole
      or any portion thereof, and subject to the explicit limitations herein
      provided, Artist shall have no right to limit or otherwise restrict the
      Company’s rights to present the Art on any KLYDO Channel or anywhere else,
      or to sell, lease, license or grant any rights to any third party in or to
      the KLYDO.{'\n\n'}
      4. Content Standards{'\n\n'}
      These content standards (“<strong>Content Standards</strong>”) apply to
      any and all Art and any other material or information provided by the
      Artist in connection with an Art (herein, together with the Art: “
      <strong>Artist Contributions</strong>”). Artist Contributions must in
      their entirety comply with all applicable federal, state, local, and
      international laws and regulations. Without limiting the foregoing, Artist
      Contributions must not:{'\n\n'}
      (a) Contain any material that is defamatory, obscene, indecent, abusive,
      offensive, harassing, violent, hateful, inflammatory, or otherwise
      objectionable.{'\n\n'}
      (b) Promote sexually explicit or pornographic material, violence, or
      discrimination based on race, sex, religion, nationality, disability,
      sexual orientation, or age.{'\n\n'}
      (c) Infringe any patent, trademark, trade secret, copyright, or other
      intellectual property or other rights of any other person.{'\n\n'}
      (d) Violate the legal rights (including the rights of publicity and
      privacy) of others or contain any material that could give rise to any
      civil or criminal liability under applicable laws or regulations or that
      otherwise may be in conflict with these Terms of Use or our Privacy
      Policy.{'\n\n'}
      (e) Be likely to deceive any person.{'\n\n'}
      (f) Promote any illegal activity, or advocate, promote, or assist any
      unlawful act.{'\n\n'}
      (g) Cause annoyance, inconvenience, or needless anxiety or be likely to
      upset, embarrass, alarm, or annoy any other person.{'\n\n'}
      (h) Impersonate any person, or misrepresent your identity or affiliation
      with any person or organization.{'\n\n'}
      (i) Involve commercial activities or sales, such as contests, sweepstakes,
      and other sales promotions, barter, or advertising.{'\n\n'}
      (j) Give the impression that they emanate from or are endorsed by us or
      any other person or entity, if this is not the case.{'\n\n'}
      5. Artist’s Rights. As between the Company and the Artist, the Artist has
      the following rights:{'\n\n'}
      (a) Subject to the accuracy of the Artist’s representations and warranties
      and to the licenses granted under these General Terms, the Artist is the
      owner of the Art. {'\n\n'}
      (b) All good will and reputation associated with the Art, and (subject to
      the terms of promotion agreed between the Company and Artist) all moral
      rights associated with the Art, are retained by the Artist. This does not
      mean that the Artist has a right, and it is hereby agreed that the Company
      is not obligated, to provide any credit to the Artist in any manner,
      including, and without limitation, over the KLYDO. Subject to the
      Company’s exclusive discretion and independent judgment, the Company
      agrees to present a credit in places it subjectively believes it is
      reasonably appropriate. Such credits may be removed, changed or added as
      the Company subjectively believes it is reasonably appropriate.{'\n\n'}
      (c) Removal by the Artist of Art from the Platform is subject to the
      Company’s exclusive discretion. Artist may submit a request for Art
      removal.{'\n\n'}
      6. Consideration{'\n\n'}
      (a) The parties agree that for the performance by Artist of Artist’s
      obligations under these General Terms, the Artist shall be entitled to an
      amount equal to 70% of the Net Amount actually paid by KLYDO Customers to
      the Company in respect of the purchase of an Art uploaded by Artists to
      the Artist Homepage (the “Purchased Art”). {'\n\n'}
      “Net Amount” means, the gross amount paid by the KLYDO Customers to the
      Company for the Purchased Art, net of (a) VAT or sales taxes (as
      applicable), (b) 100% of the credit clearance costs due with respect to
      the Purchased Art. {'\n\n'}
      (b) Payment of the Net Amount shall be made on a monthly basis, based on
      the Company’s standard payment terms and on such day the Company shall
      determine from time to time.{'\n\n'}
      (c) Artist shall only be paid once the accrued amount due to Artist
      exceeds US$10. {'\n\n'}
      (d) The terms and details regarding the specific billing and payment
      methods will be subject to a billing mechanism that will be adopted by the
      company. Artist will be notified of such mechanism and may be required to
      open an account with third party payment facilitators in order to receive
      the fees due pursuant to the above. {'\n\n'}
      7. No Additional Consideration. The Artist shall not be entitled to any
      benefits, coverages, or privileges, including, without limitation, social
      security, unemployment, medical or pension payments, made available to
      employees of the Company. The Artist acknowledges that the fee payable to
      Artist as agreed under these General Terms, are the sole and exclusive
      consideration due to the Artist with respect to the Art and the
      performance of the Artist’s undertakings under the Agreement and these
      General Terms, and the Artist shall not be entitled to any additional
      payment or consideration from the Company.{'\n\n'}
      8. Social Media and Links form the Platform{'\n\n'}
      Artist may link to the Company Website or to Artist page or to pages
      showing Art, provided Artist does so in a way that is fair and legal and
      does not damage Company’s reputation or take advantage of it, but Artist
      must not establish a link in such a way as to suggest any form of
      association, approval, or endorsement on Company’s part without our
      express written consent.{'\n\n'}
      The Website or Platform may provide certain social media features that
      enable Artist to:{'\n\n'}
      (a) Link from Artist’s own or certain third-party websites to certain
      content on the Website.{'\n\n'}
      (b) Send emails or other communications with certain content, or links to
      certain content, on the Website.{'\n\n'}
      (c) Cause limited portions of content on the Website to be displayed or
      appear to be displayed on Artist’s own or certain third-party websites.
      {'\n\n'}
      Artists may use these features solely as they are provided by Company, and
      solely with respect to the content they are displayed with, and otherwise
      in accordance with any additional terms and conditions Company provides
      with respect to such features. Subject to the foregoing, Artist must not:
      {'\n\n'}
      (a) Establish a link from any website that is not owned by Artist.{'\n\n'}
      (b) Cause the Platform or portions of it to be displayed on, or appear to
      be displayed by, any other site, for example, framing, deep linking, or
      in-line linking.{'\n\n'}
      (c) Link to any part of the Platform or Website other than the Website’s
      homepage or pages of the Website showing the Art.{'\n\n'}
      (d) Otherwise take any action with respect to the materials on the
      Platform or the Website that are inconsistent with any other provision of
      these General Terms.{'\n\n'}
      The website from which Artist is linking, or on which Artist makes certain
      content accessible, must comply in all respects with the Content Standards
      set out in these Terms General Terms.{'\n\n'}
      Artist agrees to cooperate with us in causing any unauthorized framing or
      linking immediately to stop. The company reserves the right to withdraw
      linking permission without notice.{'\n\n'}
      We may disable all or any social media features and any links at any time
      without notice at our discretion. {'\n\n'}
      If the Platform contains links to other sites and resources provided by
      third parties, these links are provided for Artist’s convenience only.
      This includes links contained in advertisements, including banner
      advertisements and sponsored links. The company has no control over the
      contents of those sites or resources, and accepts no responsibility for
      them or for any loss or damage that may arise from Artist’s use of them.
      If Artist decides to access any of the third-party websites linked to the
      Platform, Artist does so entirely at its own risk and subject to the terms
      and conditions of use for such websites.{'\n\n'}
      9. Indemnification. The Artist agrees to defend, indemnify and hold the
      Company and its affiliates harmless from and against all claims, damages,
      liabilities, losses, expenses and costs (including reasonable fees and
      expenses of attorneys and other professionals) arising out of or resulting
      from: (i) any action by a third party against the Company that is based on
      a claim that any action performed by Artist under these General Terms, or
      the results of such action, or the Company’s use of any intellectual
      property or media provided by the Artist, infringe, misappropriate or
      violate such third party’s intellectual property rights, privacy or
      publicity rights; (ii) any breach of these General Terms; (iii) any action
      by a third party against the Company that is based on any act or omission
      of Artist and that results in: (a) personal injury (or death) or tangible
      or intangible property damage (including loss of use and unauthorized
      disclosure of confidential or proprietary information); or (b) the
      violation of any statute, ordinance, or regulation, and (c) any action by
      a third party against the Company due to Artist’s breach of these General
      Terms. The Artist agrees to defend, indemnify and hold any purchaser of
      any KLYDO, against all claims, damages, liabilities, losses, expenses and
      costs (including reasonable fees and expenses of attorneys and other
      professionals) arising out of or resulting from any misrepresentation made
      by Artist in connection with the Art.{'\n\n'}
      10. Term; Survival. The term of the Company’s and Artist’s engagement
      commence when these General Terms are accepted by the Artist, and
      terminates after all obligations under these General Terms were completed
      and the Artist’s access to the Platform is terminated (i.e. Artist’s
      account is closed). Each party (i.e., either the Company and Artist), may
      terminate these General Terms and their respective obligations thereunder,
      if the other party has breached a material undertaking set forth under
      these General Terms, and has failed to cure such breach within 7 days as
      of delivery of notice from the non-breaching party. The representations,
      warranties and undertakings of the Artist under Section 3 of these General
      Terms, the indemnity and confidentiality obligations under Sections 7 and
      8 of these General Terms and the agreements and undertakings under this
      Section 8 through 17 (inclusive) of these General Terms, shall survive the
      termination or expiration of the engagement between the Company and
      Artist, for whatever reason and without limitation of time.{'\n\n'}
      11. Independent Contractor Status. The Artist is not as an employee or
      agent of the Company and, as with respect to the performance of the
      Artist’s undertakings herein, is an independent contractor of the Company.
      The Artist (and not the Company) will be responsible, on an exclusive
      basis, for complying with all applicable laws, rules and regulations
      concerning income, employment and other tax withholding, social security
      contributions, pension fund contributions, unemployment contributions and
      similar matters and the Company shall not be required to withhold income,
      employment or other taxes from payments to the Artist.{'\n\n'}
      12. Geographic Restrictions{'\n\n'}
      The owner of the Platform is based in the State of Israel. The company
      makes no claims that the Platform or any of its content is accessible or
      appropriate outside of the State of Israel. Access to the Platform may not
      be legal by certain persons or in certain countries. If Artist accesses
      the Platform from outside the State of Israel, Artist does so on its own
      initiative and is responsible for compliance with local laws.{'\n\n'}
      13. Disclaimer of Warranties{'\n\n'}
      Artist understands that the Company cannot and does not guarantee or
      warrant that files available for downloading from the internet or the
      Platform will be free of viruses or other destructive code. Artist is
      responsible for implementing sufficient procedures and checkpoints to
      satisfy Artist’s particular requirements for anti-virus protection and
      accuracy of data input and output, and for maintaining a means external to
      the Platform for any reconstruction of any lost data. TO THE FULLEST
      EXTENT PROVIDED BY LAW, COMPANY WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE
      CAUSED BY A DISTRIBUTED DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER
      TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT ARTIST’S COMPUTER
      EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO
      ARTIST’S USE OF THE PLATFORM OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
      PLATFORM OR TO ARTIST DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY
      WEBSITE LINKED TO IT.{'\n\n'}
      ARTIST’S USE OF THE PLATFORM, ITS CONTENT, AND ANY SERVICES OR ITEMS
      OBTAINED THROUGH THE PLATFORM IS AT ARTIST’S OWN RISK. THE PLATFORM, ITS
      CONTENT, AND ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM ARE
      PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF
      ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER THE COMPANY NOR ANY PERSON
      ASSOCIATED WITH THE COMPANY MAKES ANY WARRANTY OR REPRESENTATION WITH
      RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
      AVAILABILITY OF THE PLATFORM. WITHOUT LIMITING THE FOREGOING, NEITHER THE
      COMPANY NOR ANYONE ASSOCIATED WITH THE COMPANY REPRESENTS OR WARRANTS THAT
      THE PLATFORM, ITS CONTENT, OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE
      PLATFORM WILL BE ACCURATE, RELIABLE, ERROR-FREE, OR UNINTERRUPTED, THAT
      DEFECTS WILL BE CORRECTED, THAT THE PLATFORM OR THE SERVER THAT MAKES IT
      AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS, OR THAT THE
      PLATFORM OR ANY SERVICES OR ITEMS OBTAINED THROUGH THE PLATFORM WILL
      OTHERWISE MEET ARTIST’S NEEDS OR EXPECTATIONS. {'\n\n'}
      TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL
      WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR
      OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY,
      NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.{'\n\n'}
      WITHOUT DEROGATING FROM ANY OF THE AFORESAID, ARTIST ACKNOWLEDGES AND
      AGREES THAT THE COMPANY IS NOT AND SHALL NOT BE RESPONSIBLE FOR THE
      AVAILABILITY OF THE THIRD-PARTY PROVIDERS’ INFRASTRUCTURE OR SERVICES, NOR
      SHALL THE COMPANY BE RESPONSIBLE OR LIABLE FOR ANY DAMAGE CAUSED TO ARTIST
      DUE TO ANY ACT OR OMISSION ON BEHALF OF SUCH THIRD-PARTY PROVIDERS OR YOUR
      USE OF THEIR SERVICES. {'\n\n'}
      THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR
      LIMITED UNDER APPLICABLE LAW.{'\n\n'}
      14. Limitation on Liability{'\n\n'}
      TO THE FULLEST EXTENT PROVIDED BY LAW, IN NO EVENT WILL THE COMPANY, ITS
      AFFILIATES, OR THEIR LICENSORS, SERVICE PROVIDERS, EMPLOYEES, AGENTS,
      OFFICERS, OR DIRECTORS BE LIABLE FOR DAMAGES OF ANY KIND, UNDER ANY LEGAL
      THEORY, ARISING OUT OF OR IN CONNECTION WITH ARTIST’S USE, OR INABILITY TO
      USE, THE PLATFORM, ANY WEBSITES LINKED TO IT, ANY CONTENT ON THE PLATFORM
      OR SUCH OTHER WEBSITES, INCLUDING ANY DIRECT, INDIRECT, SPECIAL,
      INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, INCLUDING BUT NOT LIMITED
      TO, PERSONAL INJURY, PAIN AND SUFFERING, EMOTIONAL DISTRESS, LOSS OF
      REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF
      USE, LOSS OF GOODWILL, LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING
      NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE.
      {'\n\n'}
      THE FOREGOING DOES NOT AFFECT ANY LIABILITY THAT CANNOT BE EXCLUDED OR
      LIMITED UNDER APPLICABLE LAW. {'\n\n'}
      15. Limitation on Time to File Claims{'\n\n'}
      ANY CAUSE OF ACTION OR CLAIM ARTIST MAY HAVE ARISING OUT OF OR RELATING TO
      THESE GENERAL TERMS OR THE PLATFORM MUST BE COMMENCED WITHIN ONE (1) YEAR
      AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE OF ACTION OR
      CLAIM IS PERMANENTLY BARRED.{'\n\n'}
      16. Entire Agreement. These General Terms constitute the entire agreement
      between the parties and supersedes all prior agreements and
      understandings, whether written or oral, relating to the subject matter of
      these General Terms. {'\n\n'}
      17. Amendment. Company may revise and update these General Terms from time
      to time in its sole discretion (including, but not limited to, on issues
      related to the fees payable to you). All changes are effective immediately
      when Company posts them, and apply to all access to and use of the
      Platform thereafter. However, any changes to the dispute resolution
      provisions set out in Governing Law and Jurisdiction (below) will not
      apply to any disputes for which the parties have actual notice on or
      before the date the change is posted on the Platform. Artist’s continued
      use of the Platform following the posting of revised General Terms means
      that Artist accepts and agrees to the changes. Artists are expected to
      check this page frequently so Artist is aware of any changes, as they are
      binding on Artist. {'\n\n'}
      18. Governing Law; Jurisdiction. These General Terms are construed,
      interpreted and enforced in accordance with the laws of the State of
      Israel, without regard to conflict of law principles that would result in
      the application of any law other than the State of Israel. In the event of
      a dispute arising under or related to These General Terms that cannot be
      resolved amicably between the parties, the competent courts of Tel-Aviv,
      Israel, shall have exclusive jurisdiction of any such dispute. {'\n\n'}
      19. Successors and Assigns. These General Terms shall be binding upon, and
      inure to the benefit of, both parties and their respective heirs,
      successors and assigns, including any corporation with which, or into
      which, the Company may be merged or which may succeed to its assets or
      business; provided, however, that the obligations of the Artist are
      personal and shall not be assigned by Artist.{'\n\n'}
      20. No Waiver. No delay or omission by the Company in exercising any right
      under these General Terms shall operate as a waiver of that or any other
      right. A waiver or consent given by the Company on any one occasion shall
      be effective only in that instance and shall not be construed as a bar or
      waiver of any right on any other occasion.{'\n\n'}
      21. Headings. The captions of the sections of these General Terms are for
      convenience of reference only and in no way define, limit or affect the
      scope or substance of any section.{'\n\n'}
      22. Severability. In the event that any provision of these General Terms
      shall be invalid, illegal or otherwise unenforceable, the validity,
      legality and enforceability of the remaining provisions shall in no way be
      affected or impaired thereby.{'\n\n'}
      23. Your Comments and Concerns.{'\n\n'}
      The Platform is owned and operated by KlydoClock Ltd. of Rinatiya, Israel.
      {'\n\n'}
      All notices of{' '}
      <Link
        href="https://www.klydoclock.com/pages/copyright-policy"
        target="_blank"
        rel="noopener noreferrer"
      >
        copyright
      </Link>{' '}
      infringement claims should be sent to the copyright agent designated in
      our Copyright Policy in the manner and by the means set out therein.
      {'\n\n'}
      All other feedback, comments, requests for technical support, and other
      communications relating to the Platform should be directed to:{' '}
      <Link href="mailto:contact@klydoclock.com">
        {' contact@klydoclock.com'}
      </Link>
    </DSValueTypography>
  );
};
export default Contract;

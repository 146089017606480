import { Theme } from '../../../../types';
import { useState } from 'react';
import { useTheme } from '@mui/material';
import useAppContext from '../../../../hooks/useAppContext';

type UseKlydoColorStackProps = {
  onChangeColor: (key: string, color: string) => boolean;
  theme?: Theme;
};

type AreColorsValid = {
  dialsColor?: boolean;
  handsColor?: boolean;
  pendulumRodColor?: boolean;
  pendulumColor?: boolean;
  backgroundColor?: boolean;
};
const useKlydoColorPickerModal = ({
  onChangeColor,
  theme,
}: UseKlydoColorStackProps) => {
  const { spacing, palette } = useTheme();
  const { isAdmin, impersonationActor } = useAppContext();
  const [updatedTheme, setUpdatedTheme] = useState<Theme>({
    dialsColor: theme?.dialsColor || '',
    handsColor: theme?.handsColor || '',
    pendulumRodColor: theme?.pendulumRodColor || '',
    pendulumColor: theme?.pendulumColor || '',
    backgroundColor: theme?.backgroundColor || '',
  });
  const [areColorsValid, setAreColorsValid] = useState<AreColorsValid>(
    theme
      ? {
          dialsColor: true,
          handsColor: true,
          pendulumRodColor: true,
          pendulumColor: true,
          backgroundColor: true,
        }
      : {},
  );

  const [currentColorKey, setCurrentColorKey] = useState<keyof Theme | null>(
    null,
  );

  const handleColorPick = (key: keyof Theme) => {
    setCurrentColorKey(key);
  };

  const handleColorChange = async (color: string) => {
    if (currentColorKey) {
      const isChangeApplicable = await onChangeColor(currentColorKey, color);
      setUpdatedTheme((prev) => ({
        ...prev,
        [currentColorKey]: color,
      }));
      setAreColorsValid((prev) => ({
        ...prev,
        [currentColorKey]: isChangeApplicable,
      }));
    }
  };
  const isThemeBad =
    !areColorsValid.backgroundColor ||
    !areColorsValid.dialsColor ||
    !areColorsValid.handsColor ||
    !areColorsValid.pendulumColor ||
    !areColorsValid.pendulumRodColor;

  const isAdminRole = isAdmin || !!impersonationActor;

  const disabledSave = !isAdminRole && isThemeBad;

  return {
    spacing,
    palette,
    disabledSave,
    updatedTheme,
    handleColorPick,
    handleColorChange,
    setCurrentColorKey,
    areColorsValid,
  };
};
export default useKlydoColorPickerModal;

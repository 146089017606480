import { useState } from 'react';
import useRemoveKlydoMutation from '../../hooks/useRemoveKlydoMutation';
import useAppContext from '../../../../hooks/useAppContext';
import { DELETE_KLYDO_SECCESSFULLY_ALERT } from '../../../../consts/alerts';
import { AlertType } from '../../../../design-system/DSAlert';

const useDeleteKlydo = (deletedKlydoId: string, onSuccess?: () => void) => {
  const { showAlert } = useAppContext();
  const [isDeleting, setIsDeleting] = useState(false);

  const { mutate: deleteKlydoMutation } = useRemoveKlydoMutation({
    onSuccess: () => {
      showAlert({
        message: DELETE_KLYDO_SECCESSFULLY_ALERT,
        alertType: AlertType.SUCCESS,
      });
      setIsDeleting(false);
      onSuccess?.();
    },
    onError: () => {
      setIsDeleting(false);
    },
    optimistic: true,
  });

  const handleDeleteKlydo = () => {
    if (deletedKlydoId) {
      setIsDeleting(true);
      deleteKlydoMutation(deletedKlydoId);
    }
  };

  return {
    isDeleting,
    handleDeleteKlydo,
  };
};
export default useDeleteKlydo;

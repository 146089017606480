import React from 'react';
import DSTextField from '../../../design-system/DSTextField';
import DSCircularProgress from '../../../design-system/DSCircularProgress';
import DSAutocomplete from '../../../design-system/DSAutocomplete';
import useUserDropdown from '../hooks/useUserDropdown';
import { SearchUserOutput } from '../../../api/user';
import DSListItem from '../../../design-system/DSListItem';

const UserDropdown: React.FC = () => {
  const {
    options,
    handleInputChange,
    handleOptionSelect,
    isLoading,
    spacing,
    dropDownValue,
    searchText,
  } = useUserDropdown();
  return (
    <DSAutocomplete
      options={options}
      getOptionLabel={(option: SearchUserOutput) => option.name}
      renderOption={(props, option) => (
        <DSListItem {...props} key={option.id || option.name}>
          {option.name}
        </DSListItem>
      )}
      inputValue={searchText}
      value={dropDownValue}
      onInputChange={(event, value) => {
        handleInputChange(event, value);
      }}
      onChange={handleOptionSelect}
      renderInput={(params) => (
        <DSTextField
          {...params}
          placeholder=""
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <DSCircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          sx={{
            width: spacing(30),
            '.MuiInputBase-root': {
              padding: 0,
            },
          }}
        />
      )}
      sx={{
        minWidth: spacing(18),
        marginLeft: spacing(3),
      }}
    />
  );
};

export default UserDropdown;

export type Theme = {
  backgroundColor: string;
  handsColor: string;
  pendulumColor: string;
  pendulumRodColor: string;
  dialsColor: string;
};

export type ReviewScheme = {
  type: 'delete' | 'edit';
  date: Date;
  msg?: string;
  approved?: boolean;
};

export type TimeTypes = 'daily' | 'weekly' | 'monthly' | 'yearly';

export type KlydoTimes = {
  type: TimeTypes;
  start: string;
  end: string;
  negative: boolean;
};

export type NavigationDrawerItem = {
  title: string;
  route: string;
  element?: JSX.Element;
  disabled?: boolean;
};

export enum ThemeStackStrings {
  dialsColor = 'Dials Color',
  handsColor = 'Hands Color',
  pendulumRodColor = 'Pendulum Rod Color',
  pendulumColor = 'Pendulum Color',
  backgroundColor = 'Pendulum Background Color',
}

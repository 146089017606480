import { KlydoUnion } from '../../../../api/klydo';
import KlydoPreview from '../../../../components/klydoPreview/KlydoPreview';
import { DSCardActions, DSCardContent } from '../../../../design-system/Card';
import DSBox from '../../../../design-system/DSBox';
import DSKlydoCard from '../../../../design-system/Klydo/DSKlydoCard';
import { useTheme } from '@mui/material';
import {
  DSTitleTypography,
  DSSubtitleTypography,
} from '../../../../design-system/Typography';
import useKlydoCard from '../hooks/useKlydoCard';
import MenuButton from '../../../../components/MenuButton/MenuButton';
import KlydoStatus from './KlydoStatus';
import DSDivider from '../../../../design-system/DSDivider';
import {
  INCOMPLETE_PROFILE_ALERT,
  INCOMPLETE_PROFILE_REASON_ALERT,
  SUBMIT_FOR_REVIEW_ALERT,
} from '../../../../consts/alerts';
import ErrorDialog from '../../../../components/ErrorDialog';
import WarningDialog from '../../../../components/WarningDialog';

export type KlydoCardProps = {
  klydo: KlydoUnion;
  isStatic?: boolean;
  isMenuHidden?: boolean;
  handleRemoveKlydoFromPack?: (klydoId: string) => void;
  handleDeleteKlydo?: (klydoId: string) => void | undefined;
};

const KlydoCard = ({
  klydo,
  isStatic,
  isMenuHidden,
  handleRemoveKlydoFromPack,
  handleDeleteKlydo,
}: KlydoCardProps) => {
  const { spacing } = useTheme();
  const {
    menuButtonItemsList,
    profileIncompleteErrorDialogOpen,
    handleCloseErrorDialog,
    onEditProfile,
    onClosedSubmitDialog,
    submitDialogOpen,
    onApprovedSubmit,
    isEditAvailable,
    handleKlydoCardClick,
  } = useKlydoCard({
    klydo,
    handleDeleteKlydo,
    handleRemoveKlydoFromPack,
  });

  return (
    <DSKlydoCard
      onClick={handleKlydoCardClick}
      sx={{
        cursor: isEditAvailable ? 'pointer' : 'default',
      }}
    >
      <KlydoPreview
        klydo={klydo}
        isAnimationStatic={isStatic}
        isPendulumStatic={isStatic}
        backgroundHidden
        dialsHidden
        scale={0.2}
      />

      <DSBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <DSCardContent
          sx={{
            textAlign: 'left',
            paddingBottom: 0,
          }}
        >
          <DSBox sx={{ minHeight: spacing(14), maxWidth: spacing(19) }}>
            <DSTitleTypography
              sx={{
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: 3,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                wordWrap: 'break-word',
                width: '100%',
                whiteSpace: 'normal',
              }}
            >
              {klydo.name}
            </DSTitleTypography>

            <DSSubtitleTypography>
              Updated At{' '}
              {new Date(klydo.updatedAt)
                .toLocaleDateString(undefined, {
                  year: '2-digit',
                  month: '2-digit',
                  day: '2-digit',
                })
                .replace(/\//g, '.')}
            </DSSubtitleTypography>
          </DSBox>
        </DSCardContent>
        <DSDivider
          flexItem
          sx={{
            alignSelf: 'flex-start',
            width: '90%',
            borderWidth: '2px',
          }}
        />
        <DSBox>
          <DSCardActions
            sx={{ justifyContent: 'space-between' }}
            onClick={(event) => event.stopPropagation()}
          >
            <KlydoStatus klydo={klydo} />
            {!isMenuHidden && <MenuButton menuItems={menuButtonItemsList} />}
          </DSCardActions>
        </DSBox>
      </DSBox>
      <ErrorDialog
        errorTitle={INCOMPLETE_PROFILE_ALERT}
        errorReason={INCOMPLETE_PROFILE_REASON_ALERT}
        isErrorDialogOpen={profileIncompleteErrorDialogOpen}
        handleClose={handleCloseErrorDialog}
        onClick={onEditProfile}
      />
      <WarningDialog
        warningMessage={SUBMIT_FOR_REVIEW_ALERT}
        open={submitDialogOpen}
        handleConfirmationClick={onApprovedSubmit}
        handleClose={onClosedSubmitDialog}
      />
    </DSKlydoCard>
  );
};

export default KlydoCard;

import DSBox from '../../design-system/DSBox';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import useMenuButton from './hooks/useMenuButton';
import DSMenuIconButton from '../../design-system/DSMenuIconButton';
import DSMenu from '../../design-system/DSMenu';
import DSMenuItem from '../../design-system/DSMenuItem';

export type MenuItemsProps = {
  onClick: () => void;
  isDisabled: boolean;
  title: string;
};

type MenuButtonProps = {
  menuItems: MenuItemsProps[];
};

const MenuButton = ({ menuItems }: MenuButtonProps) => {
  const { anchorElement, isMenuOpen, handleMenuClick, handleMenuClose } =
    useMenuButton();
  return (
    <DSBox>
      <DSMenuIconButton
        onClick={handleMenuClick}
        aria-controls={isMenuOpen ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={isMenuOpen ? 'true' : undefined}
      >
        <MoreVertIcon />
      </DSMenuIconButton>
      <DSMenu
        anchorEl={anchorElement}
        open={isMenuOpen}
        onClose={handleMenuClose}
        sx={{
          mt: 1,
        }}
      >
        {menuItems.map((button) => (
          <DSMenuItem
            key={button.title}
            disabled={button.isDisabled}
            onClick={() => {
              button.onClick();
              handleMenuClose();
            }}
          >
            {button.title}
          </DSMenuItem>
        ))}
      </DSMenu>
    </DSBox>
  );
};

export default MenuButton;

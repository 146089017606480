import React from 'react';
import { MenuItem, useTheme } from '@mui/material';

const DSMenuItem = (props: React.ComponentProps<typeof MenuItem>) => {
  const { spacing } = useTheme();
  return (
    <MenuItem
      {...props}
      sx={{
        ...props.sx,
        padding: spacing(1, 2),
        fontFamily: 'Gilroy-Medium',
        fontSize: spacing(2),
      }}
    />
  );
};

export default DSMenuItem;

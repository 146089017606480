import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useDeviceType from '../../../hooks/useDeviceType';

const useNavigationDrawer = () => {
  const location = useLocation();
  const currentRoute = location.pathname;
  const { isSmallLaptop } = useDeviceType();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isHelpDialogOpen, setIsHelpDialogOpen] = useState(false);

  const navigate = useNavigate();

  const toggleDrawer = (open: boolean) => {
    setIsDrawerOpen(open);
  };

  const handleClick = (route: string) => {
    navigate(route);
    if (isSmallLaptop) toggleDrawer(false);
  };

  return {
    toggleDrawer,
    isDrawerOpen,
    handleClick,
    isHelpDialogOpen,
    setIsHelpDialogOpen,
    currentRoute,
  };
};

export default useNavigationDrawer;

import React from 'react';
import { FormControlLabel } from '@mui/material';

const DSFormControlLabel = (
  props: React.ComponentProps<typeof FormControlLabel>,
) => {
  return <FormControlLabel {...props} />;
};

export default DSFormControlLabel;

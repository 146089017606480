import React from 'react';
import DSStack from '../../../design-system/DSStack';
import DSSocialChip from '../../../design-system/DSSocialChip';
import { useTheme } from '@mui/material';
import { SocialMedia } from '../../../api/user';
import { SOCIAL_NETWORKS } from '../hooks/socialNetwork';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';

type SocialChipStackProps = {
  socialMedia: SocialMedia[];
};

const SocialChipStack: React.FC<SocialChipStackProps> = ({ socialMedia }) => {
  const { palette } = useTheme();

  const validSocialMedia = socialMedia.filter((item) =>
    SOCIAL_NETWORKS.some(
      (network) => network.name.toLowerCase() === item.network.toLowerCase(),
    ),
  );

  return (
    <DSStack
      direction="row"
      spacing={2}
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        rowGap: 1,
      }}
    >
      {validSocialMedia.map((item, index) => {
        const socialNetwork = SOCIAL_NETWORKS.find(
          (network) =>
            network.name.toLowerCase() === item.network.toLowerCase(),
        )!;
        if (!socialNetwork) {
          console.error(`Social network ${item.network} not found`);
        }

        const IconComponent = socialNetwork.icon;

        return (
          <DSSocialChip
            key={index}
            icon={
              <IconComponent
                color={palette.text.secondary}
                width={'20px'}
                height={'20px'}
              />
            }
            label={item.link}
          />
        );
      })}
    </DSStack>
  );
};

export default SocialChipStack;

import { InfiniteData, useQueryClient } from '@tanstack/react-query';
import klydoApiClient, { DraftKlydo } from '../../../api/klydo';
import useKlydoMutation, {
  UseKlydoMutationOptions,
} from '../../../hooks/useKlydoMutation';

const useUpsertDraftKlydoMutation = (
  options?: UseKlydoMutationOptions<
    unknown,
    { klydoId: string; klydo: Partial<DraftKlydo> },
    unknown
  >,
) => {
  const queryClient = useQueryClient();
  return useKlydoMutation({
    mutationKey: ['upsert-klydo'],
    mutationFn: ({ klydoId, klydo }) => {
      return klydoApiClient.upsertDraftKlydo(klydoId, klydo);
    },
    onMutate: ({ klydoId, klydo }) => {
      const matchingQueries = queryClient
        .getQueryCache()
        .findAll({ queryKey: ['klydosList'] });

      queryClient.setQueryData(['klydo', klydoId], (oldData: DraftKlydo) => ({
        ...oldData,
        ...klydo,
      }));

      matchingQueries.forEach(({ queryKey }) => {
        queryClient.setQueryData<InfiniteData<DraftKlydo[]>>(
          queryKey,
          (oldData) => {
            if (!oldData) return oldData;

            return {
              ...oldData,
              pages: oldData.pages.map((page) =>
                page.map((oldklydo) =>
                  oldklydo.id === klydoId
                    ? { ...oldklydo, ...klydo }
                    : oldklydo,
                ),
              ),
            };
          },
        );
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: ['klydosList'] });
    },
    ...options,
  });
};

export default useUpsertDraftKlydoMutation;

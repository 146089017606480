import { debounce } from 'lodash';
import { useEffect } from 'react';

type UseAutoSaveProps = {
  onSave: () => void;
  dependencies: any[];
  delay?: number;
};

const DELAY_DEFAULT_NUMBER = 1000;

const useAutoSave = ({
  dependencies,
  onSave,
  delay = DELAY_DEFAULT_NUMBER,
}: UseAutoSaveProps) => {
  const debouncedSave = debounce(onSave, delay);
  useEffect(() => {
    debouncedSave.cancel();
    debouncedSave();
    return () => debouncedSave.cancel();
  }, dependencies);
};
export default useAutoSave;

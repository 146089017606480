import { createContext } from 'react';
import firebase from 'firebase/compat/app';
import { User } from '../api/user';
import { Alert } from '../design-system/DSAlert';

export type AppContextType = {
  user: User;
  authUser: firebase.User | null;
  setAuthUser: (user: firebase.User) => void;
  setUser: React.Dispatch<React.SetStateAction<User | null>>;
  isAdmin: boolean;
  impersonationActor?: string;
  alert: Alert | undefined;
  showAlert: (alert: Alert | undefined) => void;
  hasAgreedToContract: boolean;
  setHasAgreedToContract: React.Dispatch<React.SetStateAction<boolean>>;
};

export const AppContext = createContext<AppContextType>({} as AppContextType);

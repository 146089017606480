import axios, { AxiosProgressEvent } from 'axios';
import { Theme } from '../types';
import { baseClient } from './baseRequest';
import { UploadFileInput, UploadResponse } from './uploads';
import { ApprovedKlydo } from './klydo';
import { Locale } from '../hooks/useLoacle';

export type KlydosViewersResult = {
  clockId: string;
  city: string;
  latitude: number;
  longitude: number;
  loopUrl: string;
};

export type StudioApiClient = {
  getSuggestedKlydoPallete: (
    loopUrl: string,
    abortSignal?: AbortSignal,
  ) => Promise<Theme[]>;
  getKlydoViewers: (windowHours: number) => Promise<KlydosViewersResult[]>;
  getTopKlydos: (limit: number) => Promise<ApprovedKlydo[]>;
  getSumeDise: () => Promise<number>;
  uploadKlydoLoop: (input: UploadFileInput) => Promise<UploadResponse>;
  getLocale: () => Promise<Locale>;
  getKlydoDise: (klydoId: string) => Promise<number>;
  getKlydoGuideImageUrl: () => Promise<string>;
};

const studioApiClient: StudioApiClient = {
  getSuggestedKlydoPallete: async (
    loopUrl: string,
    abortSignal?: AbortSignal,
  ) => {
    return baseClient.post({
      url: `/studio/suggest-klydo-palette`,
      body: {
        loopUrl: loopUrl,
      },
      signal: abortSignal,
    });
  },
  getKlydoViewers(windowHours) {
    return baseClient.post<KlydosViewersResult[]>({
      url: '/studio/get-klydos-viewers',
      body: {
        windowHours,
      },
    });
  },
  getTopKlydos(limit) {
    return baseClient.post<ApprovedKlydo[]>({
      url: '/studio/get-top-klydos',
      body: {
        limit,
      },
    });
  },
  getSumeDise: async () => {
    return baseClient.get<number>({
      url: '/studio/get-sum-dise',
    });
  },
  uploadKlydoLoop: async (input) => {
    return baseClient.post<UploadResponse>({
      url: '/studio/process-klydo-loop',
      body: {
        file: input.file,
        fileType: input.fileType,
        options: input.options,
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (input.onUploadProgress) {
          const percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / (progressEvent?.total || 1),
          );
          input.onUploadProgress(percentCompleted);
        }
      },
    });
  },
  getLocale: async () => {
    const response = await axios.get<Locale>('https://ip.klydoclock.com/');
    return response.data;
  },
  getKlydoDise: async (klydoId: string) => {
    return baseClient.get<number>({ url: `/studio/get-klydo-dise/${klydoId}` });
  },
  getKlydoGuideImageUrl: async () => {
    return baseClient.get<string>({
      url: '/studio/klydo-guide-image-url',
    });
  },
};

export default studioApiClient;

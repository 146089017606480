import React from 'react';
import { Card, useTheme } from '@mui/material';

const DSKlydoCard = (props: React.ComponentProps<typeof Card>) => {
  const { spacing } = useTheme();
  return (
    <Card
      variant="elevation"
      elevation={5}
      {...props}
      sx={{
        aspectRatio: '42 / 30',
        maxWidth: spacing(42),
        maxHeight: spacing(30),
        borderRadius: spacing(3.5),
        alignItems: 'center',
        display: 'flex',
        paddingX: spacing(1),
        ...props.sx,
      }}
    />
  );
};

export default DSKlydoCard;

import { useEffect } from 'react';
import firebaseService from '../../../firebase/firebaseService';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { getCountryData, TCountryCode } from 'countries-list';
import useAppContext from '../../../hooks/useAppContext';

const useProfile = () => {
  const { user } = useAppContext();
  const { spacing } = useTheme();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await firebaseService.signOut();
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleEditProfile = () => {
    navigate('/edit-profile');
  };

  useEffect(() => {
    if (!user) {
      handleLogout();
    }
  }, [user]);

  const { name, email, photoUrl, description, country, socialMedia } = user;

  const { name: countryName } = getCountryData(country as TCountryCode) || {};

  return {
    handleLogout,
    handleEditProfile,
    spacing,
    name,
    email,
    photoUrl,
    description,
    country,
    countryName,
    socialMedia,
  };
};

export default useProfile;

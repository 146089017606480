import { Outlet } from 'react-router-dom';
import DSBox from './design-system/DSBox';
import NavigationDrawer from './components/navigationDrawer/NavigationDrawer';
import { NavigationDrawerItem } from './types';
import Dashboard from './views/Dashboard/components/Dashboard';
import Klydos from './views/Klydos/KlydosList/components/Klydos';
import AppBar from './components/AppBar/components/AppBar';
import { useTheme } from '@mui/material';
import { APPBAR_HIGHT, DRAWER_WIDTH } from './consts/general';
import DSAlert from './design-system/DSAlert';
import useAppContext from './hooks/useAppContext';
import DSSnackbar from './design-system/DSSnackbar';
import ContractAgreementModal from './components/ContractAgreementModal';

export const navigationDrawerPages: NavigationDrawerItem[] = [
  { title: 'Dashboard', route: '/dashboard', element: <Dashboard /> },
  { title: 'Klydos', route: '/klydos', element: <Klydos /> },
  { title: 'Channels (soon!)', disabled: true, route: '/channels' },
];

const MainLayout = () => {
  const { spacing, palette } = useTheme();
  const { alert, showAlert, hasAgreedToContract, isAdmin } = useAppContext();

  if (!hasAgreedToContract && !isAdmin) {
    return <ContractAgreementModal />;
  }
  return (
    <DSBox
      sx={{
        backgroundColor: palette.background.default,
      }}
    >
      <NavigationDrawer pages={navigationDrawerPages} />
      <AppBar />

      <DSBox
        sx={{
          flexGrow: 1,
          paddingX: { lg: '1.1%' },
          marginTop: APPBAR_HIGHT,
          marginLeft: { lg: spacing(DRAWER_WIDTH) },
          backgroundColor: palette.background.default,
        }}
      >
        <Outlet />
      </DSBox>
      {alert && (
        <DSSnackbar
          open={!!alert}
          autoHideDuration={5000}
          onClose={() => showAlert(undefined)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <DSAlert message={alert?.message} alertType={alert?.alertType} />
        </DSSnackbar>
      )}
    </DSBox>
  );
};

export default MainLayout;

import React, { useEffect } from 'react';
import firebase from 'firebase/compat/app';
import useUserInitializer from './hooks/useUserInitializer';
import { SplashScreen } from '../SplashScreen/SplashScreen';
import { User } from '../api/user';
import useUserMutation from '../views/SignUp/hooks/useUserMutation';

type UserInitializerProps = {
  children: React.ReactNode;
  authUser: firebase.User;
  setUser: (user: User) => void;
  isAdmin: boolean;
  setHasAgreedToContract: React.Dispatch<React.SetStateAction<boolean>>;
};

const UserInitializer: React.FC<UserInitializerProps> = ({
  children,
  authUser,
  setUser,
  isAdmin,
  setHasAgreedToContract,
}) => {
  const { loading, user } = useUserInitializer(
    authUser,
    setUser,
    isAdmin,
    setHasAgreedToContract,
  );

  const parsedAuthPhotoUrl =
    authUser?.photoURL?.replace('s96-c', 's400-c') ||
    'https://ui-avatars.com/api/?name=User';

  const { mutate } = useUserMutation(authUser.uid, {
    onSuccess: (user: User) => {
      setUser(user);
    },
    optimistic: false,
  });

  useEffect(() => {
    if (!user && !loading) {
      mutate({
        name: authUser.displayName || '',
        email: authUser.email || '',
        photoUrl: parsedAuthPhotoUrl,
        description: '',
        country: '',
        socialMedia: [],
      });
    }
  }, [user, loading, authUser, mutate, parsedAuthPhotoUrl]);

  if (loading) {
    return <SplashScreen />;
  }
  return <>{children}</>;
};

export default UserInitializer;

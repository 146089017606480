import ObjectId from 'bson-objectid';

export type ParseUrlInput = {
  url: string;
  isStatic?: boolean;
  size?: number;
};

export const parseCloudinaryUrl = (input: ParseUrlInput) => {
  let { url } = input;
  if (!url) return '';
  const { isStatic, size } = input;
  const type = url.split('.').pop();
  const params = `c_scale,w_${size}`;
  const framesParams = 'so_auto';
  if (isStatic) {
    url = url.replace(/\.(mp4|webp|gif)$/, '.jpg');
    if (url!.includes('image/upload') || url.includes('video/upload')) {
      url = url.replace('/upload/', `/upload/${framesParams}/`);
    } else {
      url = url.replace('/klydoclock/', `/klydoclock/${framesParams}/`);
    }
    return url;
  }
  if (type === 'mp4') {
    if (size) {
      if (url!.includes('image/upload') || url.includes('video/upload')) {
        url = url.replace('/upload/', `/upload/${params}/`);
      } else {
        url = url.replace('/klydoclock/', `/klydoclock/${params}/`);
      }
    }
  }
  return url;
};

type ViewType = 'video' | 'image' | 'none';
const imgTypes = [
  'jpg',
  'gif',
  'avif',
  'apng',
  'png',
  'ico',
  'cur',
  'jpeg',
  'jfif',
  'pjpeg',
  'pjp',
  'tif',
  'webp',
  'bmp',
];
const videoTypes = ['mp4', 'mpge', 'avi', 'mov', 'mkv', 'webm', 'ogg', 'ogv'];
export function getMediaType(url: string): ViewType {
  if (url.startsWith('data'))
    return url.startsWith('data:video')
      ? 'video'
      : url.startsWith('data:image')
        ? 'image'
        : 'none';
  const arr = url.split('.');
  const typ = arr[arr.length - 1];
  return imgTypes.includes(typ)
    ? 'image'
    : videoTypes.includes(typ)
      ? 'video'
      : 'none';
}

export function formatDistanceToNow(date: Date) {
  const now = new Date();
  const givenDate = new Date(date);
  const diffInMilliseconds = now.getTime() - givenDate.getTime();

  if (isNaN(diffInMilliseconds)) {
    return 'Invalid date';
  }

  const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  const diffInMonths = Math.floor(diffInDays / 30);
  const diffInYears = Math.floor(diffInMonths / 12);

  if (diffInSeconds < 60) {
    return `${diffInSeconds} seconds ago`;
  } else if (diffInMinutes < 60) {
    return `${diffInMinutes} minutes ago`;
  } else if (diffInHours < 24) {
    return `${diffInHours} hours ago`;
  } else if (diffInDays < 30) {
    return `${diffInDays} days ago`;
  } else if (diffInMonths < 12) {
    return `${diffInMonths} months ago`;
  } else {
    return `${diffInYears} years ago`;
  }
}

export const fontColorContrast = (hex: string) => {
  if (!hex) {
    return '#FFFFFFF';
  }
  if (!/^#[0-9A-F]{6}$/i.test(hex)) {
    console.error('Invalid hex color format');
    return '#FFFFFF';
  }

  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);

  const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

  return luminance > 0.5 ? '#000000' : '#FFFFFF';
};

export const genrateObjectId = () => {
  return new ObjectId().toHexString();
};

import React, { forwardRef } from 'react';
import { Popper } from '@mui/material';

const DSPopper = forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof Popper>
>((props) => {
  const { children, ...popperProps } = props;

  return <Popper {...popperProps}>{children}</Popper>;
});

export default DSPopper;

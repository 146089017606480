import { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import userApiClient, { User } from '../../api/user';
import { useNavigate } from 'react-router-dom';

const useUserInitializer = (
  authUser: firebase.User | null,
  setUser: (user: User) => void,
  isAdmin: boolean,
  setHasAgreedToContract: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const [loading, setLoading] = useState(true);
  const [user, setLocalUser] = useState<User | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAndInitializeUser = async () => {
      try {
        if (!authUser) {
          setLoading(false);
          return;
        }

        await authUser.reload();
        const refreshedUser = firebase.auth().currentUser;

        if (
          (refreshedUser && refreshedUser.emailVerified) ||
          (isAdmin && refreshedUser)
        ) {
          const res = await userApiClient.login(refreshedUser.uid);
          if (res) {
            setUser(res);
            setLocalUser(res);
            const hasAgreed = !!res?.contractAccepted;
            setHasAgreedToContract(hasAgreed);

            if (!hasAgreed) {
              navigate('/contract-agreement');
            }
          }
        } else {
          console.warn('Email not verified. Waiting for verification...');
        }
      } catch (error) {
        console.error('Error in UserInitializer:', error);
      } finally {
        setLoading(false);
      }
    };

    checkAndInitializeUser();
  }, [authUser, setUser]);

  return { loading, user };
};

export default useUserInitializer;

import DSDrawer from '../../design-system/DSDrawer';
import DSButton from '../../design-system/DSButtom';
import MenuIcon from '@mui/icons-material/Menu';
import { NavigationDrawerItem } from '../../types';
import useNavigationDrawer from './hooks/useNavigationDrawer';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  DSSubtitleTypography,
  DSTitleTypography,
} from '../../design-system/Typography';
import DSIconButton from '../../design-system/DSIconButton';
import DSBox from '../../design-system/DSBox';
import { APPBAR_HIGHT, DRAWER_WIDTH } from '../../consts/general';
import { useTheme } from '@mui/material';
import { ReactComponent as DashboardIcon } from '../../design-system/Icons/DashboardIcon.svg';
import DSDialog from '../../design-system/DSDialog';
import DSDialogTitle from '../../design-system/DSDialogTitle';
import DSDialogContent from '../../design-system/DSDialogContent';
import useDeviceType from '../../hooks/useDeviceType';
import { HELP_ALERT, SUPPORT_EMAIL } from '../../consts/alerts';
import { ReactComponent as ChannelIcon } from '../../design-system/Icons/ChannelIcon.svg';


type NavigationDrawerProps = {
  pages: NavigationDrawerItem[];
};

const NavigationDrawer = ({ pages }: NavigationDrawerProps) => {
  const { isSmallLaptop } = useDeviceType();
  const {
    toggleDrawer,
    isDrawerOpen,
    handleClick,
    isHelpDialogOpen,
    setIsHelpDialogOpen,
    currentRoute,
  } = useNavigationDrawer();

  const { zIndex, palette, spacing } = useTheme();

  const getIconForRoute = (route: string) => {
    switch (route) {
      case '/dashboard':
        return <DashboardIcon />;
      case '/klydos':
        return <AccessTimeIcon />;
      case '/channels':
        return <ChannelIcon />;
      default:
        return null;
    }
  };

  return (
    <>
      {isSmallLaptop && !isDrawerOpen && (
        <DSIconButton
          onClick={() => toggleDrawer(true)}
          sx={{
            position: 'fixed',
            top: spacing(2),
            left: spacing(2),
            zIndex: zIndex.drawer,
            color: palette.text.primary,
          }}
        >
          <MenuIcon />
        </DSIconButton>
      )}

      <DSDrawer
        variant={isSmallLaptop ? 'temporary' : 'permanent'}
        anchor="left"
        open={isSmallLaptop ? isDrawerOpen : true}
        onClose={() => toggleDrawer(false)}
        sx={{
          '& .MuiDrawer-paper': {
            width: spacing(DRAWER_WIDTH),
            height: `calc(100% - ${spacing(APPBAR_HIGHT)})`,
            marginTop: spacing(APPBAR_HIGHT),
            borderRadius: '0px 28px 0px 0px',
            background:
              'var(--Background-Manu, linear-gradient(0deg, #0C0C0C 0%, #121212 100%))',
            boxShadow:
              '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
            borderRight: 'none',
            p: 4,
          },
        }}
      >
        <DSBox
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: spacing(2),
          }}
        >
          {pages.map((page) => {
            const isSelected = currentRoute === page.route;
            return (
              <DSButton
                key={page.route}
                onClick={() => handleClick(page.route)}
                startIcon={getIconForRoute(page.route)}
                disabled={page.disabled}
                sx={{
                  justifyContent: 'flex-start',
                  gap: spacing(2),
                  width: '100%',
                  textTransform: 'none',
                  px: spacing(5),
                  py: spacing(2),
                  borderRadius: spacing(12),
                  backgroundColor: isSelected
                    ? 'rgba(255, 255, 255, 0.16)'
                    : 'transparent',
                  color: isSelected
                    ? palette.text.primary
                    : palette.text.secondary,
                  '&:hover': {
                    backgroundColor: isSelected ? '#444' : '#333',
                  },
                  transition: 'background-color 0.2s ease',
                }}
              >
                <DSTitleTypography
                  color={page.disabled ? palette.text.disabled : undefined}
                >
                  {page.title}
                </DSTitleTypography>
              </DSButton>
            );
          })}
        </DSBox>

        <DSBox sx={{ p: spacing(2) }}>
          <DSButton
            startIcon={<HelpOutlineIcon />}
            onClick={() => setIsHelpDialogOpen(true)}
            sx={{
              color: palette.text.secondary,
              textTransform: 'none',

              textAlign: 'center',
              width: '50%',
              borderRadius: spacing(3),
              '&:hover': {
                backgroundColor: '#333',
              },
            }}
          >
            Help
          </DSButton>
        </DSBox>
        <DSDialog
          open={isHelpDialogOpen}
          onClose={() => setIsHelpDialogOpen(false)}
          sx={{
            textAlign: 'center',
          }}
        >
          <DSDialogTitle>
            <DSTitleTypography>Help</DSTitleTypography>
          </DSDialogTitle>
          <DSDialogContent>
            <DSSubtitleTypography sx={{ width: '100%' }}>
              {HELP_ALERT}
            </DSSubtitleTypography>
            <DSSubtitleTypography
              sx={{
                textDecorationLine: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => (window.location.href = `mailto:${SUPPORT_EMAIL}`)}
            >
              {SUPPORT_EMAIL}
            </DSSubtitleTypography>
          </DSDialogContent>
        </DSDialog>
      </DSDrawer>
    </>
  );
};

export default NavigationDrawer;

import DSAvatar from '../../../design-system/DSAvatar';
import DSBox from '../../../design-system/DSBox';
import DSTextField from '../../../design-system/DSTextField';
import { Input } from '@mui/material';
import useEditProfile from '../hooks/useEditProfile';
import DSPrimaryButton from '../../../design-system/DSPrimaryButtom';
import { ArrowBack } from '@mui/icons-material';
import DSTabHeaderTypography from '../../../design-system/Typography/DSSTabHeaderTypography';
import DSLabelTypography from '../../../design-system/Typography/DSLabelTypography';
import DSGradientViewPaper from '../../../design-system/Paper/DSGradientViewPaper';
import SocialEditableStack from './SocialEditableStack';
import { ReactComponent as PersonFilled } from '../../../design-system/Icons/PersonFilled.svg';
import { ReactComponent as EditFilled } from '../../../design-system/Icons/EditFilled.svg';
import DSIconButton from '../../../design-system/DSIconButton';
import CountryDropdown from '../../../components/CountryDropdown/components/CountryDropdown';
import {
  USER_DESCRIPTION_LIMIT,
  USER_NAME_LIMIT,
} from '../../../consts/textFieldsLimitations';
import useBackNavigation from '../../../hooks/useBackNavigation';
import DSCircularProgress from '../../../design-system/DSCircularProgress';
import { KLYDO_YELLOW } from '../../../consts/colors';

const EditProfile = () => {
  const {
    name,
    handleNameChange,
    description,
    setDescription,
    photoUrl,
    handleFileSelection,
    handleSave,
    country,
    setCountry,
    spacing,
    socialMedia,
    setSocialMedia,
    disabledSaveButton,
    isUploadingProfileImage,
  } = useEditProfile();

  const { goBack } = useBackNavigation();

  return (
    <DSBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        padding: spacing(4),
        gap: spacing(3),
      }}
    >
      <DSBox
        sx={{
          display: 'flex',
          gap: spacing(1),
          flexDirection: 'row',
        }}
      >
        <ArrowBack
          onClick={goBack}
          sx={{
            fontSize: spacing(6),
            cursor: 'pointer',
          }}
        />
        <DSTabHeaderTypography>Edit Profile</DSTabHeaderTypography>
      </DSBox>

      <DSBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: spacing(3),
        }}
      >
        <DSBox
          sx={{
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
            width: spacing(25),
          }}
        >
          <DSAvatar
            typeof="icon"
            src={photoUrl}
            sx={{
              width: spacing(22),
              height: spacing(22),
            }}
          >
            {!photoUrl && <PersonFilled />}
          </DSAvatar>
          <DSIconButton
            component={'label'}
            sx={{
              cursor: 'pointer',
              position: 'absolute',
              bottom: spacing(0),
              right: spacing(0),
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
          >
            <EditFilled />
            <Input
              id="upload-input"
              type="file"
              inputProps={{ accept: '.png, .jpeg, .jpg' }}
              style={{ display: 'none' }}
              onChange={handleFileSelection}
            />
          </DSIconButton>
        </DSBox>
        <DSBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: spacing(1),
            width: '30%',
            height: 'auto',
          }}
        >
          <DSLabelTypography>
            {`User Name (max ${USER_NAME_LIMIT} characters)`}
          </DSLabelTypography>
          <DSTextField
            placeholder="Username"
            value={name}
            onChange={(e) => handleNameChange(e.target.value)}
            fullWidth
            slotProps={{
              input: {
                inputProps: { maxLength: USER_NAME_LIMIT },
              },
            }}
          />
        </DSBox>
      </DSBox>
      <DSBox
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexShrink: 0,
        }}
      >
        <DSGradientViewPaper
          sx={{
            flexDirection: 'column',
            width: { xxs: '90vw', xs: '80vw', lg: '50vw' },
            height: 'auto',
            paddingY: { xxs: spacing(2), md: spacing(4) },
            paddingX: { xxs: spacing(2), md: spacing(4) },
          }}
        >
          <DSBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: spacing(1),
              width: '100%',
              height: 'auto',
            }}
          >
            <DSLabelTypography>
              {`Description (max ${USER_DESCRIPTION_LIMIT} characters)`}
            </DSLabelTypography>
            <DSTextField
              sx={{
                padding: '0px 5px',
                '.MuiInputBase-input': {
                  padding: 0,
                },
              }}
              placeholder="Describe yourself..."
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              multiline
              rows={3}
              fullWidth
              slotProps={{
                input: {
                  inputProps: { maxLength: USER_DESCRIPTION_LIMIT },
                },
              }}
            />
          </DSBox>

          <DSBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: spacing(1),
              width: '100%',
              height: 'auto',
            }}
          >
            <DSLabelTypography>Country</DSLabelTypography>
            <CountryDropdown
              initialCountryCode={country}
              onSelect={(country) => setCountry(country)}
            />
          </DSBox>
          <DSBox sx={{ display: 'flex', width: '100%' }}>
            <SocialEditableStack
              socialMedia={socialMedia}
              setSocialMedia={setSocialMedia}
            />
          </DSBox>
        </DSGradientViewPaper>
      </DSBox>
      <DSBox sx={{ textAlign: 'center' }}>
        <DSPrimaryButton
          onClick={handleSave}
          disabled={disabledSaveButton}
          sx={{
            padding: '10px 24px',
          }}
        >
          {isUploadingProfileImage ? (
            <DSCircularProgress
              size={spacing(4)}
              sx={{ color: KLYDO_YELLOW }}
            />
          ) : (
            `Save`
          )}
        </DSPrimaryButton>
      </DSBox>
    </DSBox>
  );
};

export default EditProfile;

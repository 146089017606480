import { useCallback, useState } from 'react';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import klydoApiClient, { KlydoUnion } from '../../../../api/klydo';
import { useNavigate } from 'react-router-dom';
import { genrateObjectId } from '../../../../utils';

const PAGE_SIZE = 12;

const useKlydosView = () => {
  const [searchText, setSearchText] = useState('');

  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const handleTextChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    [],
  );

  const { data, fetchNextPage, isLoading, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery<KlydoUnion[], Error>({
      queryKey: ['klydosList', searchText],
      queryFn: async ({ pageParam }) => {
        const { skip = 0 } = pageParam as { skip?: number };
        const searchOptions = {
          limit: PAGE_SIZE,
          skip,
          sort: { createdAt: -1 },
        };
        const res = await klydoApiClient.search(searchText, searchOptions);

        res.forEach((klydo) => {
          queryClient.setQueryData(['klydo', klydo.id], klydo);
        });

        return res;
      },
      getNextPageParam: (lastPage, allPages) => {
        const currentLength = allPages.flat().length;
        return currentLength < PAGE_SIZE ? undefined : { skip: currentLength };
      },
      initialPageParam: { skip: 0 },
    });

  const handleCreateClick = () => {
    const createdObjectId = genrateObjectId();
    navigate(`/klydo/${createdObjectId}`);
  };

  const flatData = data?.pages.flat();

  return {
    data: flatData,
    isLoading,
    searchText,
    isFetchingNextPage,
    hasNextPage,
    handleTextChange,
    fetchNextPage,
    handleCreateClick,
  };
};

export default useKlydosView;

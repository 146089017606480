import { useCallback } from 'react';
import DSLink from '../../../design-system/DSLink';
import DSValueTypography from '../../../design-system/Typography/DSValueTypography';
import { downloadKlydoGuideZip } from '../../../utils/downloadKlydoGuide';

export const UploadLoopSpeck = () => {
  const handleOnMouseDown = useCallback((event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  }, []);
  return (
    <DSValueTypography>
      Required:
      <DSValueTypography>- Maximum file size 60 MB</DSValueTypography>
      <DSValueTypography>- MP4 or GIF format</DSValueTypography>
      <DSValueTypography>- Animation must be a seamless loop</DSValueTypography>
      <DSValueTypography mb={1}>- 1:1 Square ratio</DSValueTypography>
      For approval, please ensure your content is:
      <DSValueTypography>
        - Not offensive (no hate speech, explicit content, or extreme violence)
      </DSValueTypography>
      <DSValueTypography>
        - Your original work (you must own the rights)
      </DSValueTypography>
      <DSValueTypography mb={1}>
        - Properly formatted (meets upload specs)
      </DSValueTypography>
      <DSLink
        onMouseDown={handleOnMouseDown}
        onClick={() => {
          downloadKlydoGuideZip();
        }}
        sx={{
          cursor: 'pointer',
        }}
      >
        Click here to download the Klydo Design Guide Overlay
      </DSLink>
    </DSValueTypography>
  );
};

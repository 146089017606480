import { useTheme } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useState } from 'react';

const useTimePicker = (onClose: (time: Dayjs | null) => void) => {
  const { spacing } = useTheme();
  const [time, setTime] = useState<Dayjs | null>(dayjs());

  const handleTimeChange = (newValue: Dayjs | null) => {
    setTime(newValue);
  };

  const handleOkClick = () => {
    onClose(time);
  };

  return {
    time,
    handleTimeChange,
    handleOkClick,
    spacing,
  };
};

export default useTimePicker;

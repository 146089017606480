import { useQueryClient } from '@tanstack/react-query';
import klydoApiClient, { DraftKlydo } from '../../../api/klydo';
import useKlydoMutation, {
  UseKlydoMutationOptions,
} from '../../../hooks/useKlydoMutation';

const useRemoveKlydoMutation = (
  options: Omit<
    UseKlydoMutationOptions<
      void,
      string,
      { previousKlydos: Map<string, DraftKlydo[]> }
    >,
    'onSuccess' | 'onSettled'
  > & {
    onSuccess?: () => void;
    onSettled?: (data: void | undefined, error: unknown) => void;
    optimistic?: boolean;
  } = {},
) => {
  const queryClient = useQueryClient();

  return useKlydoMutation<
    void,
    string,
    { previousKlydos: Map<string, DraftKlydo[]> }
  >({
    mutationFn: (klydoId: string) => klydoApiClient.deleteKlydo(klydoId),
    mutationKey: ['klydosList'],

    onError: (error, klydoId, context) => {
      if (options.optimistic && context?.previousKlydos) {
        context.previousKlydos.forEach((previousKlydos, queryKey) => {
          queryClient.setQueryData([queryKey], previousKlydos);
        });
      }
      options.onError?.(error, klydoId, context);
    },

    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['klydosList'] });
      options.onSuccess?.();
    },

    onSettled: async (_, error) => {
      if (options.optimistic) {
        await queryClient.invalidateQueries({ queryKey: ['klydosList'] });
      }
      options.onSettled?.(_, error);
    },

    ...options,
  });
};

export default useRemoveKlydoMutation;

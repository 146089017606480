import React from 'react';
import DSBox from '../../../design-system/DSBox';
import DSTextField from '../../../design-system/DSTextField';
import { useTheme } from '@mui/material';
import { SOCIAL_NETWORKS } from '../hooks/socialNetwork';

type SocialEditableStackProps = {
  socialMedia: { network: string; link: string }[];
  setSocialMedia: (socialMedia: { network: string; link: string }[]) => void;
};

const SocialEditableStack: React.FC<SocialEditableStackProps> = ({
  socialMedia,
  setSocialMedia,
}) => {
  const { palette } = useTheme();

  const handleLinkChange = (networkName: string, link: string) => {
    const updatedSocialMedia = socialMedia.filter(
      (item) => item.network.toLowerCase() !== networkName.toLowerCase(),
    );

    const hasNonWhitespaceContent = link.trim();

    if (hasNonWhitespaceContent) {
      updatedSocialMedia.push({ network: networkName, link });
    }

    setSocialMedia(updatedSocialMedia);
  };

  return (
    <DSBox
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        gap: 2,
        rowGap: 3,
        justifyContent: 'space-between',
      }}
    >
      {SOCIAL_NETWORKS.map(({ name, icon: IconComponent }) => {
        const existingLink =
          socialMedia.find(
            (item) => item.network.toLowerCase() === name.toLowerCase(),
          )?.link || '';

        return (
          <DSBox
            key={name}
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              flex: '1 1 45%',
            }}
          >
            <IconComponent
              color={palette.text.secondary}
              width="20px"
              height="20px"
            />
            <DSTextField
              fullWidth
              value={existingLink}
              onChange={(e) => handleLinkChange(name, e.target.value)}
              placeholder={`Enter ${name} link`}
            />
          </DSBox>
        );
      })}
    </DSBox>
  );
};

export default SocialEditableStack;

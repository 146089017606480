import React from 'react';
import { Typography, useTheme } from '@mui/material';

const DSLabelTypography = (props: React.ComponentProps<typeof Typography>) => {
  const { spacing, palette } = useTheme();
  return (
    <Typography
      fontWeight={400}
      fontFamily={'Gilroy-Medium'}
      lineHeight={spacing(2.5)}
      fontSize={spacing(2)}
      color={palette.text.secondary}
      {...props}
    />
  );
};

export default DSLabelTypography;

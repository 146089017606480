export const HELP_ALERT =
  'To request assistance, please contact us at the following email address';
export const SUPPORT_EMAIL = 'studiosupport@klydoclock.com';
export const DELETE_KLYDO_ALERT = 'Are you sure you want to delete this Klydo?';
export const DELETE_KLYDO_SECCESSFULLY_ALERT = 'Klydo deleted successfully';
export const EDIT_PROFILE_ALERT =
  'Profile Saved. (Updates may take up to 24 hours)';
export const UPDATE_PROFILE_FAILED_ALERT = 'Profile update failed';
export const UPDATE_KLYDO_FAILED_ALERT = 'Klydo update failed';
export const UPLAOD_FILE_FAILED_ALERT = 'File upload failed';
export const INVAILED_LOOP_FILE_TYPE_ALERT =
  'Invalid file type. Only MP4 and GIF files are allowed.';
export const MAX_FILE_SIZE_ALERT =
  'File size exceeds the limit. Max size is 70MB.';
export const INCOMPLETE_PROFILE_ALERT = 'Please complete your profile';
export const INCOMPLETE_PROFILE_REASON_ALERT =
  'Profile is missing some required fields';
export const PUBLISH_ALERT_SUCCESS = 'Klydo published successfully';
export const PUBLIC_SWITCH_ALERT = `Are you sure you want to change this klydo from private to public? 
                                    This action cannot be undone.`;
export const SUBMIT_FOR_REVIEW_ALERT = `Are you ready to submit your klydo?`;

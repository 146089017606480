import React from 'react';
import { Paper, useTheme } from '@mui/material';

const DSTabGradientViewPaper = (props: React.ComponentProps<typeof Paper>) => {
  const { spacing } = useTheme();
  return (
    <Paper
      {...props}
      sx={{
        flexShrink: 0,
        background:
          'var(--Background-first-layer, linear-gradient(0deg, #111 38%, #212121 100%));',
        boxShadow:
          '0px 1px 3px 0px rgba(0, 0, 0, 0.12), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.20)',
        borderRadius: spacing(3.5),
        paddingX: { xxs: '2%', lg: '3.3%' },
        paddingBottom: '2%',
        ...props.sx,
      }}
    />
  );
};

export default DSTabGradientViewPaper;

import { useCallback, useState } from 'react';
import { Theme } from '../../../../types';

export type UseKlydoColorStackProps = {
  onChangeTheme: (theme: Theme) => void;
  themes: Theme[] | undefined;
};
const onChangeTheme = ({ onChangeTheme, themes }: UseKlydoColorStackProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [openColorPicker, setOpenColorPicker] = useState<boolean>(false);
  const [selectedThemeIndex, setSelectedThemeIndex] = useState<
    number | undefined
  >();
  const handleColorClick = useCallback(
    (index: number) => {
      if (themes === undefined) return;
      onChangeTheme(themes[index]);
      setSelectedIndex(index);
    },
    [onChangeTheme, themes],
  );

  return {
    handleColorClick,
    selectedIndex,
    openColorPicker,
    setOpenColorPicker,
    selectedThemeIndex,
    setSelectedThemeIndex,
    setSelectedIndex,
  };
};

export default onChangeTheme;

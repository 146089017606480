import { useCallback, useState } from 'react';
import { KlydoReviewStatus, KlydoType, KlydoUnion } from '../../../api/klydo';
import useUpsertDraftKlydoMutation from './useUpsertDraftKlydoMutation';
import usePublicKlydoMutation from './usePublishKlydoMutation';

type UseKlydoReadOnlyViewProps = {
  klydo: KlydoUnion;
  onClickEdit: () => void;
  isKlydoRejected: boolean;
  isKlydoPending: boolean;
};
const useKlydoReadOnlyView = ({
  klydo,
  onClickEdit,
  isKlydoRejected,
  isKlydoPending,
}: UseKlydoReadOnlyViewProps) => {
  const { mutate: upsertDraftKlydoMutation } = useUpsertDraftKlydoMutation();
  const { mutate: publicKlydoMutation } = usePublicKlydoMutation({
    onSuccess: () => {
      setPublishedDialogOpen(false);
    },
  });
  const [publishedDialogOpen, setPublishedDialogOpen] =
    useState<boolean>(false);

  const handleBackToEdit = useCallback(() => {
    if (isKlydoPending) {
      upsertDraftKlydoMutation({
        klydoId: klydo?.id,
        klydo: {
          reviewStatus: {
            state: KlydoReviewStatus.Draft,
            message: '',
          },
        },
      });
    }
    onClickEdit();
  }, [onClickEdit, klydo]);

  const onApprovedPublished = useCallback(() => {
    if (klydo.type === KlydoType.Approved) {
      publicKlydoMutation({
        klydoId: klydo.id,
      });
    }
  }, [klydo, publicKlydoMutation]);

  const onClosedPublishedDialog = useCallback(() => {
    setPublishedDialogOpen(false);
  }, []);

  const handlePublicSwitch = useCallback(() => {
    if (isKlydoRejected) {
      upsertDraftKlydoMutation({
        klydoId: klydo?.id,
        klydo: {
          public: !klydo.public,
        },
      });
    } else {
      setPublishedDialogOpen(true);
    }
  }, [
    klydo,
    upsertDraftKlydoMutation,
    setPublishedDialogOpen,
    isKlydoRejected,
  ]);

  return {
    handleBackToEdit,
    onApprovedPublished,
    publishedDialogOpen,
    onClosedPublishedDialog,
    handlePublicSwitch,
  };
};

export default useKlydoReadOnlyView;

import DSDialogContent from '../design-system/DSDialogContent';
import { DSSubtitleTypography } from '../design-system/Typography';
import DSDialogActions from '../design-system/DSDialogActions';
import DSSecondaryButton from '../design-system/DSSecondaryButton';
import DSPrimaryButton from '../design-system/DSPrimaryButtom';
import DSDialog from '../design-system/DSDialog';

type DeleteDialogProps = {
  deleteMessage: string;
  isDeleteDialogOpen: boolean;
  handleConfirmationClick: () => void;
  handleClose: () => void;
  isDeleting?: boolean;
};
const DeleteDialog = ({
  deleteMessage,
  isDeleteDialogOpen,
  handleConfirmationClick,
  handleClose,
  isDeleting,
}: DeleteDialogProps) => {
  return (
    <DSDialog maxWidth="md" open={isDeleteDialogOpen}>
      <DSDialogContent>
        <DSSubtitleTypography>{deleteMessage}</DSSubtitleTypography>
        <DSSubtitleTypography>
          {isDeleting && 'Deleting...'}
        </DSSubtitleTypography>
      </DSDialogContent>
      <DSDialogActions>
        <DSSecondaryButton disabled={isDeleting} onClick={() => handleClose()}>
          Cancel
        </DSSecondaryButton>
        <DSPrimaryButton
          disabled={isDeleting}
          onClick={() => handleConfirmationClick()}
        >
          Yes
        </DSPrimaryButton>
      </DSDialogActions>
    </DSDialog>
  );
};
export default DeleteDialog;

// useCountryDropdown.ts
import { useState, useMemo, useEffect } from 'react';
import { countries } from 'countries-list';
import { useTheme } from '@mui/material';

export interface CountryOption {
  code: string;
  name: string;
}

export interface CountryDropdownProps {
  initialCountryCode: string;
  onSelect: (country: string) => void;
}

const useCountryDropdown = ({
  initialCountryCode,
  onSelect,
}: CountryDropdownProps) => {
  const { spacing } = useTheme();
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState<CountryOption | null>(
    null,
  );

  const handleOptionSelect = (
    _event: unknown,
    newValue: CountryOption | null,
  ) => {
    setSelectedCountry(newValue);
    onSelect(newValue?.code || '');
  };

  const allCountries: CountryOption[] = useMemo(() => {
    return Object.entries(countries).map(([code, data]) => ({
      code,
      name: data.name,
    }));
  }, []);

  const filteredCountries = useMemo(() => {
    if (!searchValue) return allCountries;
    const searchLower = searchValue.toLowerCase();
    return allCountries.filter((country) =>
      country.name.toLowerCase().includes(searchLower),
    );
  }, [searchValue, allCountries]);

  const handleInputChange = (_event: unknown, newValue: string) => {
    setIsLoading(true);
    setSearchValue(newValue);
    setIsLoading(false);
  };

  useEffect(() => {
    if (initialCountryCode) {
      const found = filteredCountries.find(
        (opt) => opt.code === initialCountryCode,
      );
      setSelectedCountry(found || null);
    }
  }, [initialCountryCode, filteredCountries]);

  return {
    options: filteredCountries,
    handleInputChange,
    isLoading,
    spacing,
    selectedCountry,
    handleOptionSelect,
  };
};

export default useCountryDropdown;

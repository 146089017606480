import DSBox from '../../../design-system/DSBox';
import KlydoMap from '../../KlydoMap/components/KlydoMap';
import { useDashboard } from '../hooks/useDashboard';
import DSTabHeaderTypography from '../../../design-system/Typography/DSSTabHeaderTypography';
import DSHeaderTypography from '../../../design-system/Typography/DSHeaderTypography';
import KlydoCard from '../../Klydos/KlydosList/components/KlydoCard';
import DSGrid from '../../../design-system/DSGrid';
import DSGradientViewPaper from '../../../design-system/Paper/DSGradientViewPaper';
import { ReactComponent as KlydoIcon } from '../../../design-system/Icons/KlydoIcon.svg';
import { useTheme } from '@mui/material';
import { DSTypography } from '../../../design-system/Typography';
import DSCircularProgress from '../../../design-system/DSCircularProgress';

const Dashboard = () => {
  const { topKlydos, isTopKlydosLoading } = useDashboard();
  const { spacing, palette } = useTheme();

  const TopKlydoseEmptyStates = () => {
    if (isTopKlydosLoading) {
      return <DSCircularProgress sx={{ alignSelf: 'center' }} />;
    }
    if (!isTopKlydosLoading && !topKlydos?.length) {
      return (
        <DSBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '80%',
            alignItems: 'center',
            justifyContent: 'center',
            padding: { xxs: 0, xl: spacing(3) },
            gap: spacing(2),
          }}
        >
          <KlydoIcon />
          <DSTypography
            sx={{
              color: palette.action.disabled,
              fontFamily: 'Gilroy-Medium',
              fontSize: spacing(2),
              textAlign: 'center',
              width: '80%',
            }}
          >
            You don’t have any Approved Klydos Yet
          </DSTypography>
        </DSBox>
      );
    }
    return null;
  };

  return (
    <DSBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingX: { xxs: '2%', lg: '3.3%' },
        overflow: 'hidden',
        height: '90%',
      }}
    >
      <DSBox sx={{ paddingTop: spacing(4), paddingBottom: spacing(8) }}>
        <DSTabHeaderTypography>Dashboard</DSTabHeaderTypography>
      </DSBox>
      <DSBox
        sx={{
          flexGrow: 1,
          display: 'flex',
          gap: spacing(4),
          flexDirection: { xxs: 'column-reverse', sm: 'row' },
        }}
      >
        <DSGradientViewPaper
          sx={{
            flexDirection: 'column',
            height: { xxs: '60vh', sm: '70vh' },
            flexGrow: 1,
            paddingY: { xxs: 2, xs: spacing(4) },
            paddingX: { xxs: 2, xs: spacing(4) },
          }}
        >
          <DSHeaderTypography sx={{ marginBottom: spacing(4) }}>
            Your Klydo Map
          </DSHeaderTypography>
          <KlydoMap />
        </DSGradientViewPaper>
        <DSGradientViewPaper
          sx={{
            flexDirection: 'column',
            height: { xxs: '60vh', sm: '70vh' },
            padding: { xxs: spacing(1), xs: spacing(3) },
            minWidth: spacing(40),
          }}
        >
          <DSHeaderTypography sx={{ padding: spacing(1) }}>
            Top Klydos
          </DSHeaderTypography>
          <DSBox
            sx={{
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              height: '95%',
              overflow: 'auto',
            }}
          >
            <TopKlydoseEmptyStates />
            <DSGrid
              container
              spacing={spacing(2)}
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                paddingX: { xxs: 0, sm: spacing(1), xl: spacing(1) },
              }}
            >
              {topKlydos?.map((klydo) => (
                <DSBox key={klydo.id}>
                  <KlydoCard klydo={klydo} isStatic isMenuHidden />
                </DSBox>
              ))}
            </DSGrid>
          </DSBox>
        </DSGradientViewPaper>
      </DSBox>
    </DSBox>
  );
};

export default Dashboard;

import React, { useState } from 'react';
import { AppContext } from './AppContext';
import AuthInitializer from './AuthInitializer';
import UserInitializer from './UserInitializer';
import { User } from '../api/user';
import firebase from 'firebase/compat/app';
import { Alert } from '../design-system/DSAlert';

type Props = {
  children: React.ReactNode;
};

const AppContextProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [authUser, setAuthUser] = useState<firebase.User | null>(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [alert, setAlert] = useState<Alert | undefined>(undefined);
  const [hasAgreedToContract, setHasAgreedToContract] =
    useState<boolean>(false);

  const [impersonationActor, setImpersonationActor] = useState<
    string | undefined
  >();

  const handleSetImpersonationActor = (actor: string) => {
    setImpersonationActor(actor);
  };

  const getInfoFromToken = async (
    user: firebase.User | null,
    onValidateInfo: (impersonationActor: string, isAdmin: boolean) => void,
  ): Promise<void> => {
    try {
      if (!user) {
        onValidateInfo('', false);
        return;
      }

      const idTokenResult = await user.getIdTokenResult();
      const impersonationActor = (idTokenResult.claims?.actor as string) || '';
      const isAdmin = idTokenResult.claims?.role === 'admin';
      onValidateInfo(impersonationActor, isAdmin);
    } catch (error) {
      console.error('Error validating admin role:', error);
      throw error;
    }
  };

  const handleSetAuthUser = (user: firebase.User | null) => {
    setAuthUser(user);
    const handleValidation = (impersonationActor: string, isAdmin: boolean) => {
      setIsAdmin(isAdmin);
      handleSetImpersonationActor(impersonationActor);
    };

    getInfoFromToken(user, handleValidation);
  };

  const showAlert = (alert: Alert | undefined) => {
    setAlert(alert);
  };

  return (
    <AppContext.Provider
      value={{
        user: user!,
        setUser,
        authUser: authUser!,
        setAuthUser: handleSetAuthUser,
        isAdmin,
        impersonationActor: impersonationActor,
        alert,
        showAlert,
        hasAgreedToContract,
        setHasAgreedToContract,
      }}
    >
      <AuthInitializer setAuthUser={handleSetAuthUser} isAdmin={isAdmin}>
        {authUser && (
          <UserInitializer
            authUser={authUser}
            setUser={setUser}
            isAdmin={isAdmin}
            setHasAgreedToContract={setHasAgreedToContract}
          >
            {children}
          </UserInitializer>
        )}
      </AuthInitializer>
    </AppContext.Provider>
  );
};

export default AppContextProvider;

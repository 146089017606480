import { AlertType } from '../design-system/DSAlert';
import { KlydoError } from './types';
import useAppContext from './useAppContext';

const useHandleErrorMutation = () => {
  const { showAlert } = useAppContext();

  return (error: KlydoError, defaultErrorMessage: string) => {
    const errorMessage =
      error.response?.data?.message || error.message || defaultErrorMessage;
    showAlert({
      message: errorMessage,
      alertType: AlertType.ERROR,
    });
  };
};
export default useHandleErrorMutation;

import { useTheme } from '@mui/material';
import DSBox from '../../design-system/DSBox';
import {
  PENDULUM_BALL_DIAMETER,
  PENDULUM_DIAMETER,
  PENDULUM_ROD_HEIGHT,
  PENDULUM_ROD_WIDTH,
} from '../../consts/clockDimensions';

const Pendulum = ({
  backgroundColor,
  pendulumColor,
  rodColor,
  isStatic,
  scale = 1,
}: {
  backgroundColor: string;
  pendulumColor: string;
  rodColor: string;
  isStatic?: boolean;
  scale?: number;
}) => {
  const { zIndex } = useTheme();
  const pendulumDiameter = `${PENDULUM_DIAMETER * scale}px`;
  const ballDiameter = `${PENDULUM_BALL_DIAMETER * scale}px`;
  const pendulumRodWidth = `${PENDULUM_ROD_WIDTH * scale}px`;
  const pendulumRodHeight = `${PENDULUM_ROD_HEIGHT * scale}px`;

  return (
    <DSBox
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <DSBox
        sx={{
          position: 'absolute',
          top: '68%',
          borderRadius: '50%',
          backgroundColor: backgroundColor,
          overflow: 'hidden',
          width: pendulumDiameter,
          height: pendulumDiameter,
        }}
      >
        <DSBox
          sx={{
            position: 'absolute',
            marginTop: `${-400 * scale}px`,
            marginLeft: `${133 * scale}px`,
            transformOrigin: '50% 0px',
            animation: isStatic
              ? 'none'
              : 'pendulum-swing 2s ease-in-out infinite',
          }}
        >
          <DSBox
            sx={{
              backgroundColor: rodColor,
              width: pendulumRodWidth,
              height: pendulumRodHeight,
              position: 'absolute',
              transform: 'translateX(-50%)',
              zIndex: zIndex.pendulumRod,
              boxShadow: `
              0px 20px 30px rgba(0, 0, 0, 0.3),
              0px 0px 15px rgba(0, 0, 0, 0.15)
            `,
            }}
          />
          <DSBox
            sx={{
              backgroundColor: pendulumColor,
              width: ballDiameter,
              height: ballDiameter,
              position: 'absolute',
              top: pendulumRodHeight,
              transform: 'translate(-50%, -50%)',
              zIndex: zIndex.pendulumBall,
              borderRadius: '50%',
              boxShadow: `
              0px 20px 30px rgba(0, 0, 0, 0.3),
              0px 0px 15px rgba(0, 0, 0, 0.15)
            `,
            }}
          />
        </DSBox>
      </DSBox>
      <style>
        {`
          @keyframes pendulum-swing {
            0% {
              transform: rotate(-4deg); /* Swing left */
            }
            50% {
              transform: rotate(4deg); /* Swing right */
            }
            100% {
              transform: rotate(-4deg); /* Back to left */
            }
          }
        `}
      </style>
    </DSBox>
  );
};

export default Pendulum;

import useUpsertDraftKlydoMutation from '../../hooks/useUpsertDraftKlydoMutation';
import {
  DraftKlydo,
  KlydoReviewStatus,
  KlydoType,
  KlydoUnion,
} from '../../../../api/klydo';
import { useCallback, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PROFILE_MANDATORY_FIELDS } from '../../../../consts/mandatoryFields';
import useAppContext from '../../../../hooks/useAppContext';
import useDeviceType from '../../../../hooks/useDeviceType';
import { AlertType } from '../../../../design-system/DSAlert';

type UseKlydoCardProps = {
  klydo: KlydoUnion;
  handleDeleteKlydo?: (klydoId: string) => void;
  handleRemoveKlydoFromPack?: (klydoId: string) => void;
};
const useKlydoCard = ({
  klydo,
  handleDeleteKlydo,
  handleRemoveKlydoFromPack,
}: UseKlydoCardProps) => {
  const { user, showAlert, isAdmin } = useAppContext();
  const navigate = useNavigate();
  const [warnningDialogOpen, setWarningDialogOpen] = useState(false);
  const [submitDialogOpen, setSubmitDialogOpen] = useState<boolean>(false);

  const { mutate: upsertDraftKlydoMutation } = useUpsertDraftKlydoMutation({
    onError: (error) => {
      showAlert({
        message: error.response?.data?.message || error.message,
        alertType: AlertType.ERROR,
      });
    },
  });

  const [
    profileIncompleteErrorDialogOpen,
    setProfileIncompleteErrorDialogOpen,
  ] = useState<boolean>(false);

  const isProfileMissingFields = PROFILE_MANDATORY_FIELDS.some(
    (field) => !user?.[field],
  );

  const handleCloseErrorDialog = useCallback(() => {
    setProfileIncompleteErrorDialogOpen(false);
  }, [setProfileIncompleteErrorDialogOpen]);

  const handleSubmitKlydo = useCallback(() => {
    if (isProfileMissingFields && !isAdmin) {
      setProfileIncompleteErrorDialogOpen(true);
      return;
    }
    setSubmitDialogOpen(true);
  }, [isProfileMissingFields]);

  const onApprovedSubmit = useCallback(() => {
    upsertDraftKlydoMutation(
      {
        klydoId: klydo.id,
        klydo: {
          reviewStatus: {
            state: KlydoReviewStatus.Pending,
            message: '',
          },
        },
      },
      {
        onSuccess: () => setSubmitDialogOpen(false),
      },
    );
  }, [JSON.stringify(klydo)]);

  const handleCancelReview = (klydo: DraftKlydo) => {
    upsertDraftKlydoMutation({
      klydoId: klydo.id,
      klydo: {
        reviewStatus: {
          state: KlydoReviewStatus.Draft,
          message: '',
        },
      },
    });
  };

  const menuButtonItemsList = useMemo(() => {
    const menuItems = [];

    if (handleRemoveKlydoFromPack) {
      menuItems.push({
        title: 'Remove',
        isDisabled: false,
        onClick: () => handleRemoveKlydoFromPack(klydo.id),
      });
    }
    if (handleDeleteKlydo) {
      menuItems.push({
        title: 'Delete',
        isDisabled: klydo.type === KlydoType.Approved,
        onClick: () => handleDeleteKlydo(klydo.id),
      });
    }
    menuItems.push(
      klydo.type === KlydoType.Draft &&
        klydo.reviewStatus?.state === KlydoReviewStatus.Pending
        ? {
            title: 'Cancel Review',
            isDisabled: false,
            onClick: () => handleCancelReview(klydo as DraftKlydo),
          }
        : {
            title: 'Submit',
            isDisabled:
              klydo.type === KlydoType.Approved ||
              klydo.reviewStatus?.state === KlydoReviewStatus.Pending,
            onClick: () => handleSubmitKlydo(),
          },
      {
        title: 'Edit',
        isDisabled: klydo.type !== KlydoType.Draft,
        onClick: () => navigate(`/klydo/${klydo.id}`),
      },
    );
    return menuItems;
  }, [klydo, handleSubmitKlydo, handleRemoveKlydoFromPack, handleDeleteKlydo]);

  const onEditProfile = () => {
    navigate('/edit-profile');
  };

  const onClosedSubmitDialog = useCallback(() => {
    setSubmitDialogOpen(false);
  }, []);

  const { isMobile } = useDeviceType();
  const isEditAvailable = !isMobile;

  const handleKlydoCardClick = useCallback(() => {
    if (isEditAvailable) {
      navigate(`/klydo/${klydo.id}`);
    }
  }, [isEditAvailable, klydo.id, navigate]);

  return {
    menuButtonItemsList,
    setWarningDialogOpen,
    warnningDialogOpen,
    profileIncompleteErrorDialogOpen,
    handleCloseErrorDialog,
    onEditProfile,
    onClosedSubmitDialog,
    submitDialogOpen,
    onApprovedSubmit,
    isEditAvailable,
    handleKlydoCardClick,
  };
};

export default useKlydoCard;

import { useCallback, useEffect, useState } from 'react';
import userApiClient, { SearchUserOutput } from '../../../api/user';
import firebaseService from '../../../firebase/firebaseService';
import useUsersQuery from './useUsersQuery';
import { useTheme } from '@mui/material';
import useAppContext from '../../../hooks/useAppContext';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

const useUserDropdown = () => {
  const { spacing } = useTheme();
  const queryClient = useQueryClient();
  const { setAuthUser } = useAppContext();
  const { user } = useAppContext();
  const [searchText, setSearchText] = useState('');
  const { data: users, isLoading } = useUsersQuery(searchText);
  const [dropDownValue, setDropDownValue] = useState<SearchUserOutput | null>(
    null,
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (user) {
      setDropDownValue({ id: user.id, name: user.name });
    }
  }, [user]);

  const handleInputChange = useCallback(
    (event: React.SyntheticEvent, value: string) => {
      setSearchText(value);
    },
    [],
  );

  const handleOptionSelect = useCallback(
    async (event: React.SyntheticEvent, value: SearchUserOutput | null) => {
      if (value) {
        const token = await userApiClient.impersonate(value.id);
        const authUser = await firebaseService.impersonate(token);
        setAuthUser(authUser.user!);
        setSearchText('');
        queryClient.invalidateQueries();
        navigate('/');
      }
    },
    [setAuthUser],
  );

  const options = users?.pages.flatMap((page) => page) || [];

  return {
    options,
    handleInputChange,
    handleOptionSelect,
    isLoading,
    spacing,
    searchText,
    dropDownValue,
  };
};

export default useUserDropdown;

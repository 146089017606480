import DSAvatar from '../../../design-system/DSAvatar';
import DSBox from '../../../design-system/DSBox';
import Flag from 'react-world-flags';
import useProfile from '../hooks/useProfile';
import DSTabHeaderTypography from '../../../design-system/Typography/DSSTabHeaderTypography';
import DSSecondaryButton from '../../../design-system/DSSecondaryButton';
import ProfileItemMenuButton from './ProfileItemMenuButton';
import DSLabelTypography from '../../../design-system/Typography/DSLabelTypography';
import DSValueTypography from '../../../design-system/Typography/DSValueTypography';
import DSGradientViewPaper from '../../../design-system/Paper/DSGradientViewPaper';
import SocialChipStack from './SocialChipStack';

const Profile = () => {
  const {
    handleLogout,
    handleEditProfile,
    spacing,
    name,
    email,
    photoUrl,
    description,
    country,
    countryName,
    socialMedia,
  } = useProfile();

  return (
    <DSBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingX: spacing(3),
        paddingBottom: spacing(4),
      }}
    >
      <DSBox
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
        }}
      >
        <ProfileItemMenuButton
          onLogout={handleLogout}
          onEdit={handleEditProfile}
        />
      </DSBox>
      <DSBox sx={{ display: 'flex', flexDirection: 'column', gap: spacing(3) }}>
        <DSBox
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
            gap: spacing(1),
          }}
        >
          <DSAvatar
            src={photoUrl}
            sx={{
              width: {
                xxs: spacing(15),
                xs: spacing(20),
                sm: spacing(26),
                md: spacing(26),
                lg: spacing(26),
                xl: spacing(34),
              },
              height: {
                xxs: spacing(15),
                xs: spacing(20),
                sm: spacing(26),
                md: spacing(26),
                lg: spacing(26),
                xl: spacing(34),
              },
            }}
          />
          <DSTabHeaderTypography>{name}</DSTabHeaderTypography>
          <DSValueTypography>{email}</DSValueTypography>
          <DSSecondaryButton
            onClick={() => handleEditProfile()}
            color="secondary"
            variant="contained"
            sx={{
              fontSize: spacing(2),
              borderRadius: 'var(--Corner-Full, 1000px)',
              px: spacing(4),
              py: spacing(1),
              width: spacing(30),
              boxShadow:
                '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
            }}
          >
            Edit Profile
          </DSSecondaryButton>
        </DSBox>
        <DSBox
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexShrink: 0,
          }}
        >
          <DSGradientViewPaper
            sx={{
              flexDirection: 'column',
              width: { xxs: '90vw', xs: '80vw', lg: '50vw' },
              height: 'auto',
              paddingY: { xxs: spacing(2), md: spacing(4) },
              paddingX: { xxs: spacing(2), md: spacing(4) },
            }}
          >
            <DSBox>
              <DSLabelTypography>Description: </DSLabelTypography>
              <DSValueTypography>{description}</DSValueTypography>
            </DSBox>
            <DSBox
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '100%',
                flexDirection: { sm: 'column', md: 'row' },
                alignSelf: 'stretch',
                alignItems: 'flex-start',
              }}
            >
              <DSBox
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: spacing(2),
                  width: '100%',
                }}
              >
                <DSAvatar
                  sx={{
                    width: spacing(5.5),
                    height: spacing(5.5),
                  }}
                >
                  <Flag
                    code={country}
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                      borderRadius: '50%',
                    }}
                    alt={`${country} Flag`}
                  />
                </DSAvatar>

                <DSBox sx={{ display: 'flex', flexDirection: 'column' }}>
                  <DSLabelTypography>Country: </DSLabelTypography>
                  <DSValueTypography>{countryName}</DSValueTypography>
                </DSBox>
              </DSBox>
              <DSBox
                sx={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  gap: spacing(2),
                  overflow: 'hidden',
                }}
              >
                <DSLabelTypography>Social Media:</DSLabelTypography>
                <SocialChipStack socialMedia={socialMedia} />
              </DSBox>
            </DSBox>
          </DSGradientViewPaper>
        </DSBox>
      </DSBox>
    </DSBox>
  );
};

export default Profile;

import { useKlydosTopFive } from './useKlydosTopFive';

export const useDashboard = () => {
  const { data: topKlydos, isLoading: isTopKlydosLoading } = useKlydosTopFive();

  return {
    topKlydos,
    isTopKlydosLoading,
  };
};

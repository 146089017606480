import { useMemo } from 'react';
import { useKlydoViewers } from './useKlydosViewer';
import { useTheme } from '@mui/material';
import useLocale from '../../../hooks/useLoacle';
import { KlydosViewersResult } from '../../../api/studio';
import { kmeans } from 'ml-kmeans';

export const useKlydoMap = () => {
  const windowHours = 24;
  const { data: locale } = useLocale();
  const { spacing } = useTheme();
  const { data: viewers, isError } = useKlydoViewers(windowHours);

  const devices: KlydosViewersResult[] = useMemo(
    () => viewers ?? [],
    [viewers],
  );

  const findDenseLocation = useMemo(() => {
    if (!devices || devices.length === 0) {
      return null;
    }

    const points = devices
      .map((device) => [Number(device.latitude), Number(device.longitude)])
      .filter(([lat, lng]) => !isNaN(lat) && !isNaN(lng));

    if (points.length < 3) {
      return null;
    }

    const k = 3;
    const result = kmeans(points, k, { initialization: 'kmeans++' });

    if (!result || !result.clusters) {
      return null;
    }

    const clusterCounts: number[] = new Array(k).fill(0);
    result.clusters.forEach((c: number) => clusterCounts[c]++);

    const maxClusterIndex = clusterCounts.indexOf(Math.max(...clusterCounts));

    const densestCluster = points.filter(
      (_, i) => result.clusters[i] === maxClusterIndex,
    );

    const avgLat =
      densestCluster.reduce((sum, [lat]) => sum + lat, 0) /
      densestCluster.length;
    const avgLng =
      densestCluster.reduce((sum, [, lng]) => sum + lng, 0) /
      densestCluster.length;

    return { lat: avgLat, lng: avgLng, count: densestCluster.length };
  }, [devices]);

  const center = findDenseLocation || {
    lat: locale?.ll?.[0] ?? 40.7128,
    lng: locale?.ll?.[1] ?? -74.006,
  };

  const zoom = findDenseLocation ? 4 : 8;

  return {
    devices,
    center,
    zoom,
    isError,
    spacing,
  };
};

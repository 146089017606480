import { Theme } from '../types';
import { baseClient } from './baseRequest';

export enum KlydoType {
  Draft = 'DraftKlydo',
  Approved = 'ApprovedKlydo',
}

export enum KlydoReviewStatus {
  Pending = 'pending',
  Rejected = 'rejected',
  Draft = 'draft',
}

export type Klydo = {
  name: string;
  loopUrl: string;
  convertedLoopUrl?: string;
  theme: Theme;
  id: string;
  creator: string;
  dials: boolean;
  klydoSecureUrl?: string;
  createdAt: Date;
  updatedAt: Date;
  authorName: string;
  metadata?: {
    fileName: string;
    klydoDurationInSeconds?: number;
  };
  public: boolean;
};

export type ApprovedKlydo = Klydo & {
  type: KlydoType.Approved;
  stats: {
    favorites: number;
    viewed: number;
    time: number;
  };
};

export type DraftKlydo = Klydo & {
  type: KlydoType.Draft;
  reviewStatus: {
    state: KlydoReviewStatus;
    message?: string;
  };
};

export type KlydoUnion = DraftKlydo | ApprovedKlydo;

export type KlydoApiClient = {
  processImage: (loopUrl: string) => Promise<Theme[]>;
  checkContrast: (filePath: string, handColor: string) => Promise<string>;
  search: (query: string, options: KlydoSearchOptions) => Promise<KlydoUnion[]>;
  approvedKlydosTextSearch: (
    query: string,
    options: KlydoSearchOptions,
  ) => Promise<ApprovedKlydo[]>;
  deleteKlydo: (klydoId: string) => Promise<void>;
  upsertDraftKlydo: (
    klydoId: string,
    klydo: Partial<DraftKlydo>,
  ) => Promise<void>;
  publishKlydo: (klydoId: string) => Promise<void>;
  getKlydo: (id: string) => Promise<KlydoUnion>;
};

export type KlydoSearchFilter = { excludeIds: string[] };

export type KlydoSearchOptions = {
  limit: number;
  skip: number;
  sort: { [key: string]: number };
};

const klydoApiClient: KlydoApiClient = {
  processImage: async (loopUrl: string) => {
    return baseClient.post({
      url: `/klydos/process-image`,
      body: {
        loopUrl: loopUrl,
      },
    });
  },

  checkContrast: async (filePath: string, handColor: string) => {
    return baseClient.post<string>({
      url: `/klydos/check-contrast`,
      body: {
        filePath: filePath,
        handColor: handColor,
      },
    });
  },
  search: async (query: string, options: KlydoSearchOptions) => {
    return baseClient.post({
      url: `/studio/klydos/.search/text`,
      body: { query, options },
    });
  },
  approvedKlydosTextSearch: async (
    query: string,
    options: KlydoSearchOptions,
  ) => {
    return baseClient.post({
      url: `/studio/klydos/.search/approved-klydos-text-search`,
      body: { query, options },
    });
  },
  deleteKlydo: async (klydoId: string) => {
    return baseClient.delete({ url: `/studio/klydos/${klydoId}` });
  },
  upsertDraftKlydo: async (klydoId: string, klydo: Partial<DraftKlydo>) => {
    return baseClient.put({
      url: `/studio/klydos/${klydoId}`,
      body: klydo,
    });
  },
  publishKlydo: async (klydoId: string) => {
    return baseClient.put({ url: `/studio/klydos/${klydoId}/publish` });
  },
  getKlydo(id: string) {
    return baseClient.get<KlydoUnion>({
      url: `/studio/klydos/${id}`,
    });
  },
};

export default klydoApiClient;

import { useMediaQuery, useTheme } from '@mui/material';

const useDeviceType = () => {
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down('xs'));
  const isSmallTablet = useMediaQuery(breakpoints.down('sm'));
  const isTablet = useMediaQuery(breakpoints.down('md'));
  const isSmallLaptop = useMediaQuery(breakpoints.down('lg'));
  const isBigScreen = useMediaQuery(breakpoints.up('lg'));

  return {
    isMobile,
    isSmallTablet,
    isTablet,
    isSmallLaptop,
    isBigScreen,
  };
};

export default useDeviceType;

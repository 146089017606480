import DSBox from '../../design-system/DSBox';
import useKlydoPreview from './hooks/useKlydoPreview';
import KlydoLoopUrl from './KlydoLoopUrl';
import Pendulum from './Pendulum';
import ClockView from './ClockView';
import { Klydo } from '../../api/klydo';
import MenuButton, { MenuItemsProps } from '../MenuButton/MenuButton';
import { useTheme } from '@mui/material';
import {
  CLOCK_DIAMETER,
  KLYDO_HEIGHT_WITHOUT_FRAME,
  KLYDO_WIDTH_WITHOUT_FRAME,
} from '../../consts/clockDimensions';

type KlydoPreviewProps = {
  klydo: Partial<Klydo>;
  dialsHidden?: boolean;
  backgroundHidden?: boolean;
  minutesValue?: number;
  hoursValue?: number;
  isAnimationStatic?: boolean;
  isPendulumStatic?: boolean;
  menuButtonItemsList?: MenuItemsProps[];
  scale?: number;
};

const KlydoPreview = ({
  klydo,
  minutesValue,
  hoursValue,
  isAnimationStatic,
  isPendulumStatic,
  menuButtonItemsList,
  dialsHidden,
  backgroundHidden,
  scale = 1,
}: KlydoPreviewProps): JSX.Element => {
  const {
    ref,
    backgroundColor,
    loopUrl,
    handsColor,
    pendulumColor,
    pendulumRodColor,
    handHour,
    handMin,
    handHourS,
    handMinS,
    dialsColor,
  } = useKlydoPreview({
    klydo,
    minutesValue,
    hoursValue,
    isAnimationStatic,
  });
  const { zIndex } = useTheme();

  const width = `${KLYDO_WIDTH_WITHOUT_FRAME * scale}px`;
  const height = `${KLYDO_HEIGHT_WITHOUT_FRAME * scale}px`;
  const clockDiameter = `${CLOCK_DIAMETER * scale}px`;

  const baseMenuTop = 48;
  const baseMenuRight = 48;
  const menuTop = `${baseMenuTop * scale}px`;
  const menuRight = `${baseMenuRight * scale}px`;

  return (
    <DSBox
      sx={{
        width,
        height,
        aspectRatio: '18 / 30',
        margin: '0 auto',
        borderRadius: '34.827px',
        position: 'relative',
        backgroundColor: backgroundHidden ? 'transparent' : '#2D2D2D',
        padding: '40.971px var(--6, 48px)',
      }}
    >
      {menuButtonItemsList && (
        <DSBox
          sx={{
            position: 'absolute',
            top: menuTop,
            right: menuRight,
            zIndex: zIndex.modal,
          }}
        >
          <MenuButton menuItems={menuButtonItemsList} />
        </DSBox>
      )}
      <div ref={ref}>
        <DSBox
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            display: 'flex',
            width: '100%',
            height: '50%',
            marginTop: '50%',
          }}
        >
          <DSBox
            sx={{
              backgroundColor: backgroundColor,
              borderRadius: '50%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'center',
              display: 'flex',
              width: clockDiameter,
              height: clockDiameter,
              position: 'absolute',
              padding: 0,
            }}
          >
            {loopUrl && <KlydoLoopUrl loopUrl={loopUrl} />}
            <ClockView
              handHour={handHour}
              handHourS={handHourS}
              handMin={handMin}
              handMinS={handMinS}
              handsColor={handsColor}
              dialsHidden={dialsHidden}
              dialsColor={dialsColor}
              scale={scale}
            />
          </DSBox>
          <Pendulum
            backgroundColor={backgroundColor}
            pendulumColor={pendulumColor}
            rodColor={pendulumRodColor}
            isStatic={isPendulumStatic}
            scale={scale}
          />
        </DSBox>
      </div>
    </DSBox>
  );
};
export default KlydoPreview;

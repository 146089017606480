import { useEffect, useCallback, useState, useRef } from 'react';
import { DraftKlydo, KlydoReviewStatus } from '../../../api/klydo';
import useAutoSave from '../../hooks/useAutosave';
import useUpsertDraftKlydoMutation from './useUpsertDraftKlydoMutation';
import { useTheme } from '@mui/material';
import { Theme } from '../../../types';
import { ColorValidator } from '../../../utils/contrastCheck';
import { AlertType } from '../../../design-system/DSAlert';
import {
  INVAILED_LOOP_FILE_TYPE_ALERT,
  MAX_FILE_SIZE_ALERT,
} from '../../../consts/alerts';
import useAppContext from '../../../hooks/useAppContext';
import useUploadKlydoLoopMutation from '../../hooks/useUploadKlydoLoopMutation';
import { getMediaType } from '../../../utils';
import { PROFILE_MANDATORY_FIELDS } from '../../../consts/mandatoryFields';
import { useNavigate } from 'react-router-dom';

const colorValidator = new ColorValidator();

const MAX_FILE_SIZE_BYTES = 70000000;

type KlydoEditableViewProps = {
  klydo?: DraftKlydo;
  setSuggestedColors: (theme: Theme[] | undefined) => void;
  onLocalUrlChange: (url: string) => void;
  onSecuredUrlChange: (url: string) => void;
  klydoSecureUrl: string;
  onKlydoThemeChange: (theme: Theme | undefined) => void;
  selectedTheme: Theme | undefined;
  klydoId: string | undefined;
  onSubmitKlydo: () => void;
};

const useKlydoEditableView = ({
  klydo,
  setSuggestedColors,
  onLocalUrlChange,
  onSecuredUrlChange,
  klydoSecureUrl,
  onKlydoThemeChange,
  selectedTheme,
  klydoId,
  onSubmitKlydo,
}: KlydoEditableViewProps) => {
  const { showAlert, isAdmin } = useAppContext();
  const { mutate: upsertDraftKlydoMutation } = useUpsertDraftKlydoMutation();
  const [submitDialogOpen, setSubmitDialogOpen] = useState<boolean>(false);
  const [klydoTitle, setKlydoTitle] = useState(klydo?.name || '');
  const [isLoadingUrl, setIsLoadingUrl] = useState(false);
  const [isLoadingThemes, setIsLoadingThemes] = useState(false);
  const [fileName, setFileName] = useState<string>(
    klydo?.metadata?.fileName || '',
  );
  const abortControllerRef = useRef<AbortController | null>(null);
  const [lastUpdate, setLastUpdate] = useState<Date | undefined>(
    klydo?.updatedAt,
  );
  const [klydoDurationInSeconds, setKlydoDurationInSeconds] = useState<
    number | undefined
  >(klydo?.metadata?.klydoDurationInSeconds);
  const [shouldAutoSaveTheme, setShouldAutoSaveTheme] = useState(true);
  const { spacing } = useTheme();
  const { mutate: uploadKlydoLoopMutation } = useUploadKlydoLoopMutation({
    onError: () => {
      onLocalUrlChange('');
      setIsLoadingUrl(false);
    },
    onSuccess: (response) => {
      abortControllerRef.current = new AbortController();
      onSecuredUrlChange(response.url);
      setKlydoDurationInSeconds(response.duration);
      setIsLoadingUrl(false);
    },
  });

  const { user } = useAppContext();
  const navigate = useNavigate();

  const [
    profileIncompleteErrorDialogOpen,
    setProfileIncompleteErrorDialogOpen,
  ] = useState<boolean>(false);

  const isProfileMissingFields = PROFILE_MANDATORY_FIELDS.some(
    (field) => !user?.[field],
  );

  const handleCloseErrorDialog = useCallback(() => {
    setProfileIncompleteErrorDialogOpen(false);
  }, [setProfileIncompleteErrorDialogOpen]);

  const onEditProfile = () => {
    navigate('/edit-profile');
  };

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  useEffect(() => {
    colorValidator.initializeVibrantColors(klydoSecureUrl);
  }, [klydoSecureUrl]);

  const handleFileUpload = async (fileUrl: string) => {
    setIsLoadingUrl(true);
    uploadKlydoLoopMutation({
      file: fileUrl,
      fileType: getMediaType(fileUrl),
    });
  };

  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) return;
      const validTypes = ['video/mp4', 'image/gif'];
      if (!validTypes.includes(file.type)) {
        showAlert({
          message: INVAILED_LOOP_FILE_TYPE_ALERT,
          alertType: AlertType.ERROR,
        });
        return;
      }
      if (file.size > MAX_FILE_SIZE_BYTES) {
        showAlert({
          message: MAX_FILE_SIZE_ALERT,
          alertType: AlertType.ERROR,
        });
        return;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setFileName(file.name);
        onLocalUrlChange(reader.result as string);
        handleFileUpload(reader.result as string);
      };
      reader.readAsDataURL(file);
    },
    [],
  );

  const handleRemoveFile = useCallback(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    onLocalUrlChange('');
    onSecuredUrlChange('');
    setSuggestedColors([]);
    onKlydoThemeChange(undefined);
  }, []);

  const handleSubmitToReview = useCallback(() => {
    if (!klydoId) return;
    if (isProfileMissingFields && !isAdmin) {
      setProfileIncompleteErrorDialogOpen(true);
      return;
    }
    setSubmitDialogOpen(true);
  }, [klydoId, isProfileMissingFields]);

  const handleAutoSave = useCallback(() => {
    if (klydoId && (klydoTitle || klydoSecureUrl)) {
      let updatedKlydo: Partial<DraftKlydo> = {
        name: klydoTitle,
        loopUrl: klydoSecureUrl,
        reviewStatus: klydo?.reviewStatus || {
          state: KlydoReviewStatus.Draft,
        },
        metadata: {
          fileName: fileName,
          klydoDurationInSeconds: klydoDurationInSeconds,
        },
      };
      if (shouldAutoSaveTheme) {
        updatedKlydo = { ...updatedKlydo, theme: selectedTheme };
      }
      upsertDraftKlydoMutation({
        klydoId: klydoId,
        klydo: updatedKlydo,
      });
      setLastUpdate(new Date());
    }
  }, [
    klydoId,
    klydoTitle,
    selectedTheme,
    fileName,
    klydoDurationInSeconds,
    klydo,
    klydoSecureUrl,
    shouldAutoSaveTheme,
  ]);

  const isThemeValid =
    !!selectedTheme?.handsColor &&
    !!selectedTheme?.backgroundColor &&
    !!selectedTheme?.dialsColor &&
    !!selectedTheme?.pendulumColor &&
    !!selectedTheme?.pendulumRodColor;

  useAutoSave({
    onSave: handleAutoSave,
    dependencies: [
      klydoTitle,
      klydoSecureUrl,
      selectedTheme,
      klydoId,
      fileName,
      klydoDurationInSeconds,
    ],
  });

  const isCreateButtonDisabled =
    !klydoTitle || !klydoSecureUrl || !selectedTheme || !isThemeValid;
  const handleMissingFields = () => {
    const missingFields = [];

    if (!klydoTitle) {
      missingFields.push('Klydo Title');
    }
    if (!klydoSecureUrl) {
      missingFields.push('Klydo File');
    }
    if (!selectedTheme) {
      missingFields.push('Klydo Theme');
    }

    return missingFields.length
      ? `Missing fields: ${missingFields.join(', ')}`
      : '';
  };

  const onChangeColor = useCallback(
    (key: string, color: string) => {
      const shouldSkipContrastCheck = key !== 'handsColor';
      const isChangeApplicable = colorValidator.validateColor({
        color,
        themeKey: key,
        loopUrl: klydoSecureUrl,
        selectedTheme,
        shouldSkipContrastCheck,
      });
      onKlydoThemeChange({
        ...selectedTheme,
        [key]: color,
      } as Theme);
      return isChangeApplicable || false;
    },
    [selectedTheme, klydoSecureUrl, selectedTheme],
  );

  const handlePublicSwitch = useCallback(() => {
    if (!klydoId) return;
    upsertDraftKlydoMutation({
      klydoId: klydoId,
      klydo: {
        public: !klydo?.public,
      },
    });
  }, [klydoId, klydo]);

  const onApprovedSubmit = useCallback(() => {
    if (!klydoId) return;
    upsertDraftKlydoMutation(
      {
        klydoId: klydo?.id || klydoId,
        klydo: {
          reviewStatus: {
            state: KlydoReviewStatus.Pending,
            message: '',
          },
        },
      },
      {
        onSuccess: () => onSubmitKlydo(),
      },
    );
  }, [klydoId, klydo]);

  const onClosedSubmitDialog = useCallback(() => {
    setSubmitDialogOpen(false);
  }, []);

  return {
    updateKlydoMutation: upsertDraftKlydoMutation,
    handleFileChange,
    handleRemoveFile,
    handleSubmitToReview,
    klydoTitle,
    setKlydoTitle,
    selectedTheme,
    isLoadingUrl,
    spacing,
    lastUpdate,
    isCreateButtonDisabled,
    setSuggestedColors,
    isLoadingThemes,
    fileName,
    handleMissingFields,
    onChangeColor,
    profileIncompleteErrorDialogOpen,
    handleCloseErrorDialog,
    onEditProfile,
    setShouldAutoSaveTheme,
    handlePublicSwitch,
    onApprovedSubmit,
    onClosedSubmitDialog,
    submitDialogOpen,
  };
};
export default useKlydoEditableView;

import React from 'react';
import {
  KlydoReviewStatus,
  KlydoType,
  KlydoUnion,
} from '../../../../api/klydo';
import DSBox from '../../../../design-system/DSBox';
import { DSTypography } from '../../../../design-system/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { DRAFT, PENDING, APPROVED, REJECTED } from '../../../../consts/colors';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import QueryBuilderOutlinedIcon from '@mui/icons-material/QueryBuilderOutlined';
import { ReactComponent as DraftIcon } from '../../../../design-system/Icons/DraftIcon.svg';
import { useTheme } from '@mui/material';

type KlydoStatusProps = {
  klydo: KlydoUnion | undefined;
};

const getStatusColor = (state: KlydoType | KlydoReviewStatus) => {
  switch (state) {
    case KlydoType.Approved:
      return APPROVED;
    case KlydoReviewStatus.Pending:
      return PENDING;
    case KlydoReviewStatus.Rejected:
      return REJECTED;
    case KlydoReviewStatus.Draft:
      return DRAFT;
    default:
      return DRAFT;
  }
};

const statusConfig: Record<
  KlydoType | KlydoReviewStatus,
  { icon: React.ReactElement; color: string }
> = {
  [KlydoType.Approved]: {
    icon: <CheckCircleOutlineIcon />,
    color: getStatusColor(KlydoType.Approved),
  },
  [KlydoType.Draft]: {
    icon: <DraftIcon />,
    color: getStatusColor(KlydoReviewStatus.Draft),
  },
  [KlydoReviewStatus.Pending]: {
    icon: <QueryBuilderOutlinedIcon />,
    color: getStatusColor(KlydoReviewStatus.Pending),
  },
  [KlydoReviewStatus.Rejected]: {
    icon: <HighlightOffIcon />,
    color: getStatusColor(KlydoReviewStatus.Rejected),
  },
  [KlydoReviewStatus.Draft]: {
    icon: <DraftIcon />,
    color: getStatusColor(KlydoReviewStatus.Draft),
  },
};

const getStatusIcon = (state: KlydoType | KlydoReviewStatus) => {
  const { spacing } = useTheme();
  const config = statusConfig[state] || statusConfig[KlydoReviewStatus.Draft];

  return React.cloneElement(config.icon, {
    sx: { color: config.color, fontSize: spacing(2.5) },
  });
};

const getStatusText = (state: KlydoType | KlydoReviewStatus) => {
  if (state === KlydoType.Approved) {
    return 'Approved';
  }

  return state?.charAt(0).toUpperCase() + state?.slice(1);
};

const KlydoStatus: React.FC<KlydoStatusProps> = ({ klydo }) => {
  const { spacing } = useTheme();
  const getKlydoState = (
    klydo: KlydoUnion | undefined,
  ): KlydoType | KlydoReviewStatus => {
    if (!klydo) {
      return KlydoReviewStatus.Draft;
    }
    if (klydo.type && klydo.type !== KlydoType.Draft) {
      return klydo.type;
    }
    return klydo.reviewStatus?.state || KlydoReviewStatus.Draft;
  };
  const state = getKlydoState(klydo);

  return (
    <DSBox sx={{ gap: spacing(1), display: 'flex' }}>
      {getStatusIcon(state)}
      <DSTypography
        fontFamily={'Gilroy-SemiBold'}
        fontWeight={400}
        fontSize={spacing(2)}
        lineHeight={spacing(3)}
        color={getStatusColor(state)}
      >
        {getStatusText(state)}
      </DSTypography>
    </DSBox>
  );
};

export default KlydoStatus;

import React, { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Dayjs } from 'dayjs';
import DSDialog from '../../../design-system/DSDialog';
import useTimePicker from '../hooks/useTimePicker';
import DSTimeClock from '../../../design-system/DSTimeClock';
import DSDialogTitle from '../../../design-system/DSDialogTitle';
import { DSTypography } from '../../../design-system/Typography';
import DSDialogContent from '../../../design-system/DSDialogContent';
import DSDialogActions from '../../../design-system/DSDialogActions';
import DSPrimaryButton from '../../../design-system/DSPrimaryButtom';
import DSSecondaryButton from '../../../design-system/DSSecondaryButton';
import DSBox from '../../../design-system/DSBox';
import DSIconButton from '../../../design-system/DSIconButton';
import { ChevronRight, ChevronLeft } from '@mui/icons-material';

interface TimePickerProps {
  isOpen: boolean;
  onClose: (time: Dayjs | null) => void;
}

const TimePicker: React.FC<TimePickerProps> = ({ isOpen, onClose }) => {
  const { time, handleTimeChange, handleOkClick, spacing } =
    useTimePicker(onClose);

  const [view, setView] = useState<'hours' | 'minutes'>('hours');

  const toggleView = (direction: 'back' | 'forward') => {
    setView((prevView) =>
      direction === 'back'
        ? prevView === 'hours'
          ? 'minutes'
          : 'hours'
        : prevView === 'minutes'
          ? 'hours'
          : 'minutes',
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DSDialog open={isOpen} onClose={() => onClose(null)}>
        <DSDialogTitle>
          <DSTypography fontFamily={'Gilroy-Medium'} fontSize={spacing(2)}>
            SELECT TIME
          </DSTypography>
        </DSDialogTitle>
        <DSDialogContent>
          <DSBox display="flex" justifyContent="flex-end" mb={spacing(2)}>
            <DSIconButton onClick={() => toggleView('back')}>
              <ChevronLeft />
            </DSIconButton>
            <DSIconButton onClick={() => toggleView('forward')}>
              <ChevronRight />
            </DSIconButton>
          </DSBox>
          <DSTimeClock value={time} onChange={handleTimeChange} view={view} />
        </DSDialogContent>
        <DSDialogActions>
          <DSSecondaryButton onClick={() => onClose(null)}>
            Cancel
          </DSSecondaryButton>
          <DSPrimaryButton onClick={handleOkClick}>OK</DSPrimaryButton>
        </DSDialogActions>
      </DSDialog>
    </LocalizationProvider>
  );
};

export default TimePicker;

import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import { KlydoError } from './types';
import useHandleErrorMutation from './useHandleErrorMutation';

export type UseKlydoMutationOptions<
  TData = unknown,
  TVariables = void,
  TContext = unknown,
> = UseMutationOptions<TData, KlydoError, TVariables, TContext>;

export type UseKlydoMutationResult<
  TData = unknown,
  TError = KlydoError,
  TVariables = void,
  TContext = unknown,
> = UseMutationResult<TData, TError, TVariables, TContext>;

const useKlydoMutation = <
  TData = unknown,
  TVariables = void,
  TContext = unknown,
>(
  options: UseKlydoMutationOptions<TData, TVariables, TContext>,
): UseKlydoMutationResult<TData, KlydoError, TVariables, TContext> => {
  const handleError = useHandleErrorMutation();

  const enhancedOptions = {
    ...options,
    onError: (
      error: KlydoError,
      variables: TVariables,
      context: TContext | undefined,
    ) => {
      handleError(error, 'An unxpected error occurred');
      if (options.onError) {
        options.onError(error, variables, context);
      }
    },
  };

  return useMutation(enhancedOptions);
};

export default useKlydoMutation;

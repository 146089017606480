import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { KLYDO_BLACK, KLYDO_YELLOW } from './consts/colors';

import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';
import {
  LARGE_SCREEN,
  MEDUIM_SCREEN,
  MOBILE,
  SMALL_SCREEN,
  SMALL_TABLET,
  TABLET,
} from './consts/breakpoints';
const environment =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development'
    ? 'development'
    : 'production';
if (environment === 'production') {
  Sentry.init({
    dsn: 'https://a139595d2870ccdd00202cd2c378e6cf@o4508608097681408.ingest.us.sentry.io/4508653687865344',
    environment: environment,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.captureConsoleIntegration(),
      Sentry.replayIntegration(),
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation: useLocation,
        useNavigationType: useNavigationType,
        createRoutesFromChildren: createRoutesFromChildren,
        matchRoutes: matchRoutes,
      }),
    ],

    tracesSampleRate: environment === 'production' ? 0.01 : 0,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

declare module '@mui/material/styles' {
  interface ZIndex {
    pendulumRod: number;
    pendulumBall: number;
    clockDials: number;
    handMinute: number;
    handMinuteS: number;
    handHour: number;
    handHourS: number;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xxs: true;
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

const darkTheme = createTheme({
  breakpoints: {
    values: {
      xxs: 0,
      xs: MOBILE,
      sm: SMALL_TABLET,
      md: TABLET,
      lg: SMALL_SCREEN,
      xl: MEDUIM_SCREEN,
      xxl: LARGE_SCREEN,
    },
  },
  typography: {
    fontFamily: [
      'Gilroy-bold, Gilroy-SemiBold, Gilroy-Medium, Gilroy-Regular, Gilroy-Light',
    ].join(','),
    button: {
      textTransform: 'capitalize',
      fontFamily: 'Gilroy-SemiBold',
      fontWeight: 500,
    },
    h3: {
      fontFamily: 'Gilroy-Bold',
      lineHeight: '56px',
      fontSize: '48px',
      fontWeight: 400,
    },
    h5: {
      fontFamily: 'Gilroy-Medium',
      fontSize: '24px',
      lineHeight: '32px',
      fontWeight: 400,
    },
    h6: {
      fontFamily: 'Gilroy-SemiBold',
      fontSize: '19px',
      lineHeight: '24px',
    },
    subtitle1: {
      fontFamily: 'Gilroy-Medium',
      fontSize: '16px',
      lineHeight: '28px',
      fontWeight: 400,
      color: 'rgba(255, 255, 255, 0.7)',
    },
  },
  palette: {
    mode: 'dark',
    action: {
      disabled: 'rgba(255, 255, 255, 0.12)',
      active: 'rgba(255, 255, 255, 0.56)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
    },
    primary: {
      main: KLYDO_YELLOW,
      contrastText: KLYDO_BLACK,
    },
    secondary: {
      main: '#2D2D2D',
      contrastText: 'rgba(255, 255, 255, 1)',
    },
    background: {
      // black
      default: KLYDO_BLACK,
      paper: KLYDO_BLACK,
    },
  },
  zIndex: {
    pendulumRod: 1,
    pendulumBall: 2,
    clockDials: 0,
    handMinute: 3,
    handMinuteS: 2,
    handHour: 3,
    handHourS: 2,
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

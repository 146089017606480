import { useTheme } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { KLYDO_WHITE } from '../../../../consts/colors';
import DSBox from '../../../../design-system/DSBox';
import { Theme, ThemeStackStrings } from '../../../../types';
import DSTooltip from '../../../../design-system/DSTooltip';
import DSIconButton from '../../../../design-system/DSIconButton';

export type KlydoColorStackItemProps = Partial<Theme> & {
  isSelected?: boolean;
  theme: Theme;
  onClickEdit?: () => void;
};

const KlydoColorStackItem: React.FC<KlydoColorStackItemProps> = ({
  isSelected,
  theme,
  onClickEdit,
}) => {
  const ColorCircle = ({
    color,
    themeKey,
  }: {
    color?: string;
    themeKey: keyof Theme;
  }) => {
    const { spacing } = useTheme();
    return (
      <DSTooltip title={ThemeStackStrings[themeKey]}>
        <DSBox
          sx={{
            width: {
              sm: spacing(3),
              md: spacing(4),
              lg: spacing(5),
              xl: spacing(6),
            },
            height: {
              sm: spacing(3),
              md: spacing(4),
              lg: spacing(5),
              xl: spacing(6),
            },
            borderRadius: '50%',
            backgroundColor: color || 'rgba(255, 255, 255, 0.23)',
          }}
        />
      </DSTooltip>
    );
  };
  return (
    <DSBox
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center', // Centers the color circles
        alignSelf: 'stretch',
        gap: 2,
        paddingX: 6,
        paddingY: 1.5,
        borderRadius: 'var(--3, 24px)',
        borderColor: isSelected ? KLYDO_WHITE : 'rgba(255, 255, 255, 0.23)',
        borderWidth: 3,
        borderStyle: 'solid',
        width: '100%',
        position: 'relative',
      }}
    >
      <DSBox sx={{ display: 'flex', gap: 2 }}>
        {Object.keys(theme).map((key) => (
          <ColorCircle
            color={theme[key as keyof Theme]}
            themeKey={key as keyof typeof ThemeStackStrings}
            key={key}
          />
        ))}
      </DSBox>

      <DSIconButton
        sx={{
          position: 'absolute',
          right: 10,
        }}
        onClick={onClickEdit}
      >
        <EditIcon />
      </DSIconButton>
    </DSBox>
  );
};

export default KlydoColorStackItem;

export const KLYDO_WIDTH_WITHOUT_FRAME = 643; // ~17cm
export const KLYDO_HEIGHT_WITHOUT_FRAME = 1058; // ~28cm
export const CLOCK_DIAMETER = 530; // ~14cm
export const PENDULUM_DIAMETER = 265; // ~7cm
export const PENDULUM_BALL_DIAMETER = 208; // ~5.5cm
export const PENDULUM_ROD_WIDTH = 19; // ~0.5cm
export const PENDULUM_ROD_HEIGHT = 530; // ~14cm
export const HOUR_HAND_WIDTH = 24.6; // ~0.65cm
export const HOUR_HAND_HEIGHT = 189; // ~5cm
export const MINUTE_HAND_WIDTH = 18.9; // ~0.5cm
export const MINUTE_HAND_HEIGHT = 245.6; // ~6.5cm
export const DIALS_DIAMETER = 19; // ~0.55cm

import { Vibrant } from 'node-vibrant/browser';
import { Palette } from '@vibrant/color';
import { Theme } from '../types';

const KLYDO_CONTRAST_THRESHOLD = 1.1;
const KLYDO_DISTANCE_THRESHOLD_LIGHT = 0.05;
const KLYDO_DISTANCE_THRESHOLD = 0.1;
const KLYDO_DISTANCE_THRESHOLD_DARK = 0.3;
const PENDULUM_CONTRAST_THRESHOLD = 1.4;
const NORMALIZATION_PARAMETER = 255;
const RED_CONTRAST_COEFFICIENT = 0.2126;
const GREEN_CONTRAST_COEFFICIENT = 0.7152;
const BLUE_CONTRAST_COEFFICIENT = 0.0722;
const INDICATOR_FOR_URL_EDIT_INDEX = 'upload/';
const MAGNITUTUE_DARK_THRESHOLD = 85;
const MAGNITUTUE_LIGHT_THRESHOLD = 280;
const RELATIVE_POPULATION_THRESHOLD = 0.25;

export class ColorValidator {
  vibrantColors: Palette[] | null = null;

  private parseUrl = (loopUrl: string, insertedString: string) => {
    const indexOfInsert =
      loopUrl.indexOf(INDICATOR_FOR_URL_EDIT_INDEX) +
      INDICATOR_FOR_URL_EDIT_INDEX.length;
    const suffix = loopUrl.split('.')[loopUrl.split('.').length - 1];
    let updatedUrl =
      loopUrl.slice(0, indexOfInsert) +
      insertedString +
      loopUrl.slice(indexOfInsert);
    updatedUrl =
      updatedUrl.substring(0, updatedUrl.length - suffix.length) + 'jpg';
    return updatedUrl;
  };

  private gammaCorrection = (value: number): number => {
    const normalizedValue = value / NORMALIZATION_PARAMETER;
    if (normalizedValue <= 0.04045) {
      return normalizedValue / 12.92;
    } else {
      return Math.pow((normalizedValue + 0.055) / 1.055, 2.4);
    }
  };

  private hexToRgb = (hex: string): { r: number; g: number; b: number } => {
    return {
      r: parseInt(hex.substring(0, 2), 16),
      g: parseInt(hex.substring(2, 4), 16),
      b: parseInt(hex.substring(4, 6), 16),
    };
  };

  private getTotalPopulation = (vibrant: Palette) => {
    let totalPopulation = 0;
    Object.keys(vibrant).forEach((key) => {
      const population = vibrant[key]?.population;
      if (population) {
        totalPopulation += population;
      }
    });
    return totalPopulation;
  };

  private checkContrast = (
    color1: {
      r: number;
      g: number;
      b: number;
    },
    color2: {
      r: number;
      g: number;
      b: number;
    },
  ): number => {
    const luminance1 =
      RED_CONTRAST_COEFFICIENT * this.gammaCorrection(color1.r) +
      GREEN_CONTRAST_COEFFICIENT * this.gammaCorrection(color1.g) +
      BLUE_CONTRAST_COEFFICIENT * this.gammaCorrection(color1.b);
    const luminance2 =
      RED_CONTRAST_COEFFICIENT * this.gammaCorrection(color2.r) +
      GREEN_CONTRAST_COEFFICIENT * this.gammaCorrection(color2.g) +
      BLUE_CONTRAST_COEFFICIENT * this.gammaCorrection(color2.b);
    return (
      (Math.max(luminance1, luminance2) + 0.05) /
      (Math.min(luminance1, luminance2) + 0.05)
    );
  };

  private checkEuclidianDistance = (
    color1: { r: number; g: number; b: number },
    color2: { r: number; g: number; b: number },
  ): number => {
    const r = (color1.r - color2.r) / NORMALIZATION_PARAMETER;
    const g = (color1.g - color2.g) / NORMALIZATION_PARAMETER;
    const b = (color1.b - color2.b) / NORMALIZATION_PARAMETER;
    return Math.sqrt(r * r + g * g + b * b);
  };

  private getDistanceThreshold = (magnitude1: number, magnitude2: number) => {
    if (
      magnitude1 < MAGNITUTUE_DARK_THRESHOLD &&
      magnitude2 < MAGNITUTUE_DARK_THRESHOLD
    ) {
      return KLYDO_DISTANCE_THRESHOLD_DARK;
    } else if (
      magnitude1 > MAGNITUTUE_LIGHT_THRESHOLD &&
      magnitude2 > MAGNITUTUE_LIGHT_THRESHOLD
    ) {
      return KLYDO_DISTANCE_THRESHOLD_LIGHT;
    }
    return KLYDO_DISTANCE_THRESHOLD;
  };

  async initializeVibrantColors(loopUrl: string) {
    if (!loopUrl) {
      return;
    }
    const imageDirections = [
      'g_center',
      'g_north_west',
      'g_north_east',
      'g_south_west',
      'g_south_east',
      'g_north',
      'g_south',
      'g_east',
      'g_west',
    ];

    const vibrantColors = await Promise.all(
      imageDirections.map(async (direction) => {
        try {
          const insertedString = `c_crop,${direction},fl_relative,w_0.333,h_0.333/`;
          let updatedUrl = this.parseUrl(loopUrl, insertedString);
          const response = await Vibrant.from(updatedUrl).getPalette();
          return response;
        } catch (error) {
          console.error(error);
        }
      }),
    );
    this.vibrantColors = vibrantColors.filter(
      (color): color is Palette => color !== null,
    );
  }
  private checkCenter = (color1: { r: number; g: number; b: number }) => {
    try {
      return this.checkEdges(color1);
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  private checkEdges = (color: { r: number; g: number; b: number }) => {
    let vibrantColors = this.vibrantColors;
    let isContrastAcceptable = true;

    if (!vibrantColors) {
      return false;
    }

    vibrantColors.some((vibrant) => {
      if (vibrant) {
        const totalPopulation = this.getTotalPopulation(vibrant);
        Object.keys(vibrant).forEach((key) => {
          const population = vibrant[key]?.population;
          if (
            vibrant[key] &&
            population &&
            population / totalPopulation > RELATIVE_POPULATION_THRESHOLD
          ) {
            const r = vibrant[key]?.rgb[0] || 0;
            const g = vibrant[key]?.rgb[1] || 0;
            const b = vibrant[key]?.rgb[2] || 0;

            const distance = this.checkEuclidianDistance(color, {
              r,
              g,
              b,
            });
            const contrast = this.checkContrast(color, {
              r,
              g,
              b,
            });
            const magnitude1 = Math.sqrt(r * r + g * g + b * b);
            const magnitude2 = Math.sqrt(
              color.r * color.r + color.g * color.g + color.b * color.b,
            );

            const distanceThreshold = this.getDistanceThreshold(
              magnitude1,
              magnitude2,
            );

            magnitude1 < MAGNITUTUE_DARK_THRESHOLD &&
            magnitude2 < MAGNITUTUE_DARK_THRESHOLD
              ? KLYDO_DISTANCE_THRESHOLD_DARK
              : KLYDO_DISTANCE_THRESHOLD;
            if (distance < distanceThreshold) {
              isContrastAcceptable = false;
            }
          }
        });
      }
    });

    return isContrastAcceptable;
  };

  public validateColor = ({
    color,
    themeKey,
    loopUrl,
    selectedTheme,
    shouldSkipContrastCheck,
  }: {
    color: string;
    themeKey: string;
    loopUrl?: string;
    selectedTheme?: Theme;
    shouldSkipContrastCheck?: boolean;
  }) => {
    if (shouldSkipContrastCheck) return true;
    if (themeKey === 'dialsColor') {
      if (loopUrl) {
        return this.checkEdges(this.hexToRgb(color.replace('#', '')));
      }
      return true;
    } else if (themeKey === 'handsColor') {
      if (loopUrl) {
        return this.checkCenter(this.hexToRgb(color.replace('#', '')));
      }
    } else if (
      themeKey === 'pendulumRodColor' ||
      themeKey === 'pendulumColor'
    ) {
      if (selectedTheme?.backgroundColor) {
        return (
          this.checkContrast(
            this.hexToRgb(selectedTheme.backgroundColor.replace('#', '')),
            this.hexToRgb(color.replace('#', '')),
          ) > PENDULUM_CONTRAST_THRESHOLD
        );
      }
      return true;
    } else if (themeKey === 'backgroundColor') {
      const rodConstrast = selectedTheme?.pendulumRodColor
        ? this.checkContrast(
            this.hexToRgb(selectedTheme?.pendulumRodColor.replace('#', '')),
            this.hexToRgb(color.replace('#', '')),
          ) > PENDULUM_CONTRAST_THRESHOLD
        : true;

      const pendulumConstrast = selectedTheme?.pendulumColor
        ? this.checkContrast(
            this.hexToRgb(color.replace('#', '')),
            this.hexToRgb(selectedTheme.pendulumColor.replace('#', '')),
          ) > PENDULUM_CONTRAST_THRESHOLD
        : true;
      return rodConstrast && pendulumConstrast;
    }
    return true;
  };
}

import { Theme } from '../types';

//TODO: think about the secter key
export const GOOGLE_MAPS_API_KEY = 'AIzaSyC5XG3LtScVeWzLYbiYE-JXY4GLFRnGduQ';
export const DRAWER_WIDTH = 34;
export const APPBAR_HIGHT = 10;
export const DEFAULT_KLYDO_THEME: Theme = {
  handsColor: 'rgba(168, 168, 168, 1)',
  backgroundColor: 'rgba(73, 72, 72, 1)',
  pendulumColor: 'rgba(168, 168, 168, 1)',
  pendulumRodColor: 'rgba(168, 168, 168, 1)',
  dialsColor: 'rgba(217, 217, 217, 1)',
};
export const MAX_KLYDOS = 12;

export const authErrorCodes = new Set([
  'auth/email-already-exists',
  'auth/email-already-in-use',
  'auth/invalid-email',
  'auth/invalid-password',
  'auth/wrong-password',
  'auth/weak-password',
  'auth/user-disabled',
  'auth/user-not-found',
  'auth/too-many-requests',
  'auth/invalid-credential',
]);

import useClockView from './hooks/useClockView';

type ClockViewProps = {
  handHour: React.RefObject<HTMLDivElement>;
  handMin: React.RefObject<HTMLDivElement>;
  handHourS: React.RefObject<HTMLDivElement>;
  handMinS: React.RefObject<HTMLDivElement>;
  handsColor: string;
  dialsColor: string;
  dialsHidden?: boolean;
  scale?: number;
};
const ClockView = ({
  handHour,
  handMin,
  handHourS,
  handMinS,
  handsColor,
  dialsHidden,
  dialsColor,
  scale = 1,
}: ClockViewProps) => {
  const { zIndex } = useClockView();

  const CLOCK_CENTER = 48;
  const DIAL_RADIUS = 46;
  const DIAL_COUNT = 12;
  const ANGLE_INCREMENT = 30;

  const dials = Array.from({ length: DIAL_COUNT }, (_, index) => {
    const angle = (index * ANGLE_INCREMENT * Math.PI) / 180;
    const x = CLOCK_CENTER + DIAL_RADIUS * Math.cos(angle);
    const y = CLOCK_CENTER - DIAL_RADIUS * Math.sin(angle);
    return { x, y };
  });

  const hourHandWidth = `${25 * scale}px`;
  const hourHandHeight = `${189 * scale}px`;
  const minuteHandWidth = `${19 * scale}px`;
  const minuteHandHeight = `${246 * scale}px`;
  const dialsDiameter = `${21 * scale}px`;

  return (
    <time
      dateTime="2023-06-30T04:48:07.668Z"
      style={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        borderRadius: '50%',
      }}
    >
      {!dialsHidden &&
        dials.map(({ x, y }, index) => (
          <div
            key={index}
            style={{
              top: `${y}%`,
              left: `${x}%`,
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              zIndex: zIndex.clockDials,
              color: dialsColor,
              borderRadius: '50%',
              width: dialsDiameter,
              height: dialsDiameter,
              backgroundColor: dialsColor,
              boxShadow: `
              0px 4px 6px rgba(0, 0, 0, 0.3), /* Main shadow */
              0px 1px 2px rgba(0, 0, 0, 0.2) /* Subtle inner glow */
            `,
            }}
          ></div>
        ))}
      <div // hour shadow
        ref={handHourS}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: '0.4%',
          top: '1.2%',
          transformOrigin: 'center',
          zIndex: zIndex.handHourS,
        }}
      >
        <div
          style={{
            position: 'absolute',
            borderRadius: '13px',
            width: hourHandWidth,
            height: hourHandHeight,
            bottom: '45%',
            left: '48%',
            background: 'rgba(0, 0, 0, 0.19)',
          }}
        />
      </div>
      <div // hour
        ref={handHour}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          transformOrigin: 'center',
          zIndex: zIndex.handHour,
        }}
      >
        <div
          style={{
            position: 'absolute',
            borderRadius: '13px',
            width: hourHandWidth,
            height: hourHandHeight,
            bottom: '45%',
            left: '48.1%',
            backgroundColor: handsColor,
            boxShadow: `
            0px 1px 2px rgba(0, 0, 0, 0.2) /* Subtle inner glow */
          `,
          }}
        />
      </div>
      <div // minute shadow
        ref={handMinS}
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: '1.2%',
          top: '1.4%',
          transformOrigin: 'center',
          zIndex: zIndex.handMinuteS,
        }}
      >
        <div
          style={{
            position: 'absolute',
            borderRadius: '7px',
            width: minuteHandWidth,
            height: minuteHandHeight,
            bottom: '43%',
            left: '48.75%',
            background: 'rgba(0, 0, 0, 0.19)',
          }}
        />
      </div>
      <div // minute
        ref={handMin}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          zIndex: zIndex.handMinute,
          transformOrigin: 'center',
        }}
      >
        <div
          style={{
            position: 'absolute',
            borderRadius: '7px',
            width: minuteHandWidth,
            height: minuteHandHeight,
            bottom: '43%',
            left: '48.75%',
            backgroundColor: handsColor,
            boxShadow: `
            0px 1px 2px rgba(0, 0, 0, 0.2) /* Subtle inner glow */
          `,
          }}
        />
      </div>
    </time>
  );
};

export default ClockView;

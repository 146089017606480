import React, { forwardRef } from 'react';
import { IconButton, IconButtonProps } from '@mui/material';
interface DSIconButtonProps extends IconButtonProps {
  component?: React.ElementType;
}
const DSIconButton = forwardRef<HTMLButtonElement, DSIconButtonProps>(
  (props, ref) => {
    return <IconButton ref={ref} {...props} />;
  },
);
export default DSIconButton;

import React, { forwardRef } from 'react';
import { Tooltip, useTheme } from '@mui/material';

const DSTooltip = forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof Tooltip>
>((props, ref) => {
  const { spacing } = useTheme();
  return (
    <Tooltip
      ref={ref}
      {...props}
      arrow
      componentsProps={{
        tooltip: {
          sx: {
            fontSize: spacing(2),
            padding: spacing(2),
            borderRadius: spacing(3),
            whiteSpace: 'pre-wrap',
            maxWidth: 'none',
            ...props.componentsProps?.tooltip?.sx,
          },
        },
      }}
    />
  );
});

export default DSTooltip;

import React from 'react';
import { Dialog, useTheme } from '@mui/material';

const DSDialog = (props: React.ComponentProps<typeof Dialog>) => {
  const { spacing } = useTheme();
  return (
    <Dialog
      {...props}
      sx={{
        '.MuiDialog-paper': {
          borderRadius: spacing(3),
          overflow: 'hidden',
        },
        ...props.sx,
      }}
    />
  );
};

export default DSDialog;

import { memo } from 'react';
import { GoogleMap, OverlayView, useJsApiLoader } from '@react-google-maps/api';
import DSBox from '../../../design-system/DSBox';
import DSCircularProgress from '../../../design-system/DSCircularProgress';
import { GOOGLE_MAPS_API_KEY } from '../../../consts/general';
import { useKlydoMap } from '../hooks/useKlydoMap';
import KlydoFrame from './KlydoFrame';

const KlydoMap = () => {
  const { devices, center, zoom, spacing } = useKlydoMap();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  });

  if (!isLoaded) {
    return (
      <DSBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <DSCircularProgress />
      </DSBox>
    );
  }

  return (
    <GoogleMap
      center={center}
      zoom={zoom}
      mapContainerStyle={{
        width: '100%',
        height: '85%',
        borderRadius: spacing(8),
      }}
      options={{
        disableDefaultUI: true,
      }}
    >
      {devices?.map((device) => (
        <OverlayView
          key={device.clockId}
          position={{ lat: device.latitude, lng: device.longitude }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        >
          <DSBox
            sx={{
              width: 30,
              height: 30,
              borderRadius: '50%',
              overflow: 'hidden',
              border: '2px solid white',
              cursor: 'pointer',
              boxShadow: '0 2px 6px rgba(0,0,0,0.3)',
              position: 'relative',
            }}
          >
            {device.loopUrl && <KlydoFrame klydoLoopUrl={device.loopUrl} />}
          </DSBox>
        </OverlayView>
      ))}
    </GoogleMap>
  );
};

export default memo(KlydoMap);

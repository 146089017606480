import { useState, useRef, useCallback } from 'react';
import useAppContext from '../hooks/useAppContext';
import useUserMutation from '../views/SignUp/hooks/useUserMutation';
import { AlertType } from '../design-system/DSAlert';
import DSModal from '../design-system/DSModal';
import DSBox from '../design-system/DSBox';
import { DSTitleTypography } from '../design-system/Typography';
import DSCheckbox from '../design-system/DSCheckBox';
import DSFormControlLabel from '../design-system/DSFormControlLabel';
import DSButton from '../design-system/DSButtom';
import Contract from './Contract';
import { useTheme } from '@mui/material';

const ContractAgreementModal = () => {
  const { setUser, user, setHasAgreedToContract, showAlert } = useAppContext();
  const { spacing } = useTheme();
  const [open, setOpen] = useState(true);
  const [agreed, setAgreed] = useState(false);
  const [scrolledToBottom, setScrolledToBottom] = useState(false);
  const scrollRef = useRef(null);

  const { mutate: updateUserMutation } = useUserMutation(user?.id, {
    optimistic: false,
    onSuccess: (updatedData) => {
      setUser((prevUser) => ({
        ...prevUser,
        ...updatedData,
      }));
      setHasAgreedToContract?.(true);
      setOpen(false);
    },
    onError: (error) => {
      const errorMessage = error.response?.data?.message || error.message;
      showAlert({
        message: errorMessage,
        alertType: AlertType.ERROR,
      });
    },
  });

  const handleScroll = () => {
    if (scrollRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        setScrolledToBottom(true);
      }
    }
  };

  const handleAgree = useCallback(() => {
    if (agreed && user) {
      updateUserMutation({
        contractAccepted: true,
      });
    }
  }, [agreed, user, updateUserMutation]);

  const handleAgreeStateChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setAgreed(e.target.checked);
    },
    [],
  );

  return (
    <DSModal open={open}>
      <DSBox
        sx={{
          position: 'absolute',
          width: '90%',
          height: '90%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          p: spacing(2),
          display: 'flex',
          flexDirection: 'column',
          gap: spacing(2),
        }}
      >
        <DSTitleTypography gutterBottom>
          ARTIST COLLABORATION TERMS AND CONDITIONS
        </DSTitleTypography>

        <DSBox
          ref={scrollRef}
          onScroll={handleScroll}
          sx={{
            height: '100%',
            overflowY: 'auto',
            border: '1px solid #ccc',
            padding: spacing(2),
            borderRadius: spacing(1),
          }}
        >
          <Contract />
        </DSBox>

        <DSFormControlLabel
          control={
            <DSCheckbox
              checked={agreed}
              onChange={handleAgreeStateChange}
              disabled={!scrolledToBottom}
            />
          }
          label="I agree to the contract terms"
        />

        <DSButton
          onClick={handleAgree}
          disabled={!agreed}
          variant="contained"
          fullWidth
        >
          Agree & Continue
        </DSButton>
      </DSBox>
    </DSModal>
  );
};

export default ContractAgreementModal;

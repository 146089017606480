import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import studioApiClient from '../api/studio';

export const downloadKlydoGuideZip = async () => {
  const imageUrl = await studioApiClient.getKlydoGuideImageUrl();
  const zipFileName = 'Klydo_Design_Guide.zip';
  const imageFileName = 'Klydo_Design_Guide.png';

  try {
    const response = await fetch(imageUrl);
    const blob = await response.blob();

    const zip = new JSZip();
    zip.file(imageFileName, blob);

    const zipBlob = await zip.generateAsync({ type: 'blob' });

    saveAs(zipBlob, zipFileName);
  } catch (error) {
    console.error('Error creating ZIP file:', error);
  }
};

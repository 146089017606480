import { forwardRef } from 'react';
import { useTheme } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import DSBox from './DSBox';
import DSValueTypography from './Typography/DSValueTypography';

export enum AlertType {
  SUCCESS = 'success',
  ERROR = 'error',
  WARNING = 'warning',
  INFO = 'info',
}

export type Alert = {
  alertType: AlertType;
  message: string;
};

const iconMap: Record<AlertType, JSX.Element> = {
  [AlertType.SUCCESS]: <CheckCircleOutlineOutlinedIcon color="success" />,
  [AlertType.ERROR]: <ErrorOutlineOutlinedIcon color="error" />,
  [AlertType.WARNING]: <WarningAmberOutlinedIcon color="warning" />,
  [AlertType.INFO]: <InfoOutlinedIcon color="info" />,
};

const DSAlert = forwardRef<HTMLDivElement, Alert>((props, ref) => {
  const { spacing } = useTheme();
  const { alertType, message } = props;

  const icon = iconMap[alertType];

  return (
    <DSBox
      ref={ref}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: spacing(1),
        width: '100%',
      }}
    >
      {icon}
      <DSValueTypography>{message}</DSValueTypography>
    </DSBox>
  );
});

DSAlert.displayName = 'Alert';

export default DSAlert;

import { useCallback, useEffect, useMemo, useState } from 'react';
import { Theme } from '../../../types';
import { useParams } from 'react-router-dom';
import { KlydoReviewStatus, KlydoType, KlydoUnion } from '../../../api/klydo';
import { useTheme } from '@mui/material';
import { Dayjs } from 'dayjs';
import useBackNavigation from '../../../hooks/useBackNavigation';
import useGetKlydoQuery from './useGetKlydoQuery';

const useCreateKlydo = () => {
  const { klydoId } = useParams();
  const { data: klydo, isLoading } = useGetKlydoQuery(klydoId!);

  const { spacing, palette } = useTheme();
  const [klydoUrl, setKlydoUrl] = useState<string | undefined>(klydo?.loopUrl);

  const [klydoSecureUrl, setKlydoSecureUrl] = useState<string | undefined>(
    klydo?.loopUrl,
  );
  const [suggestedColors, setSuggestedColors] = useState<Theme[] | undefined>(
    klydo?.theme ? [klydo?.theme] : undefined,
  );

  const [minutesValue, setMinutesValue] = useState(0);
  const [hoursValue, setHoursValue] = useState(0);
  const [isDialsHidden, setIsDialsHidden] = useState(false);
  const [isTimpePickerOpen, setIsTimePickerOpen] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState<Theme | undefined>(
    klydo?.theme,
  );
  const [isEditingState, setIsEditingState] = useState(false);

  const handleHideDialsClick = useCallback(() => {
    setIsDialsHidden((prev) => !prev);
  }, []);

  const { goBack } = useBackNavigation();

  const setKlydoState = useCallback(
    (klydo: KlydoUnion) => {
      setKlydoSecureUrl(klydo.loopUrl);
      setSelectedTheme(klydo.theme);
      if (!suggestedColors && klydo.theme) setSuggestedColors([klydo.theme]);
    },
    [klydo],
  );

  useEffect(() => {
    if (klydo) {
      setKlydoState(klydo);
    }
  }, [klydo]);

  const isEditableView = useMemo(() => {
    if (isEditingState) return true;
    if (klydo?.type === KlydoType.Approved) {
      return false;
    }

    if (klydo?.reviewStatus?.state === KlydoReviewStatus.Pending) {
      return false;
    }
    if (
      klydo?.reviewStatus?.state === KlydoReviewStatus.Rejected &&
      !isEditingState
    ) {
      return false;
    }
    return true;
  }, [klydo, isEditingState]);

  const menuButtonItemsList = useMemo(() => {
    return [
      {
        title: 'Hide Dials',
        isDisabled: false,
        onClick: () => handleHideDialsClick(),
      },
      {
        title: 'Change Time View',
        isDisabled: false,
        onClick: () => setIsTimePickerOpen(true),
      },
    ];
  }, [handleHideDialsClick]);

  const handleClosedTimePicker = useCallback((time: Dayjs | null) => {
    setMinutesValue(time?.minute() || 0);
    setHoursValue(time?.hour() || 0);
    setIsTimePickerOpen(false);
  }, []);

  const handleKlydoThemeChange = useCallback((theme: Theme | undefined) => {
    setSelectedTheme(theme);
  }, []);

  const handleLocalUrlChange = useCallback(
    (url: string) => {
      setKlydoUrl(url);
    },
    [klydoUrl],
  );

  const handleSecuredUrlChange = useCallback(
    (url: string) => {
      setKlydoSecureUrl(url);
    },
    [klydoSecureUrl],
  );

  const handleBackToEditClick = () => {
    setIsEditingState(true);
  };

  const handleSubmitKlydo = () => {
    setIsEditingState(false);
  };

  return {
    suggestedColors,
    setSuggestedColors,
    klydoSecureUrl,
    minutesValue,
    hoursValue,
    isDialsHidden,
    spacing,
    menuButtonItemsList,
    isTimpePickerOpen,
    handleClosedTimePicker,
    goBack,
    klydo,
    isEditableView,
    palette,
    klydoUrl,
    setSelectedTheme,
    handleKlydoThemeChange,
    selectedTheme,
    handleLocalUrlChange,
    handleSecuredUrlChange,
    klydoId,
    handleBackToEditClick,
    isLoading,
    handleSubmitKlydo,
  };
};

export default useCreateKlydo;

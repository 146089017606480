import { useTheme } from '@mui/material';
import { KlydoReviewStatus, KlydoType, KlydoUnion } from '../../../api/klydo';
import KlydoLoopUrl from '../../../components/klydoPreview/KlydoLoopUrl';
import DSBox from '../../../design-system/DSBox';
import { DSTypography } from '../../../design-system/Typography';
import DSLabelTypography from '../../../design-system/Typography/DSLabelTypography';
import DSTabHeaderTypography from '../../../design-system/Typography/DSSTabHeaderTypography';
import DSValueTypography from '../../../design-system/Typography/DSValueTypography';
import { parseCloudinaryUrl } from '../../../utils';
import KlydoStatus from '../KlydosList/components/KlydoStatus';
import DSPrimaryButton from '../../../design-system/DSPrimaryButtom';
import useKlydoReadOnlyView from '../hooks/useKlydoReadOnlyView';
import DSSwitch from '../../../design-system/DSSwitch';
import WarningDialog from '../../../components/WarningDialog';
import { PUBLIC_SWITCH_ALERT } from '../../../consts/alerts';

type KlydoReadOnlyViewProps = {
  klydo: KlydoUnion;
  onClickEdit: () => void;
};

const KlydoReadOnlyView = ({ klydo, onClickEdit }: KlydoReadOnlyViewProps) => {
  const { spacing, palette } = useTheme();

  const shouldShowCTAButton = klydo.type === KlydoType.Draft;

  const isKlydoApproved = klydo?.type === KlydoType.Approved;

  const isKlydoRejected =
    klydo?.type === KlydoType.Draft &&
    klydo?.reviewStatus?.state === KlydoReviewStatus.Rejected;

  const isKlydoPending =
    klydo?.type === KlydoType.Draft &&
    klydo?.reviewStatus?.state === KlydoReviewStatus.Pending;

  const disabledPublishSwitch =
    (klydo.public && isKlydoApproved) || isKlydoPending;

  const {
    handleBackToEdit,
    onApprovedPublished,
    publishedDialogOpen,
    onClosedPublishedDialog,
    handlePublicSwitch,
  } = useKlydoReadOnlyView({
    klydo,
    onClickEdit,
    isKlydoRejected,
    isKlydoPending,
  });

  const SelectedPaletteCircle = ({ color }: { color: string }) => {
    return (
      <DSBox
        sx={{
          width: spacing(4),
          height: spacing(4),
          borderRadius: '50%',
          backgroundColor: color,
          border: `0.5px solid ${palette.text.secondary}`,
        }}
      />
    );
  };

  return (
    <DSBox
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        width: '100%',
        height: '100%',
      }}
    >
      <DSBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: spacing(3),
          height: '100%',
          alignItems: 'space-between',
          marginBottom: spacing(1),
        }}
      >
        <DSTabHeaderTypography>{klydo?.name}</DSTabHeaderTypography>

        <DSBox
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <DSTypography sx={{ marginTop: spacing(1), color: 'text.secondary' }}>
            Last Update{' '}
            {new Date(klydo?.updatedAt || new Date()).toLocaleDateString(
              undefined,
              {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
              },
            )}
          </DSTypography>
          <KlydoStatus klydo={klydo} />
        </DSBox>
        <DSBox
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <DSValueTypography>Public</DSValueTypography>
          <DSSwitch
            disabled={disabledPublishSwitch}
            checked={!!klydo.public}
            onChange={handlePublicSwitch}
          />
        </DSBox>
        {isKlydoRejected && (
          <>
            <DSValueTypography>
              You need to fix the following issues
            </DSValueTypography>
            <DSBox
              sx={{
                background: palette.secondary.main,
                padding: '4%',
                borderRadius: spacing(5),
              }}
            >
              <DSValueTypography color="error">
                {klydo?.reviewStatus?.message}
              </DSValueTypography>
            </DSBox>
          </>
        )}
        <DSValueTypography>Your Klydo</DSValueTypography>
        <DSBox
          sx={{
            background: palette.secondary.main,
            padding: '4%',
            borderRadius: spacing(5),
          }}
        >
          <DSBox
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <DSLabelTypography>Animation File</DSLabelTypography>
            <DSBox
              sx={{
                display: 'flex',
                flexDirection: 'row',
                gap: spacing(1),
                alignItems: 'center',
                marginBottom: spacing(1),
              }}
            >
              <DSValueTypography
                sx={{
                  pointerEvents: 'auto',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 1,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  wordWrap: 'break-word',
                  whiteSpace: 'normal',
                  width: {
                    xxs: '35vw',
                    xs: '35vw',
                    sm: '15vw',
                    md: '20vw',
                    lg: '20vw',
                    xl: '35vw',
                  },
                  cursor: 'default',
                }}
              >
                {klydo?.metadata?.fileName}
              </DSValueTypography>
              <DSBox sx={{ width: spacing(5), height: spacing(5) }}>
                <KlydoLoopUrl
                  loopUrl={parseCloudinaryUrl({
                    url: klydo.loopUrl,
                    isStatic: true,
                    size: 20,
                  })}
                />
              </DSBox>
            </DSBox>
          </DSBox>
          <DSBox
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <DSLabelTypography>Klydo Palette</DSLabelTypography>
            <DSBox
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: spacing(1),
              }}
            >
              <SelectedPaletteCircle color={klydo.theme.backgroundColor} />
              <SelectedPaletteCircle color={klydo.theme.dialsColor} />
              <SelectedPaletteCircle color={klydo.theme.handsColor} />
              <SelectedPaletteCircle color={klydo.theme.pendulumColor} />
              <SelectedPaletteCircle color={klydo.theme.pendulumRodColor} />
            </DSBox>
          </DSBox>
        </DSBox>
      </DSBox>
      <DSBox sx={{ display: 'flex', justifyContent: 'center' }}>
        {shouldShowCTAButton && (
          <DSPrimaryButton
            sx={{ padding: ' 14px var(--5, 40px)' }}
            onClick={() => handleBackToEdit()}
          >
            {isKlydoRejected ? 'Edit' : 'Cancel Review'}
          </DSPrimaryButton>
        )}
      </DSBox>
      <WarningDialog
        warningMessage={PUBLIC_SWITCH_ALERT}
        open={publishedDialogOpen}
        handleConfirmationClick={onApprovedPublished}
        handleClose={onClosedPublishedDialog}
      />
    </DSBox>
  );
};

export default KlydoReadOnlyView;

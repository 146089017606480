import { useTheme } from '@mui/material';
import DSGrid from '../../../../design-system/DSGrid';
import { DSSubtitleTypography } from '../../../../design-system/Typography';
import { ApprovedKlydo, KlydoUnion } from '../../../../api/klydo';
import KlydoCard from './KlydoCard';
import DSBox from '../../../../design-system/DSBox';
import loading from '../../../../images/loading.png';
import { useNavigate } from 'react-router-dom';
import useDeviceType from '../../../../hooks/useDeviceType';
import DeleteDialog from '../../../../components/DeleteDialog';
import useDeleteKlydo from '../hooks/useDeleteKlydo';
import { useState } from 'react';
import { DELETE_KLYDO_ALERT } from '../../../../consts/alerts';
import { genrateObjectId } from '../../../../utils';
export type KlydoGridProps = {
  isLoading: boolean;
  klydos?: KlydoUnion[];
};

const KlydoGrid = ({ isLoading, klydos }: KlydoGridProps) => {
  const { spacing } = useTheme();
  const navigate = useNavigate();
  const { isMobile } = useDeviceType();
  const [deletedKlydoId, setDeletedKlydoId] = useState<string>('');

  const handleCreateClick = () => {
    const createdObjectId = genrateObjectId();
    navigate(`/klydo/${createdObjectId}`);
  };

  const { isDeleting, handleDeleteKlydo } = useDeleteKlydo(
    deletedKlydoId,
    () => {
      setDeletedKlydoId('');
    },
  );

  if (!klydos || !klydos?.length || isLoading) {
    return (
      <DSBox
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="70vh"
      >
        <img
          src={loading}
          alt="Logo"
          width={spacing(26)}
          height={spacing(27)}
        />
        {isLoading ? (
          <DSSubtitleTypography fontSize={spacing(2.5)}>
            Loading...
          </DSSubtitleTypography>
        ) : (
          <>
            <DSSubtitleTypography
              onClick={() => handleCreateClick()}
              fontSize={spacing(2.5)}
              sx={{
                textDecorationLine: 'underline',
                cursor: 'pointer',
              }}
            >
              Click to create a new Klydo
            </DSSubtitleTypography>
          </>
        )}
      </DSBox>
    );
  }

  return (
    <>
      <DSGrid
        container
        spacing={spacing(2)}
        sx={{
          display: 'grid',
          gridTemplateColumns: {
            xxs: '1fr',
            xs: 'repeat(2, 1fr)',
            sm: 'repeat(2, 1fr)',
            md: 'repeat(3, 1fr)',
            lg: 'repeat(3, 1fr)',
            xl: 'repeat(4, 1fr)',
            xxl: 'repeat(5, 1fr)',
          },
        }}
      >
        {klydos.map((klydo) => (
          <DSGrid key={klydo.id}>
            <KlydoCard
              klydo={{ ...klydo }}
              isMenuHidden={isMobile}
              isStatic
              handleDeleteKlydo={() => {
                setDeletedKlydoId(klydo.id);
              }}
            />
          </DSGrid>
        ))}
      </DSGrid>
      <DeleteDialog
        isDeleteDialogOpen={!!deletedKlydoId}
        handleConfirmationClick={handleDeleteKlydo}
        handleClose={() => setDeletedKlydoId('')}
        deleteMessage={DELETE_KLYDO_ALERT}
        isDeleting={isDeleting}
      />
    </>
  );
};

export default KlydoGrid;

import { useEffect, useRef } from 'react';
import { useOnScreen } from '../../../hooks/useOnScreen';
import { parseCloudinaryUrl } from '../../../utils';
import { Klydo } from '../../../api/klydo';
import { DEFAULT_KLYDO_THEME } from '../../../consts/general';

const secondsForMin = 3600;
const secondsForHour = 43200;
type UseKlydoPreviewProps = {
  klydo: Partial<Klydo>;
  minutesValue?: number;
  hoursValue?: number;
  isAnimationStatic?: boolean;
};

const useKlydoPreview = ({
  klydo,
  minutesValue,
  hoursValue,
  isAnimationStatic,
}: UseKlydoPreviewProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const handMin = useRef<HTMLDivElement>(null);
  const handHour = useRef<HTMLDivElement>(null);
  const handHourS = useRef<HTMLDivElement>(null);
  const handMinS = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  const loopUrl = parseCloudinaryUrl({
    url: klydo?.loopUrl || '',
    isStatic: isAnimationStatic,
    size: 500,
  });

  useEffect(() => {
    const updHands = () => {
      const dt = new Date();
      if (hoursValue !== undefined && minutesValue !== undefined) {
        dt.setHours(hoursValue);
        dt.setMinutes(minutesValue);
      }

      const seconds = dt.getMinutes() * 60 + dt.getSeconds();
      const dgmin = (seconds / secondsForMin) * 360;
      if (handMin.current) {
        handMin.current.style.transform = `rotate(${dgmin}deg)`;
      }
      if (handMinS.current) {
        handMinS.current.style.transform = `rotate(${dgmin}deg)`;
      }
      const hours = (dt.getHours() % 12) * 3600 + seconds;
      const dgh = (hours / secondsForHour) * 360;
      if (handHour.current) {
        handHour.current.style.transform = `rotate(${dgh}deg)`;
      }
      if (handHourS.current) {
        handHourS.current.style.transform = `rotate(${dgh}deg)`;
      }
    };
    updHands();
  }, [isVisible, minutesValue, hoursValue]);

  return {
    ref,
    handMin,
    handHour,
    handHourS,
    handMinS,
    isVisible,
    loopUrl,
    handsColor: klydo?.theme?.handsColor || DEFAULT_KLYDO_THEME.handsColor,
    backgroundColor:
      klydo?.theme?.backgroundColor || DEFAULT_KLYDO_THEME.backgroundColor,
    pendulumColor:
      klydo?.theme?.pendulumColor || DEFAULT_KLYDO_THEME.pendulumColor,
    pendulumRodColor:
      klydo?.theme?.pendulumRodColor || DEFAULT_KLYDO_THEME.pendulumRodColor,
    dialsColor: klydo?.theme?.dialsColor || DEFAULT_KLYDO_THEME.dialsColor,
  };
};
export default useKlydoPreview;

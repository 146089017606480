import React from 'react';
import {
  DSTitleTypography,
  DSTypography,
} from '../../../../design-system/Typography';
import DSValueTypography from '../../../../design-system/Typography/DSValueTypography';
import DSIconButton from '../../../../design-system/DSIconButton';
import DSPrimaryButton from '../../../../design-system/DSPrimaryButtom';
import DSSecondaryButton from '../../../../design-system/DSSecondaryButton';
import DSBox from '../../../../design-system/DSBox';
import { Theme } from '../../../../types';
import useKlydoColorPickerModal from '../hooks/useKlydoColorPickerModal';
import AddIcon from '@mui/icons-material/Add';
import { fontColorContrast } from '../../../../utils';
import DSLabelTypography from '../../../../design-system/Typography/DSLabelTypography';
import { ThemeStackStrings } from '../../../../types';

interface KlydoColorPickerModalProps {
  theme?: Theme;
  onSave: (theme: Theme) => void;
  onClose: () => void;
  onChangeColor: (key: string, color: string) => boolean;
  selectedThemeIndex?: boolean;
}

const KlydoColorPickerModal: React.FC<KlydoColorPickerModalProps> = ({
  onSave,
  onClose,
  theme,
  onChangeColor,
}) => {
  const {
    spacing,
    palette,
    updatedTheme,
    handleColorPick,
    handleColorChange,
    disabledSave,
    areColorsValid,
  } = useKlydoColorPickerModal({ theme, onChangeColor });
  return (
    <DSBox
      sx={{
        width: spacing(65),
        height: spacing(54),
        backgroundColor: 'var(--background-paper-elevation-24, #383838)',
        boxShadow:
          '0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px -7px rgba(0, 0, 0, 0.20)',
        padding: spacing(3),
        borderRadius: 'var(--3, 24px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <DSTitleTypography>Create your own color palette</DSTitleTypography>
      {Object.keys(updatedTheme).map((key) => (
        <DSBox
          key={key}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 1,
          }}
        >
          <DSValueTypography
            color={
              areColorsValid[key as keyof typeof areColorsValid] === false
                ? 'red'
                : undefined
            }
          >
            {ThemeStackStrings[key as keyof typeof ThemeStackStrings]}
          </DSValueTypography>
          <DSLabelTypography>
            {areColorsValid[key as keyof typeof areColorsValid] === false
              ? 'Color contrast low. Try again'
              : ''}
          </DSLabelTypography>
          <DSIconButton
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: spacing(12),
              borderRadius: spacing(12.5),
              boxShadow:
                '0px 1px 5px 0px rgba(0, 0, 0, 0.12), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.20)',
              backgroundColor:
                updatedTheme[key as keyof Theme] || palette.action.disabled,
              color: fontColorContrast(updatedTheme[key as keyof Theme]),
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderColor: 'transparent',
              '&:hover': {
                backgroundColor: updatedTheme[key as keyof Theme],
                border: '1px solid rgba(0, 0, 0, 0.23)',
                borderColor: palette.text.primary,
              },
            }}
            onClick={() => handleColorPick(key as keyof Theme)}
          >
            {updatedTheme[key as keyof Theme] ? (
              <DSTypography>{updatedTheme[key as keyof Theme]}</DSTypography>
            ) : (
              <AddIcon />
            )}
            <input
              type="color"
              value={updatedTheme[key as keyof Theme] || '#000010'}
              onChange={(e) => handleColorChange(e.target.value)}
              style={{
                position: 'absolute',
                borderRadius: spacing(12.5),
                opacity: 0,
                cursor: 'pointer',
                width: '100%',
                height: '100%',
              }}
            />
          </DSIconButton>
        </DSBox>
      ))}
      <DSBox
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: spacing(2),
        }}
      >
        <DSSecondaryButton onClick={onClose}>Cancel</DSSecondaryButton>
        <DSPrimaryButton
          onClick={() => onSave(updatedTheme)}
          disabled={disabledSave}
          sx={{
            fontSize: spacing(2),
            paddingY: spacing(0.7),
            paddingX: spacing(2),
          }}
        >
          Save
        </DSPrimaryButton>
      </DSBox>
    </DSBox>
  );
};

export default KlydoColorPickerModal;

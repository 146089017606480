import React, { forwardRef } from 'react';
import { TimeClock } from '@mui/x-date-pickers/TimeClock';

const DSTimeClock = forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof TimeClock>
>((props, ref) => (
  <TimeClock views={['hours', 'minutes']} ref={ref} {...props} />
));

export default DSTimeClock;

import useAppContext from '../../../hooks/useAppContext';
import useDeviceType from '../../../hooks/useDeviceType';
import useKlydoMutation from '../../../hooks/useKlydoMutation';
import userApiClient from '../../../api/user';
import firebaseService from '../../../firebase/firebaseService';
import { useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

const useAppBar = () => {
  const { user, isAdmin, impersonationActor } = useAppContext();
  const queryClient = useQueryClient();
  const { isTablet } = useDeviceType();
  const navigate = useNavigate();
  const { mutate: stopImpersonating } = useKlydoMutation({
    mutationKey: ['stop-imporsonating'],
    mutationFn: () => userApiClient.stopImpersonating(),
    onSuccess: async (token: string) => {
      await firebaseService.impersonate(token);
      queryClient.invalidateQueries();
      navigate('/');
    },
  });

  const shouldShowUserDropdown = isAdmin && !isTablet;
  const shouldShowImpersonatingChip = impersonationActor && !isTablet;

  return {
    user,
    isAdmin,
    impersonationActor,
    isTablet,
    shouldShowUserDropdown,
    shouldShowImpersonatingChip,
    stopImpersonating,
  };
};
export default useAppBar;

import DSBox from '../../../design-system/DSBox';
import DSTypography from '../../../design-system/Typography/DSTypography';
import KlydoStatus from '../KlydosList/components/KlydoStatus';
import DSTextField from '../../../design-system/DSTextField';
import DSLabelTypography from '../../../design-system/Typography/DSLabelTypography';
import DSPrimaryButton from '../../../design-system/DSPrimaryButtom';
import useKlydoEditableView from '../hooks/useKlydoEditableView';
import { DraftKlydo } from '../../../api/klydo';
import KlydoFileInput from './KlydoFileInput';
import KlydoColorStack from '../ColorPicker/components/KlydoColorStack';
import { Theme } from '../../../types';
import DSTooltip from '../../../design-system/DSTooltip';
import { KLYDO_NAME_LIMIT } from '../../../consts/textFieldsLimitations';
import ErrorDialog from '../../../components/ErrorDialog';
import {
  INCOMPLETE_PROFILE_ALERT,
  INCOMPLETE_PROFILE_REASON_ALERT,
  SUBMIT_FOR_REVIEW_ALERT,
} from '../../../consts/alerts';
import DSSwitch from '../../../design-system/DSSwitch';
import WarningDialog from '../../../components/WarningDialog';

type KlydoEditableViewProps = {
  klydo?: DraftKlydo;
  setSuggestedColors: (theme: Theme[] | undefined) => void;
  onKlydoThemeChange: (theme: Theme | undefined) => void;
  suggestedColors?: Theme[];
  klydoSecureUrl: string;
  onLocalUrlChange: (url: string) => void;
  onSecuredUrlChange: (url: string) => void;
  selectedTheme: Theme | undefined;
  klydoId: string | undefined;
  onSubmitKlydo: () => void;
};

const KlydoEditableView = ({
  klydo,
  setSuggestedColors,
  onKlydoThemeChange,
  suggestedColors,
  onLocalUrlChange,
  onSecuredUrlChange,
  klydoSecureUrl,
  selectedTheme,
  klydoId,
  onSubmitKlydo,
}: KlydoEditableViewProps) => {
  const {
    handleFileChange,
    handleRemoveFile,
    handleSubmitToReview,
    klydoTitle,
    setKlydoTitle,
    isLoadingUrl,
    spacing,
    lastUpdate,
    isCreateButtonDisabled,
    isLoadingThemes,
    fileName,
    handleMissingFields,
    onChangeColor,
    profileIncompleteErrorDialogOpen,
    handleCloseErrorDialog,
    onEditProfile,
    setShouldAutoSaveTheme,
    handlePublicSwitch,
    submitDialogOpen,
    onApprovedSubmit,
    onClosedSubmitDialog,
  } = useKlydoEditableView({
    klydo,
    setSuggestedColors,
    onLocalUrlChange,
    onSecuredUrlChange,
    klydoSecureUrl,
    onKlydoThemeChange,
    selectedTheme,
    klydoId,
    onSubmitKlydo,
  });
  return (
    <>
      <DSBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          width: '100%',
          height: '100%',
          gap: spacing(3),
        }}
      >
        <DSBox
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'row',
          }}
        >
          <DSTypography sx={{ marginTop: spacing(1), color: 'text.secondary' }}>
            Last Update{' '}
            {new Date(lastUpdate || new Date()).toLocaleDateString(undefined, {
              year: '2-digit',
              month: '2-digit',
              day: '2-digit',
            })}
          </DSTypography>
          <KlydoStatus klydo={klydo} />
        </DSBox>

        <DSBox
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: spacing(3),
            overflowY: 'scroll',
            height: '80%',
            borderRadius: '5%',
            padding: 1,
          }}
        >
          <DSBox
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <DSLabelTypography>Public</DSLabelTypography>
            <DSSwitch
              defaultChecked
              disabled={!klydo}
              checked={klydo?.public}
              onChange={handlePublicSwitch}
            />
          </DSBox>

          <DSBox
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: spacing(1),
            }}
          >
            <DSLabelTypography>
              {`*Choose a public name for your Klydo (max ${KLYDO_NAME_LIMIT} characters)`}
            </DSLabelTypography>
            <DSTextField
              placeholder="Name your Klydo"
              value={klydoTitle}
              fullWidth
              required
              slotProps={{
                input: {
                  inputProps: { maxLength: KLYDO_NAME_LIMIT },
                },
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setKlydoTitle(event.target.value);
              }}
            />
          </DSBox>
          <KlydoFileInput
            onFileChange={handleFileChange}
            klydoSecureUrl={klydoSecureUrl}
            onRemoveFile={handleRemoveFile}
            fileName={fileName}
            isLoadingUrl={isLoadingUrl}
          />
          <DSBox
            sx={{ display: 'flex', flexDirection: 'column', gap: spacing(1) }}
          >
            <DSLabelTypography>
              *Choose a color palette for your Klydo
            </DSLabelTypography>
            <KlydoColorStack
              themes={suggestedColors}
              setTheme={setSuggestedColors}
              onChangeTheme={onKlydoThemeChange}
              isLoadingThemes={isLoadingThemes}
              onChangeColor={onChangeColor}
              addThemeButtonDisabled={!klydoSecureUrl}
              setShouldAutoSaveTheme={setShouldAutoSaveTheme}
            />
          </DSBox>
        </DSBox>
        <DSTooltip title={handleMissingFields()} placement="top">
          <DSPrimaryButton
            disabled={isCreateButtonDisabled}
            onClick={handleSubmitToReview}
            sx={{
              width: '50%',
              display: 'flex',
              alignSelf: 'center',
              padding: '6px 16px',
            }}
          >
            Submit For Review
          </DSPrimaryButton>
        </DSTooltip>
      </DSBox>
      <ErrorDialog
        errorTitle={INCOMPLETE_PROFILE_ALERT}
        errorReason={INCOMPLETE_PROFILE_REASON_ALERT}
        isErrorDialogOpen={profileIncompleteErrorDialogOpen}
        handleClose={handleCloseErrorDialog}
        onClick={onEditProfile}
      />
      <WarningDialog
        warningMessage={SUBMIT_FOR_REVIEW_ALERT}
        open={submitDialogOpen}
        handleConfirmationClick={onApprovedSubmit}
        handleClose={onClosedSubmitDialog}
      />
    </>
  );
};

export default KlydoEditableView;

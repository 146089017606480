import React from 'react';
import { Paper } from '@mui/material';

const DSPaper = (props: React.ComponentProps<typeof Paper>) => {
  return (
    <Paper
      {...props}
      sx={{
        borderRadius: '28px',
        backgroundColor: 'rgba(255, 255, 255, 0.09)',
        ...props.sx,
      }}
    />
  );
};

export default DSPaper;

import DSDialogContent from '../design-system/DSDialogContent';
import { DSSubtitleTypography } from '../design-system/Typography';
import DSDialogActions from '../design-system/DSDialogActions';
import DSSecondaryButton from '../design-system/DSSecondaryButton';
import DSPrimaryButton from '../design-system/DSPrimaryButtom';
import DSDialog from '../design-system/DSDialog';

type WarningDialogProps = {
  warningMessage: string;
  open: boolean;
  handleConfirmationClick: () => void;
  handleClose: () => void;
};
const WarningDialog = ({
  warningMessage,
  open,
  handleConfirmationClick,
  handleClose,
}: WarningDialogProps) => {
  return (
    <DSDialog maxWidth="md" open={open} onClose={handleClose}>
      <DSDialogContent>
        <DSSubtitleTypography sx={{ whiteSpace: 'pre-line' }}>
          {warningMessage}
        </DSSubtitleTypography>
      </DSDialogContent>
      <DSDialogActions>
        <DSSecondaryButton onClick={() => handleClose()}>
          Cancel
        </DSSecondaryButton>
        <DSPrimaryButton onClick={() => handleConfirmationClick()}>
          Yes
        </DSPrimaryButton>
      </DSDialogActions>
    </DSDialog>
  );
};
export default WarningDialog;

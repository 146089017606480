import { useQueryClient } from '@tanstack/react-query';
import useKlydoMutation, {
  UseKlydoMutationOptions,
} from '../../hooks/useKlydoMutation';
import { UploadFileInput, UploadResponse } from '../../api/uploads';
import studioApiClient from '../../api/studio';

const useUploadKlydoLoopMutation = (
  options: Omit<
    UseKlydoMutationOptions<
      UploadResponse,
      UploadFileInput,
      { previousUploadStatus: UploadResponse | undefined }
    >,
    'onSuccess' | 'onSettled'
  > & {
    onSuccess?: (response: UploadResponse) => void;
    onSettled?: (response: UploadResponse | undefined, error: unknown) => void;
  } = {},
) => {
  const queryClient = useQueryClient();

  return useKlydoMutation({
    mutationFn: (input: UploadFileInput) =>
      studioApiClient.uploadKlydoLoop(input),
    mutationKey: ['uploadKlydoLoop'],
    onMutate: async (input) => {
      await queryClient.cancelQueries({ queryKey: ['uploadKlydoLoop'] });

      const previousUploadStatus: UploadResponse | undefined =
        queryClient.getQueryData(['uploadKlydoLoop']);

      options.onMutate?.(input);

      return { previousUploadStatus };
    },
    onError: (error, input, context) => {
      if (context?.previousUploadStatus) {
        queryClient.setQueryData(
          ['uploadKlydoLoop'],
          context.previousUploadStatus,
        );
      }
      options.onError?.(error, input, context);
    },
    onSettled: async (response, error) => {
      if (options.onSettled) {
        options.onSettled(response, error);
      }
      await queryClient.invalidateQueries({ queryKey: ['uploadKlydoLoop'] });
    },
    onSuccess: (response) => {
      if (options.onSuccess) {
        options.onSuccess(response);
      }
    },
    ...options,
  });
};

export default useUploadKlydoLoopMutation;

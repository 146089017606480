import { useQueryClient } from '@tanstack/react-query';
import klydoApiClient from '../../../api/klydo';
import useKlydoMutation, {
  UseKlydoMutationOptions,
} from '../../../hooks/useKlydoMutation';

const usePublishKlydoMutation = (
  options?: UseKlydoMutationOptions<void, { klydoId: string }, void>,
) => {
  const queryClient = useQueryClient();

  return useKlydoMutation({
    mutationKey: ['publish-klydo'],
    mutationFn: ({ klydoId }) => {
      return klydoApiClient.publishKlydo(klydoId);
    },
    onSettled: (data, error, { klydoId }) => {
      queryClient.invalidateQueries({ queryKey: ['klydo', klydoId] });
    },
    ...options,
  });
};

export default usePublishKlydoMutation;

import { AxiosProgressEvent } from 'axios';
import { baseClient } from './baseRequest';

export type UploadFileInput = {
  file: string;
  fileType?: string;
  onUploadProgress?: (progress: number) => void;
  options?: {
    crop?: string;
    width?: number;
    height?: number;
  };
};

export type UploadResponseStatus = {
  status: 'rejected' | 'approved' | 'error';
  error?: string;
};

export type UploadResponse = UploadResponseStatus & {
  url: string;
  duration?: number;
};

export type UploadsApiClient = {
  uploadProfileImage: (input: UploadFileInput) => Promise<UploadResponse>;
};

const uploadsApiClient: UploadsApiClient = {
  uploadProfileImage: async (input) => {
    return baseClient.post<UploadResponse>({
      url: '/uploads/upload-profile-image',
      body: {
        file: input.file,
        options: input.options,
      },
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (input.onUploadProgress) {
          const percentCompleted = Math.floor(
            (progressEvent.loaded * 100) / (progressEvent?.total || 1),
          );
          input.onUploadProgress(percentCompleted);
        }
      },
    });
  },
};

export default uploadsApiClient;

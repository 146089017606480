import { getMediaType } from '../../utils';

type KlydoLoopUrlProps = {
  loopUrl: string;
};

const KlydoLoopUrl = ({ loopUrl }: KlydoLoopUrlProps) => {
  if (loopUrl && getMediaType(loopUrl) === 'image') {
    return (
      <img
        loading="lazy"
        src={loopUrl}
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: '50%',
        }}
      />
    );
  } else if (loopUrl && getMediaType(loopUrl) === 'video') {
    return (
      <video
        muted={true}
        src={loopUrl}
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '50%',
          objectFit: 'cover',
        }}
        autoPlay
        loop
        playsInline
      />
    );
  } else {
    return (
      <div
        style={{
          width: '100%',
          aspectRatio: '1',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <p>This file type is not supported</p>
      </div>
    );
  }
};
export default KlydoLoopUrl;

import 'firebase/compat/auth';
import DSCircularProgress from '../design-system/DSCircularProgress';
import bg from '../images/darkSplashScreen.png';
import logo from '../images/klydo-studio-logo.svg';
import { KLYDO_BLACK } from '../consts/colors';

export function SplashScreen() {
  return (
    <div
      id="fuse-splash-screen"
      style={{ background: KLYDO_BLACK, backgroundImage: `url(${bg})` }}
    >
      <div className="center">
        <div
          style={{
            width: 140,
            margin: '0 auto',
          }}
        >
          <img width="140" src={logo} alt="..." />
        </div>
        <div
          style={{
            paddingTop: 20,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <DSCircularProgress />
        </div>
      </div>
    </div>
  );
}

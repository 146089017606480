import React, { forwardRef } from 'react';
import { Snackbar, useTheme } from '@mui/material';

const DSSnackbar = forwardRef<
  HTMLDivElement,
  React.ComponentProps<typeof Snackbar>
>((props, ref) => {
  const { spacing } = useTheme();
  return (
    <Snackbar
      ref={ref}
      {...props}
      sx={{
        background: '#383838',
        borderRadius: spacing(3),
        padding: spacing(3),
        ...props.sx,
      }}
    />
  );
});

export default DSSnackbar;

import React, { forwardRef } from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';

const DSBox = forwardRef<HTMLDivElement, React.ComponentProps<typeof Box>>(
  (props, ref) => {
    const { spacing, palette } = useTheme();
    return (
      <Box
        ref={ref}
        {...props}
        sx={{
          '&::-webkit-scrollbar': {
            width: spacing(1),
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: palette.action.selected,
            borderRadius: spacing(8),
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'transparent',
          },
          ...props.sx,
        }}
      />
    );
  },
);

export default DSBox;

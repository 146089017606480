import { Theme } from '../../../../types';
import useKlydoColorStack from '../hooks/useKlydoColorStack';
import KlydoColorStackItem from './KlydoColorStackItem';
import DSIconButton from '../../../../design-system/DSIconButton';
import AddIcon from '@mui/icons-material/Add';
import DSBox from '../../../../design-system/DSBox';
import KlydoColorPickerModal from './KlydoColorPickerModal';
import DSPaper from '../../../../design-system/Paper/DSPaper';
import DSCircularProgress from '../../../../design-system/DSCircularProgress';
import DSPopper from '../../../../design-system/DSPopper';
import { useTheme } from '@mui/material';

type KlydoColorStackProps = {
  themes?: Theme[];
  setTheme: (theme: Theme[]) => void;
  onChangeTheme: (theme: Theme | undefined) => void;
  onChangeColor: (key: string, color: string) => boolean;
  isLoadingThemes: boolean;
  addThemeButtonDisabled: boolean;
  setShouldAutoSaveTheme: (value: boolean) => void;
};

const KlydoColorStack = ({
  themes,
  setTheme,
  onChangeTheme,
  onChangeColor,
  isLoadingThemes,
  addThemeButtonDisabled,
  setShouldAutoSaveTheme,
}: KlydoColorStackProps) => {
  const { spacing } = useTheme();

  const {
    selectedIndex,
    handleColorClick,
    openColorPicker,
    setOpenColorPicker,
    selectedThemeIndex,
    setSelectedThemeIndex,
    setSelectedIndex,
  } = useKlydoColorStack({
    onChangeTheme,
    themes,
  });

  if (isLoadingThemes) {
    return (
      <DSPaper
        sx={{
          alignItems: 'center',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignSelf: 'stretch',
          width: '100%',
          height: spacing(25),
          paddingX: spacing(7),
          paddingY: spacing(3),
          gap: spacing(2),
        }}
      >
        <DSCircularProgress />
      </DSPaper>
    );
  }

  return (
    <DSPaper
      sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignSelf: 'stretch',
        width: '100%',
        padding: spacing(2),
        gap: spacing(2),
      }}
    >
      <DSBox
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          gap: spacing(1),
        }}
      >
        {themes?.length && themes.length > 0
          ? themes.map((colorOption: Theme, index: number) => (
              <DSBox
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <DSBox
                  key={index}
                  sx={{ display: 'flex', width: '100%' }}
                  onClick={() => handleColorClick(index)}
                >
                  <KlydoColorStackItem
                    key={index}
                    isSelected={selectedIndex === index}
                    theme={colorOption}
                    onClickEdit={() => {
                      setSelectedThemeIndex(index);
                      onChangeTheme(themes[index]);
                      setShouldAutoSaveTheme(false);
                      setOpenColorPicker(true);
                    }}
                  />
                </DSBox>
              </DSBox>
            ))
          : null}

        <DSIconButton
          onClick={() => {
            setOpenColorPicker(true);
            setShouldAutoSaveTheme(false);
            setSelectedThemeIndex(undefined);
          }}
          disabled={addThemeButtonDisabled}
          sx={{
            fontSize: '24px',
            width: '100%',
            height: '47px',
            padding: 'var(--2, 16px) var(--3, 24px)',
            borderRadius: 'var(--3, 24px)',
            border: '3px solid var(--action-active, rgba(255, 255, 255, 0.56))',
          }}
        >
          <AddIcon />
        </DSIconButton>
      </DSBox>
      <DSPopper
        open={openColorPicker}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'transparent',
          position: 'fixed',
          bottom: '5%',
          right: '5%',
        }}
      >
        <KlydoColorPickerModal
          onClose={() => {
            setOpenColorPicker(false);
            setShouldAutoSaveTheme(true);
            if (themes) {
              onChangeTheme(themes[selectedIndex]);
            } else {
              onChangeTheme(undefined);
            }
          }}
          onSave={(updatedTheme) => {
            if (selectedThemeIndex !== undefined && themes) {
              themes[selectedThemeIndex] = updatedTheme;
              setTheme([...themes]);
              setSelectedIndex(selectedThemeIndex);
            } else {
              setTheme([...(themes || []), updatedTheme]);
            }
            setOpenColorPicker(false);
            setShouldAutoSaveTheme(true);
            onChangeTheme(updatedTheme);
            setSelectedThemeIndex(undefined);
          }}
          onChangeColor={onChangeColor}
          theme={
            selectedThemeIndex !== undefined
              ? themes?.[selectedThemeIndex]
              : undefined
          }
        />
      </DSPopper>
    </DSPaper>
  );
};
export default KlydoColorStack;

import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material';
import { SocialMedia } from '../../../api/user';
import useUserMutation from '../../SignUp/hooks/useUserMutation';
import useAppContext from '../../../hooks/useAppContext';
import { AlertType } from '../../../design-system/DSAlert';
import { EDIT_PROFILE_ALERT } from '../../../consts/alerts';
import useUploadProfileImageMutation from '../../hooks/useUploadProfileImageMutation';

const useEditProfile = () => {
  const { setUser, user, showAlert } = useAppContext();
  const navigate = useNavigate();
  const { spacing } = useTheme();
  const [name, setName] = useState(user?.name || '');
  const [country, setCountry] = useState(user?.country || '');
  const [socialMedia, setSocialMedia] = useState<SocialMedia[]>(
    user?.socialMedia || [],
  );
  const [description, setDescription] = useState(user?.description || '');
  const [photoUrl, setPhotoUrl] = useState(user?.photoUrl || '');

  const { mutate: updateUserMutation } = useUserMutation(user?.id, {
    optimistic: false,
    onSuccess: (updatedData) => {
      setUser((prevUser) => ({
        ...prevUser,
        ...updatedData,
      }));
      showAlert({
        message: EDIT_PROFILE_ALERT,
        alertType: AlertType.SUCCESS,
      });
      navigate('/profile');
    },
  });

  const {
    mutate: uploadProfileImageMutation,
    isPending: isUploadingProfileImage,
  } = useUploadProfileImageMutation({
    onError: () => {
      setPhotoUrl(user?.photoUrl || '');
    },
    onSuccess: (response) => {
      setPhotoUrl(response.url);
    },
  });

  const handleFileUpload = async (fileUrl: string) => {
    uploadProfileImageMutation({
      file: fileUrl,
    });
  };

  const readAndPreviewFile = (file: File): void => {
    const reader = new FileReader();
    reader.onload = () => {
      handleFileUpload(reader.result as string);
      setPhotoUrl(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const handleFileSelection = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const file = event.target.files?.[0];
    if (!file) return;

    readAndPreviewFile(file);
  };

  const handleNameChange = (senderName: string) => {
    if (senderName.length > 14)
      return showAlert({
        message: 'Name too long',
        alertType: AlertType.ERROR,
      });
    setName(senderName);
  };

  const handleSave = async () => {
    updateUserMutation({
      name,
      description,
      country,
      socialMedia,
      photoUrl,
    });
  };

  const disabledSaveButton =
    !name.trim() ||
    !description.trim() ||
    !country.trim() ||
    !photoUrl.trim() ||
    isUploadingProfileImage;

  return {
    name,
    handleNameChange,
    description,
    setDescription,
    photoUrl,
    handleFileSelection,
    handleSave,
    country,
    setCountry,
    spacing,
    socialMedia,
    setSocialMedia,
    showAlert,
    disabledSaveButton,
    isUploadingProfileImage,
  };
};

export default useEditProfile;
